import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateApplication } from "../../../store/state/applicationSlice";
import { updateIDVModalVisibility } from "../../IDVHardStopModal/slice";
import { useTransitionToPersonalInfoPage } from "./useTransitionToPersonalInfoPage";
import { useAMTInfo } from "../../AMT/hooks";
import { AMTProspectCreationStatus } from "../../AMT/types";
import { hideProfileLoadingModal } from "../slice";
import { useSendPasInfo } from "./useSendPasInfo";
import { useProfileViewState } from "./useProfileViewState";
import { useApplicationInfo } from "../../ConfirmationPage/hooks";

export const useProspectCreationEffect = () => {
  const dispatch = useDispatch();
  const transitionToPersonalInfo = useTransitionToPersonalInfoPage();
  const sendPasInfo = useSendPasInfo();
  const { prospectCreationStatus } = useAMTInfo();
  const { id = -1 } = useApplicationInfo();

  const { profileCreated } = useProfileViewState();

  return useEffect(() => {
    if (prospectCreationStatus === AMTProspectCreationStatus.LoginUser) {
      dispatch(updateApplication({ key: "loginUser", value: true }));
    }
    if (
      profileCreated &&
      (prospectCreationStatus === AMTProspectCreationStatus.LoginUser ||
        prospectCreationStatus === AMTProspectCreationStatus.Passed)
    ) {
      sendPasInfo(id);
      transitionToPersonalInfo();
      dispatch(hideProfileLoadingModal());
    }
    if (prospectCreationStatus === AMTProspectCreationStatus.Failed) {
      dispatch(updateIDVModalVisibility({ value: true }));
      dispatch(hideProfileLoadingModal());
    }
  }, [dispatch, prospectCreationStatus, sendPasInfo, transitionToPersonalInfo, profileCreated, id]);
};
