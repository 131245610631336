import { sendToGTM } from "./sendToGTM";
import { useEffect } from "react";
import { useConfirmationEventData } from "../../components/ConfirmationPage/hooks/useConfirmationEventData";
import { CONFIRMATION_COMPLETE_EVENT } from "./events";

export const useSendConfirmCompleteEvent = () => {
  const { productCode, applicationId, obpPartyId, aoxSubmissionId } = useConfirmationEventData();
  useEffect(() => {
    const hasSent = window.dataLayer.find( (i: { event: string; applicationId: number | null; }) => i.event === CONFIRMATION_COMPLETE_EVENT && i.applicationId === applicationId);
    if (!hasSent && productCode && applicationId) {
      sendToGTM({
        event: CONFIRMATION_COMPLETE_EVENT,
        obpPartyId,
        applicationId,
        productCode,
        aoxSubmissionId
      });
    }
  }, [obpPartyId, applicationId, productCode]);
};
