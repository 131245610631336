import { DEGREE_TYPES } from "./DegreeTypes";
import { ASSOCIATES_DEGREES } from "./AssociatesDegrees";
import { BACHELORS_DEGREES } from "./BachelorsDegrees";
import { DOCTORATE_DEGREES } from "./DoctorateDegrees";
import { MASTERS_DEGREES } from "./MastersDegrees";
import { NO_DEGREE } from "./NoDegree";

const generateSelectOptions = (strings: string[]) =>
  strings.map((str: string) => ({ text: str, id: str }));

export const DEGREE_TYPE_OPTIONS = generateSelectOptions(DEGREE_TYPES);
const NO_DEGREE_OPTIONS = generateSelectOptions(NO_DEGREE);
const ASSOCIATES_DEGREE_OPTIONS = generateSelectOptions(ASSOCIATES_DEGREES);
const BACHELORS_DEGREE_OPTIONS = generateSelectOptions(BACHELORS_DEGREES);
const MASTERS_DEGREE_OPTIONS = generateSelectOptions(MASTERS_DEGREES);
const DOCTORATE_DEGREE_OPTIONS = generateSelectOptions(DOCTORATE_DEGREES);

export const DEGREE_TYPE_TO_DEGREE_OPTIONS: {
  [key: string]: { text: string; id: string }[];
} = {
  "Not Applicable / Not Listed": NO_DEGREE_OPTIONS,
  Associate: ASSOCIATES_DEGREE_OPTIONS,
  Bachelors: BACHELORS_DEGREE_OPTIONS,
  "Master's or Equivalent": MASTERS_DEGREE_OPTIONS,
  "Doctorate or Equivalent": DOCTORATE_DEGREE_OPTIONS,
};
