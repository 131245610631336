import { Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const FreedomCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’re one step closer to stress-free student loan repayment. Next, you’ll need to fund
        your new account. For some tips on setting up direct deposit and other account features
        - check out this handy{' '}
        <a href="https://www.laurelroad.com/checking-switch-guide-download/">guide</a>.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Once you start paying your student loans from this account, you’ll be able to track
        your progress towards forgiveness in Laurel Road Online Banking.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You’ll receive an email shortly with information on how to access Online Banking with
        the User Name and ID you just created. Keep in mind that it can take up to 24 hours for
        your new checking account to appear.
      </Paragraph>
    </>
  );
};

export default memo(FreedomCheckingInstructions);
