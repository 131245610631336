export const FOREIGN_GOVERNMENT_DISCLOSURE_OPTIONS = [
  {
    id: 'No',
    label: 'No, this does not apply to me.',
    value: 'PEPSTAT005',
  },
  {
    id: 'Yes',
    label: 'Yes, this does apply to me.',
    value: 'PEPSTAT001, PEPSTAT002, PEPSTAT003, PEPSTAT004',
  },
];
