import React, { FC, memo } from "react";
import { useDisclosureInfo, useSelectAcknowledgement } from "../../hooks";
import "./acknowledgement-list.scss";
import { useIsMultiproduct } from "../../../BundleModal/hook/useIsMultiproduct";
import AcknowledgementBundleList from "./AcknowledgementBundleList";
import AcknowledgementList from "./AcknowledgementList";

const Acknowledgements: FC = () => {
  const { acknowledgements, selectedAcknowledgement } = useDisclosureInfo();
  const selectHandler = useSelectAcknowledgement();
  const isMultiproduct = useIsMultiproduct();


  return (
    <div className={"disclosures__acknowledgement-list"}>
      <span className={"mobile-title hys-text secondary"}>
        Scroll down to view and accept disclosures
      </span>
        {isMultiproduct ? (
          <AcknowledgementBundleList
            acknowledgements={acknowledgements}
            selectHandler={selectHandler}
            selectedAcknowledgement={selectedAcknowledgement}
          />
        ) : (
          <AcknowledgementList 
            acknowledgements={acknowledgements}
            selectHandler={selectHandler}
            selectedAcknowledgement={selectedAcknowledgement}
          />
        )}
    </div>
  );
};

export default memo(Acknowledgements);
