import { UsState } from "../types";

export const US_STATE_LIST: UsState[] = [
  { text: "Alabama", id: "AL" },
  { text: "Alaska", id: "AK" },
  {
    text: "Arizona",
    id: "AZ",
  },
  { text: "Arkansas", id: "AR" },
  { text: "California", id: "CA" },
  { text: "Colorado", id: "CO" },
  {
    text: "Connecticut",
    id: "CT",
  },
  { text: "Delaware", id: "DE" },
  { text: "Florida", id: "FL" },
  { text: "Georgia", id: "GA" },
  {
    text: "Hawaii",
    id: "HI",
  },
  { text: "Idaho", id: "ID" },
  { text: "Illinois", id: "IL" },
  { text: "Indiana", id: "IN" },
  {
    text: "Iowa",
    id: "IA",
  },
  { text: "Kansas", id: "KS" },
  { text: "Kentucky", id: "KY" },
  { text: "Louisiana", id: "LA" },
  {
    text: "Maine",
    id: "ME",
  },
  { text: "Maryland", id: "MD" },
  { text: "Massachusetts", id: "MA" },
  { text: "Michigan", id: "MI" },
  {
    text: "Minnesota",
    id: "MN",
  },
  { text: "Mississippi", id: "MS" },
  { text: "Missouri", id: "MO" },
  { text: "Montana", id: "MT" },
  {
    text: "Nebraska",
    id: "NE",
  },
  { text: "Nevada", id: "NV" },
  { text: "New Hampshire", id: "NH" },
  { text: "New Jersey", id: "NJ" },
  {
    text: "New Mexico",
    id: "NM",
  },
  { text: "New York", id: "NY" },
  { text: "North Carolina", id: "NC" },
  { text: "North Dakota", id: "ND" },
  {
    text: "Ohio",
    id: "OH",
  },
  { text: "Oklahoma", id: "OK" },
  { text: "Oregon", id: "OR" },
  { text: "Pennsylvania", id: "PA" },
  {
    text: "Rhode Island",
    id: "RI",
  },
  { text: "South Carolina", id: "SC" },
  { text: "South Dakota", id: "SD" },
  { text: "Tennessee", id: "TN" },
  {
    text: "Texas",
    id: "TX",
  },
  { text: "Utah", id: "UT" },
  { text: "Vermont", id: "VT" },
  { text: "Virginia", id: "VA" },
  {
    text: "Washington",
    id: "WA",
  },
  { text: "West Virginia", id: "WV" },
  { text: "Wisconsin", id: "WI" },
  { text: "Wyoming", id: "WY" },
];

export const WASHINGTON_DC =   {
  text: "Washington DC",
  id: "DC",
} 

export const PUERTO_RICO = {
  text: "Puerto Rico",
  id: "PR"
}