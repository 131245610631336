import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Acknowledgement,
  AcknowledgementActionPayload,
  DisclosureInfoState,
  DisclosuresSubmitStatus,
  UpdateAcknowledgemenPreventContinuePayload,
} from "./types";
import { fetchDisclosures, submitDisclosures } from "./asyncActions";
import { convertFetchDisclosuresToAckowledgements } from "./utils";

export const initialState: DisclosureInfoState = {
  acknowledgements: [],
  disclosuresSubmitStatus: DisclosuresSubmitStatus.NotRunning,
  selectedAcknowledgement: null,
  overDraftProtection: "",
  isUmg: false,
  loginComplete: false,
};

const updateAcknowledgementValue = (
  acknowledgements: Acknowledgement[],
  acknowledgement: Acknowledgement,
  field: string
) =>
  acknowledgements.map((currAcknowledgement) => {
    const { file } = currAcknowledgement;
    return acknowledgement.file === file
      ? { ...currAcknowledgement, [field]: true }
      : currAcknowledgement;
  });

const disclosureInfoSlice = createSlice({
  name: "disclosureInfo",
  initialState,
  reducers: {
    selectAcknowledgement: (
      state,
      action: PayloadAction<AcknowledgementActionPayload>
    ) => ({
      ...state,
      selectedAcknowledgement: action.payload.acknowledgement,
    }),
    viewedAcknowledgement: (
      state,
      action: PayloadAction<AcknowledgementActionPayload>
    ) => {
      const { acknowledgement } = action.payload;
      return {
        ...state,
        acknowledgements: updateAcknowledgementValue(
          state.acknowledgements,
          acknowledgement,
          "viewed"
        ),
      };
    },
    updateAcknowledgementPreventContinue: (
      state,
      action: PayloadAction<UpdateAcknowledgemenPreventContinuePayload>
    ) => {
      const { acknowledgement, preventContinue } = action.payload;
      const updatedAcknowledgements = state.acknowledgements.map((ack) => {
        return ack.displayName === acknowledgement.displayName
          ? { ...ack, preventContinue }
          : ack;
      });
      return {
        ...state,
        acknowledgements: updatedAcknowledgements,
      };
    },
    acceptAcknowledgement: (
      state,
      action: PayloadAction<AcknowledgementActionPayload>
    ) => {
      const { acknowledgement } = action.payload;
      return {
        ...state,
        acknowledgements: updateAcknowledgementValue(
          state.acknowledgements,
          acknowledgement,
          "acknowledged"
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDisclosures.fulfilled,
      (state: DisclosureInfoState, action) => ({
        ...state,
        acknowledgements: convertFetchDisclosuresToAckowledgements(
          action.payload
        ),
        selectedAcknowledgement: action.payload[0],
      })
    );
    builder.addCase(
      submitDisclosures.pending,
      (state: DisclosureInfoState) => ({
        ...state,
        disclosuresSubmitStatus: DisclosuresSubmitStatus.Running,
      })
    );
    builder.addCase(
      submitDisclosures.fulfilled,
      (
        state: DisclosureInfoState,
        {
          payload: { token, umg, loginComplete },
        }
      ) => ({
        ...state,
        disclosuresSubmitStatus: DisclosuresSubmitStatus.Passed,
        token,
        isUmg: umg,
        loginComplete
      })
    );
  },
});

export const {
  selectAcknowledgement,
  acceptAcknowledgement,
  viewedAcknowledgement,
  updateAcknowledgementPreventContinue,
} = disclosureInfoSlice.actions;
export const { reducer: DisclosureInfoReducer } = disclosureInfoSlice;
