import { Optional } from '@laurelroad/lrds-react';
import React, { FunctionComponent, memo } from 'react';
import { ConfirmationViewProps } from '../types';
import { ApprovedView } from '../ApprovedView/ApprovedView';
import { DeniedView } from '../DeniedView/DeniedView';
import { PendingView } from '../PendingView/PendingView';
import { AccountStatus } from '../../AccountCreation/types';
import { ReferredView } from '../ReferredView/ReferredView';

const BundleViewRow: FunctionComponent<ConfirmationViewProps> = ({
  productCode,
  accountNumber,
  routingNumber,
  accountStatus,
}) => {
  return (
    <>
      <Optional renderIf={accountStatus === AccountStatus.Approved}>
        <ApprovedView />
      </Optional>
      <Optional renderIf={accountStatus === AccountStatus.Denied}>
        <DeniedView productCode={productCode} />
      </Optional>
      <Optional renderIf={accountStatus === AccountStatus.Pending}>
        <PendingView productCode={productCode} />
      </Optional>
      <Optional renderIf={accountStatus === AccountStatus.Referred}>
        <ReferredView productCode={productCode} />
      </Optional>
    </>
  );
};

export default memo(BundleViewRow);
