import React, { FC, memo } from "react";
import { AcknowledgementHeaderProps } from "../../../types";

const AcknowledgementHeader: FC<AcknowledgementHeaderProps> = ({ header }) => {
  return (
    <>
      <h5>{header}</h5>
    </>
  );
};

export default memo(AcknowledgementHeader);
