import { checkApplicationFinished } from "./checkApplicationFinished";
import { convertApplicationFinishedResponseToMCAData } from "../utils/convertApplicationFinishedResponseToMCAData";
import { runAMTMCACheck } from "./runAMTMCACheck";
import { checkMCAStatus } from "./checkMCAStatus";
import {initializeAMT} from "../../CreateCredentials/async";
import {AMTInitializationError} from "../../CreateCredentials/async/invokeCredentialCreation";
import { cCodes } from "../../../utils/amt";

export class ApplicationNotFinishedError extends Error {}
export class MCARunError extends Error {}
export class MCAFailedError extends Error {}
export class MCAPendingError extends Error {}

export const runFinishApplicationChecks = async (applicationFinishedCallback: () => void = () => {}) => {
  const appFinishedResponse = await checkApplicationFinished();
  if (!appFinishedResponse.applicationFinished) {
    throw new ApplicationNotFinishedError();
  }
  const initializationSucceded = await initializeAMT();
  if (!initializationSucceded) {
    throw new AMTInitializationError();
  }
  applicationFinishedCallback();
  //Need a small interval so react can render the amt container
  await new Promise(r => setTimeout(r, 1));
  //do not run mca if next user action code is a code
  if (cCodes.includes(appFinishedResponse.bcn)) {
    return;
  }
  const mcaData = convertApplicationFinishedResponseToMCAData(
    appFinishedResponse
);
  const mcaCheckCompleted = await runAMTMCACheck(mcaData);
  if (!mcaCheckCompleted) {
    throw new MCARunError();
  }
  const mcaStatusResponse = await checkMCAStatus();
  if (mcaStatusResponse.status === "FAIL") {
    throw new MCAFailedError();
  }
  if (mcaStatusResponse.status === "PENDING") {
    throw new MCAPendingError();
  }
};
