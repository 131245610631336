import React, { FC } from "react";
import { Loader } from "@laurelroad/lrds-react";

const AccountCreationLoader: FC = () => {
  return (
    <div className={"account-creation__modal"}>
      <Loader />
      <h6>Please do not close this window while we open your account...</h6>
    </div>
  );
};

export default AccountCreationLoader;
