import React, {FC, memo} from "react";
import {Button, DialogModal} from "@laurelroad/lrds-react";
import "./login-notification-modal.scss";
import {redirectToIbx} from "../utils/redirectToIbx";

const LoginNotificationModal: FC = () => {
    const redirectToLogin = () => {
        redirectToIbx();
    }

    return (
        <DialogModal isOpen={true} className={"login-redirect"}>
            <div className={"login-redirect__modal"}>
                <h4><strong>Just a few more steps in your application.</strong></h4>
                <h6>
                    Before you see your application decision, you’ll be asked to log in using the User ID and password which you use to access Laurel Road or KeyBank online banking and the mobile banking app
                </h6>
                <Button onClick={redirectToLogin}>Log In</Button>
            </div>
        </DialogModal>)
};

export default memo(LoginNotificationModal);
