export const NO_RESULTS_ITEM = {
  id: "no_results",
  text: "No Results",
};

export const DROP_DOWN_ITEM_NODE = "li";
export const LOADING_ITEM = {
  id: "loading",
  value: "Loading",
  text: "Loading predictions...",
};
export const LOADING_ITEMS = [LOADING_ITEM];
