import { uiReducer } from "./state/uiSlice";
import { AppViewReducer } from "../components/App";
import { DisclosureInfoReducer } from "../components/DisclosuresPage";
import { ProfileCreationViewStateReducer } from "../components/ProfileCreationPage";
import { PersonalInfoCreationReducer } from "../components/PersonalInfoPage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ApplicationReducer } from "./state/applicationSlice";
import { IDVHardstopReducer } from "../components/IDVHardStopModal/slice";
import { AccountCreationReducer } from "../components/AccountCreation/slice";
import { AMTInfoReducer } from "../components/AMT/slice";
import { ConfirmationEventDataReducer } from "../components/ConfirmationPage/slice";
import { LoginNotificationModalReducer } from "../components/CreateCredentials/components/slice";
import { NetworkReducer } from "../components/Network/slice";
import { BundleReducer } from "../components/BundleModal/slice";
import { BundleErrorReducer } from "../components/ConfirmationPage/BundleView/slice";
import { MultiProductNetworkCallsReducer } from "../services/multiProduct/MultiProductNetworkCallsSlice";

const rootReducer = combineReducers({
  ui: uiReducer,
  appView: AppViewReducer,
  disclosureInfo: DisclosureInfoReducer,
  profileCreationViewState: ProfileCreationViewStateReducer,
  personalInfoViewState: PersonalInfoCreationReducer,
  confirmationEventData: ConfirmationEventDataReducer,
  application: ApplicationReducer,
  idvModal: IDVHardstopReducer,
  accountCreation: AccountCreationReducer,
  amtInfo: AMTInfoReducer,
  loginNotification: LoginNotificationModalReducer,
  network: NetworkReducer,
  bundle: BundleReducer,
  bundleError: BundleErrorReducer,
  multiProductNetworkCalls: MultiProductNetworkCallsReducer
});

export type AppState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export { rootReducer };
export default store;
