import React, { FC, memo } from 'react';
import { Column, Paragraph } from '@laurelroad/lrds-react';
import { useInstructionsParagraph } from '../../hooks/useInstructionsParagraph';

const CheckingBody: FC = () => {
  const checkingInstructions = useInstructionsParagraph();

  return (
    <Column size={12}>
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Managing Your New Checking Account Online
        </span>
      </Paragraph>
      {checkingInstructions}
    </Column>
  );
};

export default memo(CheckingBody);
