import {LoginNotificationModalState} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export const initialState: LoginNotificationModalState = {
    showModal: false
}

export const loginNotificationModalSlice = createSlice({
    name: "LoginNotificationModal",
    initialState,
    reducers: {
        updateShowModalState: (state: LoginNotificationModalState, action:PayloadAction<LoginNotificationModalState>) => {
            return {
                ...state,
                showModal: action.payload.showModal
            }
        }
    }
});

export const { reducer: LoginNotificationModalReducer } = loginNotificationModalSlice;
export const { updateShowModalState } = loginNotificationModalSlice.actions