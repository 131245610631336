import { useEffect } from 'react';
import { invokeIDV } from '../../../utils/amt';
import { ProfileInfo } from '../../ProfileCreationPage/types';
import { useInitializeAMT } from './useInitializeAMT';
import { AMTProspectCreationStatus } from '../types';
import { useDispatch } from 'react-redux';
import { useIsAMTInitialized } from './useIsAMTInitialized';
import { useAMTInfo } from './useAMTInfo';
import { updateProspectCreateSessionId } from '../../ProfileCreationPage/slice';
import { onProspectCreation } from '../asyncActions';
import { AppDispatch } from '../../../store';

const executeAMTProspectCreation = async (
  dispatch: AppDispatch,
  prospectInfo: ProfileInfo
) => {
  try {
    const { sessionId } = await invokeIDV(prospectInfo);

    dispatch(onProspectCreation(AMTProspectCreationStatus.Passed));
    dispatch(updateProspectCreateSessionId(sessionId || ''));
  } catch (isLoginUser) {
    const status = isLoginUser
      ? AMTProspectCreationStatus.LoginUser
      : AMTProspectCreationStatus.Failed;
    dispatch(onProspectCreation(status));
    dispatch(updateProspectCreateSessionId(''));
  }
};

export const useAMTProspectCreation = (prospectInfo: ProfileInfo) => {
  useInitializeAMT();
  const dispatch = useDispatch();
  const amtInitialized = useIsAMTInitialized();
  const { prospectCreationStatus } = useAMTInfo();
  useEffect(() => {
    if (amtInitialized && prospectCreationStatus === AMTProspectCreationStatus.Pending) {
      executeAMTProspectCreation(dispatch, prospectInfo);
    }
  }, [dispatch, prospectInfo, amtInitialized, prospectCreationStatus]);
};
