export const MASTERS_DEGREES = [
  "MBA",
  "Physician Assistant",
  "Master in Nursing",
  "Master in Business (non MBA)",
  "Master in Education",
  "Master in Engineering",
  "Master in Mathematics",
  "Master in Occupational Therapy",
  "Master in Science",
  "Master in Social Science",
];
