import React, { FC } from 'react';
import './styles.scss';
import { useFetchConfirmationEventData } from './hooks/useFetchConfirmationEventData';
import { Navigation } from '../Navigation';
import { useAllowNavigationToExternalSite } from '../Navigation/hooks/AllowNavigationToExternalSite/useAllowNavigationToExternalSite';

const confirmationPage = (component: FC): FC => {
  return props => {
    useFetchConfirmationEventData();
    useAllowNavigationToExternalSite();
    return (
      <>
        <Navigation />
        <div className={'confirmation'}>{component(props)}</div>
      </>
    );
  };
};

export default confirmationPage;
