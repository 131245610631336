import React, { FC } from 'react';
import {
  Button,
  Column,
  Grid,
  Icon,
  Optional,
  Paragraph,
  themeColors,
} from '@laurelroad/lrds-react';
import { useSLOSRedirectUrl } from '../../../hooks/useSLOSRedirectUrl';
import { LAUREL_ROAD_HOMEPAGE } from '../constants';
import { useUpdateNavSteps } from '../hooks/useUpdateNavSteps';
import { useSendConfirmDeniedEvent } from '../../../services/google-tag-manager/useSendConfirmDeniedEvent';
import confirmationPage from '../confirmationPage';
import { useSetCurrentViewConfirmationPage } from '../hooks/useSetCurrentViewConfirmaitonPage';
import { ConfirmationViewProps } from '../types';
import { createBundleProductNamesAndProductTypes } from '../createBundleProductNames';
import { useBundleInfo } from '../../BundleModal/hook/useBundleInfo';
import { useBundleErrorInfo } from '../hooks/useBundleErrorInfo';
import { useConfirmationEventData } from '../hooks/useConfirmationEventData';
import { useIsCheckingApplication } from '../../../hooks/useIsCheckingApplication';
import { useProductText } from '../../../hooks/useUiState';
import {
  FREEDOM_CHECKING_PRODUCT_NAME,
  productTextSupplier,
} from '../../../constants/constants';
import { useIsBundleFreedomCheckingApplication } from '../../../hooks/useIsBundleFreedomChecking';
import BundleFreedomCheckingDeniedInstructions from './DeniedBody/BundleFreedomCheckingDeniedInstructions';
import { useIsFreedomAndBundleFreedomCheckingApplication } from '../../../hooks/useIsFreedomAndBundleFreedomCheckingApplication';

export const DeniedView: FC<ConfirmationViewProps> = ({ productCode, accounts }) => {
  useUpdateNavSteps('denied');
  useSendConfirmDeniedEvent();
  useSetCurrentViewConfirmationPage();
  const slosRedirectUrl = useSLOSRedirectUrl();
  const primaryProductCodeName = useProductText().productName;
  const productName = productCode
    ? productTextSupplier[productCode].productName
    : primaryProductCodeName;
  const { productCodes } = useBundleInfo();
  const { amlStatus } = useConfirmationEventData();
  const { mcaDecline, amlFailures } = useBundleErrorInfo();
  let bundleProductNames;
  let bundleProductTypes;
  if (accounts || mcaDecline) {
    [bundleProductNames, bundleProductTypes] = createBundleProductNamesAndProductTypes(
      accounts,
      productCodes
    );
  }
  const productType = useIsCheckingApplication() ? 'checking' : 'savings';
  return (
    <div className="high-yield-savings" data-testid="confirmation-denied-view">
      <Grid>
        <Column size={1} />
        <Column className="high-yield-savings__confirmation" size={10}>
          <Icon
            className="high-yield-savings__confirmation__icon"
            faIconConfig={{ size: '8x' }}
            color={themeColors.pending}
            icon="university"
          />
          <div className="high-yield-savings__confirmation__message">
            <span className={'hys-text h3 secondary'}>
              It looks like you aren&apos;t eligible for a{' '}
              {bundleProductNames ? bundleProductNames : productName} account.
            </span>
          </div>
          <div className="high-yield-savings__confirmation__details">
            <Grid>
              <Column size={12}>
                <Paragraph>
                  <span className={'hys-text secondary subtitle2 bold'}>
                    Status for {productName} account
                  </span>
                </Paragraph>
                <Optional renderIf={useIsBundleFreedomCheckingApplication(productCode)}>
                  <BundleFreedomCheckingDeniedInstructions />
                </Optional>
                <Optional renderIf={!useIsBundleFreedomCheckingApplication(productCode)}>
                  {amlStatus === 'fail' || amlFailures.length > 0 ? (
                    <AmlDecline productType={bundleProductTypes || productType} />
                  ) : (
                    <ChexSystemsParagraph />
                  )}
                </Optional>
              </Column>
              <Optional renderIf={slosRedirectUrl}>
                <Paragraph className="hys-text secondary">
                  Don’t worry! This doesn’t affect your refinancing approval. We’ll return you
                  to your student loan refinance application, so you can close out your loan.
                </Paragraph>
              </Optional>
              <Column
                className={
                  'high-yield-savings__form-submit-container high-yield-savings__form-submit-container--confirmation margin-auto'
                }
                size={12}>
                <Optional
                  renderIf={slosRedirectUrl && productName !== FREEDOM_CHECKING_PRODUCT_NAME}>
                  <Button onClick={() => (window.location.href = slosRedirectUrl!)}>
                    Finish Student Loan Refinance
                  </Button>
                </Optional>
                <Optional
                  renderIf={!slosRedirectUrl && productName !== FREEDOM_CHECKING_PRODUCT_NAME}>
                  <Button onClick={() => (window.location.href = LAUREL_ROAD_HOMEPAGE!)}>
                    OK
                  </Button>
                </Optional>
                <Optional
                  renderIf={useIsFreedomAndBundleFreedomCheckingApplication(productCode)}>
                  <Button href={window.gradFinUrl}>Back to Membership Portal</Button>
                </Optional>
              </Column>
            </Grid>
          </div>
        </Column>
        <Column size={1} />
      </Grid>
    </div>
  );
};

//TODO: fix above

const AmlDecline = ({ productType }: { productType: string }) => {
  return (
    <>
      <Paragraph>
        During the account opening process, our review determined that we are not able to open
        a {productType} account for you at this time. If you have any questions, please call
        our Premium Care Team at 1-833-427-2265(TDD/TTY at 1-800-539-8336). We appreciate your
        interest, and we hope to be able to serve you in the future.
      </Paragraph>
    </>
  );
};
const ChexSystemsParagraph = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        Sorry, we are unable to open the account you requested because of information on record
        with ChexSystems, Inc., a consumer reporting agency. ChexSystems did not make the
        decision to decline your account application and is unable to provide you with specific
        reasons why the decision was made.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You have rights under state and federal law. Included in these rights are:
      </Paragraph>
      <Paragraph
        className="rights-ol hys-text secondary"
        style={{ paddingLeft: '1.2em', lineHeight: '22px' }}>
        <ol>
          <li>
            The right to obtain a free copy of your consumer report if you make such a request
            to ChexSystems within 60 days of your receipt of this notice
          </li>
          <li>
            The right to dispute the completeness or accuracy of any information contained in
            such report by notifying ChexSystems directly of your dispute.
          </li>
          <li>
            You may contact ChexSystems by visiting their website at{' '}
            <a href="www.chexsystems.com">www.chexsystems.com</a>, by telephone at{' '}
            <a href="tel:1-800-428-9623">1-800-428-9623</a>, by mail at ChexSystems, Inc.,
            Attn: Customer Relations, P.O. Box 583399, Minneapolis, MN 55458.
          </li>
        </ol>
      </Paragraph>
    </>
  );
};

export default confirmationPage(DeniedView);
