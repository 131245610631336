import React, { ChangeEventHandler, FC, useCallback, useState } from "react";
import { Column, NumberField } from "@laurelroad/lrds-react";
import { useDispatch } from "react-redux";
import { updateTextField } from "../../../utils/applicationDispatchFunctions";
import { useMobilePhone } from "./hooks";
import { removePhoneNumberMasking } from "./utils";
import { useProfileInfoErrorMessages } from "../hooks/useProfileInfoErrorMessages";
import { validateProfileFields } from "../slice";
import { useProfileInfo } from "../hooks";

const MobilePhone: FC = () => {
  const dispatch = useDispatch();
  const mobilePhone = useMobilePhone();
  const profileInfo = useProfileInfo();

  const blurHandler = () => {
    dispatch(validateProfileFields(profileInfo));
  };

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        event.target.value = removePhoneNumberMasking(event.target.value);
        dispatch(updateTextField(event));
      },
      [dispatch]
    );

  return (
    <Column size={4}>
      <NumberField
        id={"mobilePhone"}
        name={"mobilePhone"}
        className="high-yield-savings__form-field"
        value={mobilePhone}
        onChange={textFieldChangeHandler}
        onBlur={blurHandler}
        label={"Mobile Phone Number"}
        mask="(000) 000-0000"
        error={useProfileInfoErrorMessages("mobilePhone")}
        required={true}
        showRequiredAsterisk
      />
    </Column>
  );
};

export default MobilePhone;
