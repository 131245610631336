import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application } from '../../types/Application';
import { ApplicationUpdatePayload } from '../../types/ApplicationUpdatedPayload';
import {
  getJarvisEligibilityInfo,
  getZipCodeInfo,
  postProfileInfo,
  putProfileInfo,
} from '../../components/ProfileCreationPage/asyncActions';
import { ApplicationUpdatedResponse } from '../../types/ApplicationUpdatedResponse';
import {
  JarvisDataResponse,
  ZipcodeLookupPayload,
} from '../../components/ProfileCreationPage/types';
import { getPrefillData } from '../../components/App/asyncActions';
import { PrefilledApplication } from '../../components/App/types';
import { toMonthDateYearString } from '../../utils/dateUtils';
import { ApplicationResetFieldsPayload } from '../../types/ApplicationResetFieldsPayload';
import { ProductCode } from '../../constants/ProductCode';

const initialState: Application = {
  citizenshipCountries: [],
  citizenshipStatus: '',
  residencyCountries: [],
  dateOfBirth: '',
  degree: '',
  degreeType: '',
  electronicCommunicationDisclosureAgreement: false,
  email: '',
  employerName: '',
  employmentStatus: '',
  expectedSourcesOfOngoingAccountFunding: [],
  firstName: '',
  foreignGovernmentDisclosure: '',
  governmentIdLocation: '',
  governmentIdNumber: '',
  governmentIdType: '',
  expiryDate: '',
  lastName: '',
  middleName: '',
  mobilePhone: '',
  occupationClassification: '',
  otherOrNoIncomeExplanation: '',
  primaryIncomeSource: '',
  socialSecurityNumber: '',
  socialSecurityNumberConfirmation: '',
  streetAddress: '',
  zipCode: '',
  productCode: '',
  slosRedirectUrl: null,
  loginUser: false,
  accountPurpose: '',
  prefix: '',
  suffix: '',
  overDraftProtection: '',
  isLoyaltyEligible: null,
  employerIndustry: '',
  employerReferralCode: '',
};

const applicationServerResponseReducer = (
  state: Application,
  action: PayloadAction<ApplicationUpdatedResponse> | PayloadAction<ZipcodeLookupPayload>
) => {
  return { ...state, ...action.payload };
};

const jarvisResponseReducer = (
  state: Application,
  action: PayloadAction<JarvisDataResponse>
) => {
  return { ...state, ...action.payload };
};

const prefillDataResponseReducer = (
  state: Application,
  action: PayloadAction<PrefilledApplication>
) => {
  const dob = action.payload.dateOfBirth && toMonthDateYearString(action.payload.dateOfBirth);
  const zip = action.payload.zipCode?.substring(0, 5);
  return {
    ...state,
    ...Object.keys(action.payload)
      .filter(field => action.payload[field as keyof PrefilledApplication] !== null)
      .reduce((acc, next) => {
        return {
          ...acc,
          [next]: action.payload[next as keyof PrefilledApplication],
        };
      }, {} as Partial<PrefilledApplication>),
    dateOfBirth: dob,
    zipCode: zip,
  } as Application;
};

const prefillDataRejectedReducer = (state: Application) => {
  return {
    ...state,
    productCode: getProductCode(),
  };
};

const getProductCode = () => {
  const CheckingRegex = /^checking/;
  const [subDomain, ,] = window.location.hostname.split('.');
  return CheckingRegex.test(subDomain) ? ProductCode.CHECKING : ProductCode.SAVINGS;
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    resetApplicationFields: (
      state: Application,
      action: PayloadAction<ApplicationResetFieldsPayload>
    ) => {
      const updatedFields = action.payload.fields.reduce(
        (fieldValueMap, fieldName) => ({
          ...fieldValueMap,
          [fieldName]: initialState[fieldName],
        }),
        {}
      );
      return {
        ...state,
        ...updatedFields,
      };
    },
    updateApplication: (
      state: Application,
      action: PayloadAction<ApplicationUpdatePayload>
    ) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postProfileInfo.fulfilled, applicationServerResponseReducer)
      .addCase(putProfileInfo.fulfilled, applicationServerResponseReducer)
      .addCase(getPrefillData.fulfilled, prefillDataResponseReducer)
      .addCase(getPrefillData.rejected, prefillDataRejectedReducer)
      .addCase(getZipCodeInfo.fulfilled, applicationServerResponseReducer)
      .addCase(getJarvisEligibilityInfo.fulfilled, jarvisResponseReducer);
  },
});

export const { updateApplication, resetApplicationFields } = applicationSlice.actions;
export const { reducer: ApplicationReducer } = applicationSlice;
