import { Optional } from "@laurelroad/lrds-react";
import React, { FunctionComponent, memo } from "react";
import { ApprovedView } from "../ApprovedView/ApprovedView";
import { DeniedView } from "../DeniedView/DeniedView";
import { PendingView } from "../PendingView/PendingView";
import { ReferredView } from "../ReferredView/ReferredView";
import { sortDuplicateBundleAccounts } from "./sortDuplicateBundleAccounts";
import { BundleAccounts, BundleMergeViewProps } from "../types";
import {
  AccountCreationResponse,
  AccountStatus,
} from "../../AccountCreation/types";

const BundleMergeView: FunctionComponent<BundleMergeViewProps> = ({
  accounts,
  amlFailures,
}) => {
  let sortedAccounts: BundleAccounts = sortDuplicateBundleAccounts(accounts);
  let amlFailedAccounts: AccountCreationResponse[] = amlFailures.map(
    (productCode) => {
      return {
        accountStatus: AccountStatus.Denied,
        productCode: productCode,
      } as AccountCreationResponse;
    }
  );

  sortedAccounts[AccountStatus.Denied].push(...amlFailedAccounts);

  return (
    <>
      <Optional renderIf={sortedAccounts[AccountStatus.Approved].length > 0}>
        <ApprovedView />
      </Optional>
      <Optional renderIf={sortedAccounts[AccountStatus.Denied].length > 0}>
        <DeniedView accounts={sortedAccounts[AccountStatus.Denied]} />
      </Optional>
      <Optional renderIf={sortedAccounts[AccountStatus.Pending].length > 0}>
        <PendingView accounts={sortedAccounts[AccountStatus.Pending]} />
      </Optional>
      <Optional renderIf={sortedAccounts[AccountStatus.Referred].length > 0}>
        <ReferredView accounts={sortedAccounts[AccountStatus.Referred]} />
      </Optional>
    </>
  );
};

export default memo(BundleMergeView);
