import { useSelector } from "react-redux";
import { CitizenshipState } from "./types";
import { AppState } from "../../../store";

export const useCitizenshipInfo = () => {
  return useSelector<AppState, CitizenshipState>(({ application }) => {
    const {
      citizenshipStatus,
      citizenshipCountries,
      residencyCountries,
    } = application;
    return {
      citizenshipCountries,
      residencyCountries,
      citizenshipStatus,
    };
  });
};
