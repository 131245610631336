export const PERKS_OFFERS_REDIRECT_URL =
  "https://laurelroad.key.com/ibxolb/login/index.html#/login?dest=DRD&desturl=https://offers.laurelroad.com&destovr=true";

export const STANDALONE_CHECKING_ACCOUNT_REDIRECT_URL = "https://checking.laurelroad.com";

export const LAUREL_ROAD_KEY_URL = "https://laurelroad.key.com";

export const FETCH_CONFIRMATION_EVENT_DATA_ENDPOINT =
  "/applications/confirmationEventData";

export const LAUREL_ROAD_HOMEPAGE = "https://laurelroad.com";

const CONFIRMATION_ROUTE = "/confirmation";

const TIMEOUT_ROUTE = "/timeout";
const APPROVED_ROUTE = "/approved";
const DENIED_ROUTE = "/denied";
const PENDING_ROUTE = "/pending";
const REFERRED_ROUTE = "/referred";
const BUNDLE_ROUTE = "/bundle";

export const CONFIRMATION_TIMEOUT_ROUTE = `${CONFIRMATION_ROUTE}${TIMEOUT_ROUTE}`;
export const CONFIRMATION_APPROVED_ROUTE = `${CONFIRMATION_ROUTE}${APPROVED_ROUTE}`;
export const CONFIRMATION_DENIED_ROUTE = `${CONFIRMATION_ROUTE}${DENIED_ROUTE}`;
export const CONFIRMATION_PENDING_ROUTE = `${CONFIRMATION_ROUTE}${PENDING_ROUTE}`;
export const CONFIRMATION_REFERRED_ROUTE = `${CONFIRMATION_ROUTE}${REFERRED_ROUTE}`;
export const CONFIRMATION_BUNDLE_ROUTE = `${CONFIRMATION_ROUTE}${BUNDLE_ROUTE}`;
