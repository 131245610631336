import "./styles.scss";
import React, { FC } from "react";
import { DialogModal } from "@laurelroad/lrds-react";
import { LAUREL_ROAD_HOMEPAGE, STANDALONE_CHECKING_ACCOUNT_REDIRECT_URL } from "../ConfirmationPage/constants";
import { useAllowNavigationToExternalSite } from "../Navigation/hooks/AllowNavigationToExternalSite/useAllowNavigationToExternalSite";

const EligibilityModal: FC = () => {
    useAllowNavigationToExternalSite();

    const cancelButtonClickHandler = () => {
        (window.location.href = LAUREL_ROAD_HOMEPAGE)
    }

    const openCheckingAccnt = () => {
        (window.location.href = STANDALONE_CHECKING_ACCOUNT_REDIRECT_URL)
    }

    return (
        <DialogModal
            hasLeftButton
            hasRightButton
            isOpen={true}
            className="eligibility-modal"
            leftButtonText="Cancel Application"
            rightButtonText="Open Standard Account"
            title="It looks like we've met before."
            rightButtonProps={{ onClick: openCheckingAccnt }}
            leftButtonProps={{ type: "secondary", onClick: cancelButtonClickHandler }}
        >
            <div className="eligibility__message">
                Our records indicate that you may have an exisiting checking account with Laurel Road. At this time, the Laurel Road Loyalty Checking<sup>SM</sup> account is only avaliable to first-time checking account applicants. If you'd like to open a standard Laurel Road Checking<sup>SM</sup> application instead, with many of the same convenient account features, please click below to get started.
            </div>
        </DialogModal>
    );
};

EligibilityModal.defaultProps = {};

export default EligibilityModal;