import React, { ChangeEvent } from 'react';
import { resetApplicationFields, updateApplication } from '../store/state/applicationSlice';
import { SelectFieldEvent } from '@laurelroad/lrds-react/dist/src/components/SelectField';
import { MultiSelectOption } from '../components/MultiSelect/types';
import { DatePickerChangeEvent } from '@laurelroad/lrds-react/dist/src/components/DatePicker';
import { CountryCode, CountryName } from '../components/PersonalInfoPage/Citizenship/types';
import {
  ExpectedSourceOfOngoingFundingId,
  ExpectedSourceOfOngoingFundingName,
} from '../components/PersonalInfoPage/SourcesOfOngoingAccountFunding/types';

export const resetFields = (fields: string[]) => resetApplicationFields({ fields });

export const updateTextField = (event: React.ChangeEvent<HTMLInputElement>) =>
  updateApplication({ key: event.target.name, value: event.target.value });

export const updateTextFieldValueWithUppercaseFirstLetter = (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const name = event.target.value
    ? event.target.value[0].toUpperCase() + event.target.value.slice(1)
    : '';
  return updateApplication({
    key: event.target.name,
    value: name,
  });
};

export const updateCheckboxField = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
  const target = event.target as HTMLInputElement;
  return updateApplication({
    key: target.name,
    value: target.checked,
  });
};

export const updateSelectField = (event: SelectFieldEvent<any>) => {
  return updateApplication({ key: event.fieldName!, value: event.id });
};

export const updateResidencyCountries = (
  selectedOptions: Array<MultiSelectOption<CountryCode, CountryName>>
) => {
  return updateApplication({
    key: 'residencyCountries',
    value: selectedOptions,
  });
};

export const updateCitizenshipCountries = (
  selectedOptions: Array<MultiSelectOption<CountryCode, CountryName>>
) => {
  return updateApplication({
    key: 'citizenshipCountries',
    value: selectedOptions,
  });
};

export const updateExpectedSourcesOfOngoingFunding = (
  selectedOptions: Array<
    MultiSelectOption<ExpectedSourceOfOngoingFundingId, ExpectedSourceOfOngoingFundingName>
  >
) => {
  return updateApplication({
    key: 'expectedSourcesOfOngoingAccountFunding',
    value: selectedOptions,
  });
};

export const updateRadioField = (event: ChangeEvent<HTMLInputElement>) => {
  return updateApplication({
    key: event.target.name,
    value: event.target.value,
  });
};

export const updateDateField = (event: DatePickerChangeEvent) => {
  return updateApplication({
    key: event.name || 'undefined',
    value: event.value || 'undefined',
  });
};
