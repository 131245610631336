import Joi from "joi";

export const CitizenshipCountriesSchema = Joi.when(
  Joi.ref("citizenshipStatus"),
  {
    not: "US",
    then: Joi.array().min(1).required(),
    otherwise: Joi.optional(),
  }
).messages({ "array.min": "Citizenship Countries is required" });
