import Joi from "joi";

const validName = new RegExp(/^([^0-9]*)$/);
const minLengthNameSchema = Joi.string().min(1).regex(validName);
const nameSchema = Joi.string().allow("").allow(null).regex(validName);

export const FirstNameSchema = minLengthNameSchema.messages({
  "string.empty": "First Name is required",
  "string.pattern.base": "Invalid name",
});
export const MiddleNameSchema = nameSchema.messages({
  "string.pattern.base": "Invalid name",
});
export const LastNameSchema = minLengthNameSchema.messages({
  "string.empty": "Last Name is required",
  "string.pattern.base": "Invalid name",
});
export const SuffixSchema = nameSchema.error(new Error("Suffix is required"));

export const ProspectNameSchema = Joi.object({
  firstName: FirstNameSchema,
  middleName: MiddleNameSchema,
  lastName: LastNameSchema,
  suffix: SuffixSchema,
});
