import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { ProspectNameState } from "./types";

export const useProspectName = () =>
  useSelector<AppState, ProspectNameState>(({ application }) => {
    const { firstName, middleName, lastName, suffix } = application;
    return {
      firstName,
      middleName,
      lastName,
      suffix
    };
  });
