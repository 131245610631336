import Joi, { Schema } from "joi";
import { StateCode } from "../../types";

const driversLicenseErrorMessages = {
  "string.empty": "ID Number is required",
  "string.pattern.base": "Please re-enter in the correct format",
};

const governmentIdNumberSchema = Joi.string().min(5);

export const DriversLicenseNumberSchemaMap = new Map<StateCode, Schema>([
  [
    "AL",
    Joi.string()
        .pattern(/^[0-9]{1,8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "AK",
    Joi.string()
      .pattern(/^[0-9]{1,7}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "AZ",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{8}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "AR",
    Joi.string()
      .pattern(/^[0-9]{4,9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "CA",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{7}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "CO",
    Joi.string()
      .pattern(/^[0-9]{9}$|^[a-zA-Z][0-9]{3,6}$|^[a-zA-Z]{2}[0-9]{2,5}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "CT",
    Joi.string()
      .pattern(/^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "DE",
    Joi.string()
      .pattern(/^[0-9]{1,7}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "DC",
    Joi.string()
      .pattern(/^[0-9]{7}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "FL",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "GA",
    Joi.string()
      .pattern(/^[0-9]{7,9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "HI",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{8}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "ID",
    Joi.string()
      .pattern(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "IL",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{11}$|^[a-zA-Z][0-9]{12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "IN",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{9}$|^[0-9]{9}$|^[0-9]{10}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "IA",
    Joi.string()
      .pattern(/^[0-9]{9}$|^[0-9]{3}[a-zA-Z]{2}[0-9]{4}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "KS",
    Joi.string()
      .pattern(
        /^[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z]$|^[a-zA-Z][0-9]{8}$|^[0-9]{9}$/
      )
      .messages(driversLicenseErrorMessages),
  ],
  [
    "KY",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{8}$|^[a-zA-Z][0-9]{9}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "LA",
    Joi.string()
      .pattern(/^[0-9]{1,9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "ME",
    Joi.string()
      .pattern(/^[0-9]{7}$|^[0-9]{7}[a-zA-Z]$|^[0-9]{8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MD",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{12}$|[a-zA-Z]{2}[0-9]{11}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MA",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{8}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MI",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{10}$|^[a-zA-Z][0-9]{12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MN",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MS",
    Joi.string()
      .pattern(/^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MO",
    Joi.string()
      .pattern(
        /^[a-zA-Z][0-9]{5,9}$|^[a-zA-Z][0-9]{6}[rR]$|^[0-9]{8}[a-zA-Z]{2}$|^[0-9]{9}[a-zA-Z]$|^[0-9]{9}$|^[0-9]{3}[a-zA-Z]{1}[0-9]{6}$/
      )
      .messages(driversLicenseErrorMessages),
  ],
  [
    "MT",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{8}$|^[0-9]{13}$|^[0-9]{9}$|^[0-9]{14}$|^[a-zA-Z]{3}[0-9]{10}/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NE",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{3,8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NV",
    Joi.string()
      .pattern(/^[0-9]{9}$|^[0-9]{10}$|^[0-9]{12}$|^[xX][0-9]{8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NH",
    Joi.string()
      .pattern(/^[0-9]{2}[a-zA-Z]{3}[0-9]{5}$|^[a-zA-Z]{3}[0-9]{8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NJ",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{14}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NM",
    Joi.string()
      .pattern(/^[0-9]{8}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NY",
    Joi.string()
      .pattern(
        /^[a-zA-Z][0-9]{7}$|^[a-zA-Z][0-9]{18}$|^[0-9]{8}$|^[0-9]{9}$|^[0-9]{16}$|^[a-zA-Z]{8}$/
      )
      .messages(driversLicenseErrorMessages),
  ],
  [
    "NC",
    Joi.string()
      .pattern(/^[0-9]{1,12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "ND",
    Joi.string()
      .pattern(/^[a-zA-Z]{3}[0-9]{6}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "OH",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{4,8}$|^[a-zA-Z]{2}[0-9]{3,7}$|^[0-9]{8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "OK",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{9}$|^[0-9]{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "OR",
    Joi.string()
      .pattern(/^[0-9]{1,9}$|^[a-zA-Z][0-9]{6}$|^[a-zA-Z]{2}[0-9]{5}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "PA",
    Joi.string()
      .pattern(/^[0-9]{8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "PR",
    Joi.string()
      .pattern(/^\d{5,7}$|^\d{9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "RI",
    Joi.string()
      .pattern(/^[0-9]{7}$|^[a-zA-Z][0-9]{6}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "SC",
    Joi.string()
      .pattern(/^[0-9]{5,11}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "SD",
    Joi.string()
      .pattern(/^[0-9]{6,10}$|^[0-9]{12}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "TN",
    Joi.string()
      .pattern(/^[0-9]{7,9}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "TX",
    Joi.string()
      .pattern(/^[0-9]{7,8}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "UT",
    Joi.string()
      .pattern(/^[0-9]{4,10}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "VT",
    Joi.string()
      .pattern(/^[0-9]{8}$|^[0-9]{7}[aA]$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "VA",
    Joi.string()
      .pattern(
        /^[a-zA-Z][0-9]{8}$|^[a-zA-Z][0-9]{9}$|^[a-zA-Z][0-9]{10}$|^[a-zA-Z][0-9]{11}$|^[0-9]{9}$/
      )
      .messages(driversLicenseErrorMessages),
  ],
  [
    "WA",
    Joi.string()
      .pattern(
        /^[a-zA-Z][a-zA-Z0-9*]{11}$|^[a-zA-Z]{2}[a-zA-Z0-9*]{10}$|^[a-zA-Z]{3}[a-zA-Z0-9*]{9}$|^[a-zA-Z]{4}[a-zA-Z0-9*]{8}$|^[a-zA-Z]{5}[a-zA-Z0-9*]{7}$|^[a-zA-Z]{6}[a-zA-Z0-9*]{6}$|^[a-zA-Z]{7}[a-zA-Z0-9*]{5}$/
      )
      .messages(driversLicenseErrorMessages),
  ],
  [
    "WV",
    Joi.string()
      .pattern(/^[0-9]{7}$|^[a-zA-Z]{1,2}[0-9]{5,6}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "WI",
    Joi.string()
      .pattern(/^[a-zA-Z][0-9]{13}$/)
      .messages(driversLicenseErrorMessages),
  ],
  [
    "WY",
    Joi.string()
      .pattern(/^[0-9]{9,10}$/)
      .messages(driversLicenseErrorMessages),
  ],
]);

export const GovernmentIdNumberSchema = Joi.string()
  .pattern(/^((?!-).)*$/)
  .when("governmentIdType", {
    is: "DLN",
    then: Joi.string().when("governmentIdLocation", {
      switch: Array.from(DriversLicenseNumberSchemaMap, ([state, schema]) => {
        return { is: state, then: schema };
      }),
      otherwise: governmentIdNumberSchema,
    }),
    otherwise: governmentIdNumberSchema,
  })
  .messages({
    "string.empty": "Government I.D. number is required",
    "string.pattern.base": "Please remove dashes",
  });
