/**
 * Performs GET request via Fetch with the supplied parameters
 * @param url - url to send request to
 * @return {Promise<E>} - Promise which resolves to type E
 */
import { SetError } from "../../components/Network/slice";
import { APIResponse } from "../../components/Network/types";
import store from "../../store";
import {BAD_REQUEST, jsonHeaders} from "./constants";

async function getRequest<E>(url: string): Promise<E> {
  const requestResult: APIResponse = await fetch(url, {
    method: "GET",
    headers: jsonHeaders(),
  });

  const result = await requestResult.json();

  if (requestResult.status >= BAD_REQUEST) {
    const error = {
      status: requestResult.status,
      message: `GET request to ${url} failed`,
      fs_ref_number: result.fs_ref_number,
    };

    store.dispatch(SetError(error));

    return Promise.reject(error);
  }

  return result;
}

export { getRequest };