import { ProfileInfo } from './types';
import { Application } from '../../types/Application';
import { ProfileInfoSchema } from './constants';
import { toIsoString, toYearMonthDateString } from '../../utils/dateUtils';

export const convertApplicationToProfileInfo = (application: Application): ProfileInfo => {
  const {
    id,
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth,
    mobilePhone,
    streetAddress,
    streetAddressTwo,
    zipCode,
    socialSecurityNumber,
    socialSecurityNumberConfirmation,
    electronicCommunicationDisclosureAgreement,
    city,
    state,
    productCode,
    prefix,
    suffix,
  } = application;

  return {
    id,
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth,
    mobilePhone,
    streetAddress,
    streetAddressTwo,
    zipCode,
    socialSecurityNumber,
    socialSecurityNumberConfirmation,
    electronicCommunicationDisclosureAgreement,
    city,
    state,
    productCode,
    prefix,
    suffix,
  };
};

export const sanitizeProfileInfo = (profileInfo: ProfileInfo): ProfileInfo => {
  return {
    ...profileInfo,
    zipCode: extractFiveDigitZip(profileInfo.zipCode),
    dateOfBirth: toYearMonthDateString(toIsoString(profileInfo.dateOfBirth)),
  };
};
export const validateProfileInfo = (profileInfo: ProfileInfo) => {
  const result = ProfileInfoSchema.validate(profileInfo);
  // If a validation error is present on the validation, then the profile is not valid
  if (result.error) {
    return false;
  }
  return true;
};

const extractFiveDigitZip = (zipCode: string) => {
  const [zip] = zipCode.split('-');
  return zip;
};
