function removeIdvSessionCookies () {
  deleteCookie("MRHSession");
  deleteCookie("LastMRH_Session");
}


interface setCookieParms {
  name: string;
  value: string;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

function setCookie({name, value, days, hours, minutes, seconds}: setCookieParms) {
  const expireDate = new Date();
  if (days) {
      expireDate.setDate(expireDate.getDate() + days);
  }
  if (hours) {
      expireDate.setHours(expireDate.getHours() + hours);
  }
  if (minutes) {
      expireDate.setMinutes(expireDate.getMinutes() + minutes);
  }
  if (seconds) {
      expireDate.setSeconds(expireDate.getSeconds() + seconds);
  }

  document.cookie = name +"="+ encodeURI(value) +
      ";domain="+ window.location.hostname +
      ";path=/"+
      ";expires="+expireDate.toUTCString();
}

function deleteCookie(name: string) {
  setCookie({name: name, value: ""});
}

export { removeIdvSessionCookies }
