import React, { FC, useState } from "react";
import Select from "react-select";
import { MultiSelectChangeHandler, MultiSelectProps } from "./types";
import { Optional, themeColors } from "@laurelroad/lrds-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.scss";

const MultiSelect: FC<MultiSelectProps> = ({
  onBlur,
  options,
  onChange,
  required,
  selected,
  placeholder,
  error,
  id,
}) => {
  const [focused, setFocused] = useState(false);

  const borderColor = (isFocused: boolean) => {
    let borderColor = themeColors.primary;
    if (error) {
      borderColor = themeColors.error;
    }
    if (!error && isFocused) {
      borderColor = themeColors.pending;
    }
    return borderColor;
  };
  return (
    <>
      <Select
        id={id}
        isMulti
        options={options}
        defaultValue={selected}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          setFocused(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
        isFocused={focused}
        onChange={onChange as unknown as MultiSelectChangeHandler<any, any>}
        styles={{
          container: (base) => ({
            ...base,
            color: themeColors.error,
            width: "100%",
            height: "56px",
            marginTop: "6px",
            minWidth: "300px",
            maxWidth: "480px",
            alignSelf: "baseline",
            display: "inline-block",
          }),
          control: (_, { isFocused }) => ({
            overflow: "visible",
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            height: "56px",
            minHeight: "56px",
            transition: "all 100ms",
            cursor: "pointer",
            outline: "none",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            boxSizing: "border-box",
            color: themeColors.secondary,
            caretColor: themeColors.primary,
            borderColor: borderColor(isFocused),
            boxShadow: isFocused
              ? "inset 1px 1px #f2aa04, inset -1px 0 #f2aa04, inset 0 -1px #f2aa04"
              : "inset 1px 0 transparent, inset -1px 0 transparent, inset 0 -1px transparent",
            "&:focus": {
              boxShadow:
                "inset 1px 1px #f2aa04, inset -1px 0 #f2aa04, inset 0 -1px #f2aa04",
            },
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: "1rem",
            overflow: "visible",
          }),
          option: (base) => {
            return {
              ...base,
              height: "60px",
              cursor: "pointer",
              paddingTop: "18px",
              paddingLeft: "30px",
              alignSelf: "center",
              color: themeColors.secondary,
              background: themeColors.surface,
              "&:hover,&:active": { background: themeColors.surface2 },
              "&:not(:last-child)": { borderBottom: "solid 1px silver" },
            };
          },
          menu: (base) => ({ ...base, marginTop: 2 }),
          menuList: (base) => ({
            ...base,
            marginTop: 0,
            padding: 0,
            border: "none",
            outline: "none",
            borderRadius: "8px",
            boxShadow:
              "0 3px 6px rgba(0, 0, 0, 0.16), 0 -10px 8px rgba(0, 0, 0, 0.03)",
          }),
          indicatorSeparator: () => ({ display: "none" }),
          input: () => ({
            fontSize: "16px",
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
            paddingRight: "12px",
            color: error ? themeColors.error : themeColors.primary,
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: 0,
            paddingRight: "8px",
            color: themeColors.primary,
            "&:hover": {
              opacity: 0.75,
              transform: "scale(1.1,1.1)",
              transition: "all ease-in-out 100ms",
            },
          }),
          multiValue: (base) => ({
            ...base,
            height: "32px",
            minWidth: "106px",
            borderRadius: "24px",
            color: themeColors.surface,
            background: themeColors.secondary,
          }),
          multiValueLabel: (base) => ({
            ...base,
            paddingTop: "6px",
            paddingLeft: "12px",
            color: themeColors.surface,
          }),
          multiValueRemove: (base) => ({
            ...base,
            paddingTop: "0",
            paddingRight: "8px",
            "&:hover": {
              background: "transparent",
              transform: "scale(1.5,1.5)",
              transition: "all ease-in-out 100ms",
              color: themeColors.surface,
            },
          }),
          placeholder: (base, state) => ({
            ...base,
            position: "absolute",
            background: "white",
            paddingLeft: "4px",
            paddingRight: "4px",
            top:
              state.hasValue || state.selectProps.inputValue ? "-23%" : "50%",
            transition: "top 0.2s, font-size 0.2s",
            fontSize:
              (state.hasValue ||
                state.selectProps.inputValue ||
                state.selectProps.isFocused) &&
              14,
          }),
        }}
      />
      <Optional renderIf={error}>
        <span
          className="lrds-textfield__assistive-text"
          style={{ color: themeColors.error, top: "unset", marginTop: "0px" }}
        >
          {error}
        </span>
      </Optional>
      <Optional renderIf={required}>
        <span className="multi-select__required-asterisk">*</span>
      </Optional>
    </>
  );
};
export default MultiSelect;
