import React, { memo } from "react";
import { useOverDraftProtectionUpdated } from "../../hooks/useOverDraftProtectionUpdated";
import "./overdraft-protection.scss";
import { useOverDraftProtectionInfo } from "./useOverDraftProtectionInfo";

const OverdraftProtection = () => {
  const overDraftProtectionHandler = useOverDraftProtectionUpdated();
  const { overDraftProtection } = useOverDraftProtectionInfo();
  return (
    <fieldset className="disclosures__overdraft-consent">
      <span className={"disclosures__overdraft-consent__message"}>
        Do you want Laurel Road to pay overdrafts on ATM transactions and
        everyday debit transactions?
      </span>
      <div className="lrds-radio-group">
        <label className="lrds-radio" htmlFor={"overDraftProtection_Yes"}>
          <input
            type="radio"
            onChange={overDraftProtectionHandler}
            id={"overDraftProtection_Yes"}
            name="overDraftProtection"
            value={"true"}
            checked={overDraftProtection === "true"}
          />
          <span>
            <h5>Pay Overdrafts</h5>
            <p>
              When we choose, we may authorize and pay ATM transaction and
              everyday debit card transactions into overdraft when there is not
              enough money in your account. When a transaction is paid into
              overdraft, we may charge you a fee up to $20. This minimizes
              the risk of having your card declined for a transaction.
            </p>
          </span>
        </label>
        <label className="lrds-radio" htmlFor={"overDraftProtection_No"}>
          <input
            type="radio"
            onChange={overDraftProtectionHandler}
            id={"overDraftProtection_No"}
            name="overDraftProtection"
            value={"false"}
            checked={overDraftProtection === "false"}
          />
          <span>
            <h5>Don't Pay Overdrafts</h5>
            <p>
              When possible, we will generally reject ATM transactions and
              everyday debit card transactions if there isn’t enough money in
              your account. We may still pay other items into overdraft at our
              discretion. This may result in fewer fees being assessed to your
              account.
            </p>
          </span>
        </label>
      </div>
    </fieldset>
  );
};

export default memo(OverdraftProtection);
