import React, { FC, memo } from "react";
import {
  Button,
  Column,
  Grid,
  Icon,
  Paragraph,
  themeColors,
} from "@laurelroad/lrds-react";
import { LAUREL_ROAD_HOMEPAGE } from "../constants";
import { useProductText } from "../../../hooks/useUiState";

const TimeoutHighYieldView: FC = () => {
  const productName = useProductText().productName;

  return (
    <div className="high-yield-savings" data-testid="confirmation-denied-view">
      <Grid>
        <Column size={1} />
        <Column className="high-yield-savings__confirmation" size={10}>
          <Icon
            className="high-yield-savings__confirmation__icon"
            faIconConfig={{ size: "8x" }}
            color={themeColors.pending}
            icon="folder-open"
          />
          <div className="high-yield-savings__confirmation__message">
            <span className={"hys-text h3 secondary"}>
              We need a little more time to review your application.
            </span>
          </div>
          <div className="high-yield-savings__confirmation__details">
            <Grid>
              <Column size={12}>
                <Paragraph>
                  <span className={"hys-text secondary subtitle2 bold"}>
                    Next Steps
                  </span>
                </Paragraph>
                <Paragraph className={"hys-text secondary"}>
                  Thank you for applying for a {productName} account! We
                  received all of your information, but we still need a little
                  more time to review your application.
                </Paragraph>
                <Paragraph className={"hys-text secondary"}>
                  When we've finished this review, we'll send an email with a
                  decision about your application to the address you provided.
                  We appreciate your patience -- You'll hear from us within a
                  few days!
                </Paragraph>
              </Column>
              <Column size={12}>
                <Paragraph className={"hys-text bold timeout-body"}>
                  If you have any questions, please call Member Services at
                  1-833-427-2265 (TDD/TTY at 1-800-539-8336).
                </Paragraph>
              </Column>
              <Column
                className={
                  "high-yield-savings__form-submit-container high-yield-savings__form-submit-container--confirmation margin-auto"
                }
                size={12}
              >
                <Button
                  onClick={() => (window.location.href = LAUREL_ROAD_HOMEPAGE!)}
                >
                  OK
                </Button>
              </Column>
            </Grid>
          </div>
        </Column>
        <Column size={1} />
      </Grid>
    </div>
  );
};

export default memo(TimeoutHighYieldView);
