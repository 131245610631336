import isEqual from 'react-fast-compare';
import { State, UiState } from '../store/types';
import { useSelector } from 'react-redux';
import { ProductText } from '../constants/constants';
const useUiState = (): UiState => {
  return useSelector<State, UiState>(({ ui }) => ui, isEqual);
};

export const useLeavingModalDestination = (): string => {
  const uiState = useUiState();
  return uiState.leavingModalDestination;
};

export const useCreateCredentialsModalDestination = (): string => {
  const uiState = useUiState();
  return uiState.createCredentialsModalDestination;
};

export const useProductText = (): ProductText => {
  return useUiState().productText;
};

export default useUiState;
