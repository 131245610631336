import { DialogModal, Optional } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';
import { useIsBundled } from '../../../hooks/useIsBundled';
import { useIsCheckingApplication } from '../../../hooks/useIsCheckingApplication';
import { useSLOSRedirectUrl } from '../../../hooks/useSLOSRedirectUrl';
import { useIsBundleFreedomCheckingApplication } from '../../../hooks/useIsBundleFreedomChecking';

const AmlHardstopModal: FC = () => {
  const slosRedirectUrl = useSLOSRedirectUrl();
  const isBundled = useIsBundled();
  const isChecking = useIsCheckingApplication();
  const isBundleFreedomChecking = useIsBundleFreedomCheckingApplication();
  const productType = isChecking ? 'checking' : 'savings';
  const link = slosRedirectUrl || 'http://laurelroad.com/';
  const linkText = isBundled ? 'Finish Student Loan Refinance' : 'OK';
  return (
    <DialogModal
      isOpen={true}
      title="We've hit a speed bump."
      hasLeftButton
      leftButtonProps={{
        text: linkText,
        href: link,
      }}
      buttonPosition={'center'}>
      <Optional renderIf={isBundleFreedomChecking}>
        <strong>
          <p style={{ textAlign: 'center' }}>
            Based on a review of the information provided in your application, we are not able
            to open a checking account for you at this time.
          </p>
          <br />
          <p style={{ textAlign: 'center' }}>
            Check your email for next steps regarding your membership.
          </p>
        </strong>
      </Optional>
      <Optional renderIf={isBundled}>
        <strong>
          <span>
            During the account opening process, our review determined that we are not able to
            open a {productType} account for you at this time. But don't worry! This doesn't
            affect your refinancing approval. You can return to your student loan refinance
            application to close out your loan.
          </span>
          <br />
          <br />
          If you have any questions, please call Member Services at 1-833-427-2265(TDD/TTY at
          1-800-539-8336).
        </strong>
      </Optional>
      <Optional renderIf={!isBundled && !isBundleFreedomChecking}>
        <strong>
          <span>
            During the account opening process, our review determined that we are not able to
            open a {productType} account for you at this time. If you have any questions,
            please call our Premium Care Team at 1-833-427-2265(TDD/TTY at 1-800-539-8336). We
            appreciate your interest, and we hope to be able to serve you in the future.
          </span>
        </strong>
      </Optional>
    </DialogModal>
  );
};

export default memo(AmlHardstopModal);
