import { Icon, Optional } from "@laurelroad/lrds-react";
import React, { FunctionComponent, memo } from "react";
import "./acknowledgement-row.scss";
import { AcknowledgementRowProps } from "./types";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const AcknowledgementRow: FunctionComponent<AcknowledgementRowProps> = ({
  acknowledgement,
  onClick,
  isActiveRow
}) => {
  const { displayName, file, acknowledged, viewed } = acknowledgement;
  const isMobile = useIsMobile();

  return (
  <div
      className="disclosures__acknowledgement-list__row"
      onClick={() => onClick(acknowledgement)}>
    <div className="display-flex">
      <Icon
              className={isActiveRow ? "active-row-icon" : "hidden-row-icon"}
      icon={"chevron-down"}
      faIconConfig={{
      size: "lg"
      }}
      />
      <div className={`high-yield-savings__disclosures__acknowledgement-row${isMobile ? "" : "__name"}`}>
      <span className={"high-yield-savings__primary hys-text secondary"}>
      {displayName}
    </span>
</div>
    </div>
      <div className="disclosures__acknowledgement-list__row__icons">
        <Optional renderIf={acknowledged}>
          <Icon
            className="high-yield-savings__icon"
            icon="check-circle"
            faIconConfig={{
              size: "lg",
            }}
          />
        </Optional>
        <Optional renderIf={viewed}>
          <a
            href={file}
            download={`${displayName}.pdf`}
            className={"high-yield-savings__download-button"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon
              className="high-yield-savings__icon"
              icon={"download"}
              faIconConfig={{
                size: "lg",
              }}
            />
          </a>
        </Optional>
      </div>
  </div>
  );
};

export default memo(AcknowledgementRow);
