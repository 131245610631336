import { IncomeState } from "./types";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { PRIMARY_INCOME_SOURCES } from "./constants/PrimaryIncomeSources";

export const useIncomeInfo = () => {
  return useSelector<AppState, IncomeState>(({ application }) => {
    const { primaryIncomeSource, otherOrNoIncomeExplanation } = application;
    return {
      primaryIncomeSource,
      otherOrNoIncomeExplanation,
    };
  });
};

export const useIsOtherIncome= () => {
    const { primaryIncomeSource } = useIncomeInfo();
    return primaryIncomeSource === PRIMARY_INCOME_SOURCES.Other;
}