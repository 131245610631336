import "./styles.scss";
import React, { FC } from "react";
import { Modal } from "@laurelroad/lrds-react";
import { SavingsBundleModal } from "./SavingsBundleBody/SavingsBundleModal";
import { useBundleInfo } from "./hook/useBundleInfo";
import { useIsCheckingApplication } from "../../hooks/useIsCheckingApplication";

const BundleModal: FC = () => {
  const { showModal } = useBundleInfo();
  const isCheckingApplication = useIsCheckingApplication();
  const isModalVisible = showModal && isCheckingApplication;
  const visibility = isModalVisible ? "visible" : "hidden";

  return (
    <div className="bundle-modal-container" style={{ visibility: visibility }}>
      <Modal isOpen={true} className="bundle-modal">
        <SavingsBundleModal />
      </Modal>
    </div>
  );
};

BundleModal.defaultProps = {};

export default BundleModal;
