import { useDispatch } from "react-redux";
import { useSelectAcknowledgement } from "./useSelectAcknowledgement";
import { useCallback } from "react";
import { acceptAcknowledgement } from "../slice";
import { useDisclosureInfo } from "./useDisclosureInfo";

export const useAcceptAcknowledgement = () => {
  const dispatch = useDispatch();
  const { acknowledgements, selectedAcknowledgement } = useDisclosureInfo();
  const selectAcknowledgement = useSelectAcknowledgement();
  return useCallback(() => {
    dispatch(
      acceptAcknowledgement({ acknowledgement: selectedAcknowledgement! })
    );
    const acknowledgement = acknowledgements.find(
      (a) => !a.acknowledged && a.file !== selectedAcknowledgement!.file
    );
    selectAcknowledgement(acknowledgement || selectedAcknowledgement);
  }, [dispatch, selectedAcknowledgement, acknowledgements, selectAcknowledgement]);
};
