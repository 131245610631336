import React, {
  ChangeEventHandler,
  FC,
  useCallback,
} from 'react';
import { Column, DatePicker, Optional, TextField } from '@laurelroad/lrds-react';
import SearchableDropdown from '../../SearchableDropdown/searchableDropdown';
import {
  GOVERNMENT_ID_TYPE_OPTIONS,
  GOVERNMENT_ID_TYPE_TO_LOCATION_SELECT_OPTIONS,
  PASSPORT_ID,
} from './constants/SelectOptions';
import { useGovernmentIdInfo } from './hooks';
import { SelectFieldEvent } from '@laurelroad/lrds-react/dist/src/components/SelectField';
import { useDispatch } from 'react-redux';
import {
  resetFields,
  updateDateField,
  updateSelectField,
  updateTextField,
} from '../../../utils/applicationDispatchFunctions';
import { DatePickerChangeEvent } from '@laurelroad/lrds-react/dist/src/components/DatePicker';
import { governmntIdTypeDependents } from './constants/dependencies';
import { usePersonalInfoErrorMessages } from '../hooks/usePersonalInfoErrorMessages';
import { usePersonalInfo } from '../hooks';
import useUiState from '../../../hooks/useUiState';
import { validatePersonalFields } from '../asyncActions';

const GovernmentId: FC = () => {
  const governmentInfo = useGovernmentIdInfo();

  const { governmentIdType, governmentIdLocation, issueDate, expiryDate, governmentIdNumber } =
    governmentInfo;
  const personalInfo = usePersonalInfo();
  const dispatch = useDispatch();

  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const searchableDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
    },
    [dispatch]
  );

  const datePickerChangeHandler = useCallback(
    (event: DatePickerChangeEvent) => {
      dispatch(updateDateField(event));
    },
    [dispatch]
  );

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => dispatch(updateTextField(event)),
    [dispatch]
  );

  const governmentIdTypeChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      searchableDropdownChangeHandler(event);
      dispatch(resetFields(governmntIdTypeDependents));
    },
    [dispatch, searchableDropdownChangeHandler]
  );

  const { optionalFieldNames } = useUiState();

  const errorGovIdType = usePersonalInfoErrorMessages('governmentIdType');
  const errorIdLocation = usePersonalInfoErrorMessages('governmentIdLocation');
  const errorIssueDate = usePersonalInfoErrorMessages('issueDate');
  const errorExpiryDate = usePersonalInfoErrorMessages('expiryDate');
  const errorIdNumber = usePersonalInfoErrorMessages('governmentIdNumber');

  return (
    <>
      {!optionalFieldNames.includes('governmentIdType') && (
        <Column className={'col--relative'} size={3}>
          <SearchableDropdown
            className="high-yield-savings__form-field"
            id={'governmentIdType'}
            value={governmentIdType|| ''}
            name={'governmentIdType'}
            label={'Government Issued Photo ID Type'}
            items={GOVERNMENT_ID_TYPE_OPTIONS}
            onChange={governmentIdTypeChangeHandler}
            onBlur={blurHandler}
            error={errorGovIdType}
            required={true}
          />
        </Column>
      )}
      {!optionalFieldNames.includes('governmentIdLocation') && (
        <Column className={'col--relative'} size={3}>
          <SearchableDropdown
            className="high-yield-savings__form-field"
            key={governmentIdType}
            id={'governmentIdLocation'}
            label={'ID Location'}
            name={'governmentIdLocation'}
            value={governmentIdLocation || ''}
            items={GOVERNMENT_ID_TYPE_TO_LOCATION_SELECT_OPTIONS[governmentIdType || ''] || []}
            onChange={searchableDropdownChangeHandler}
            onBlur={blurHandler}
            error={errorIdLocation}
            required={true}
            maxVisibleItems={10}
          />
        </Column>
      )}
      {!optionalFieldNames.includes('issueDate') && (
        <Optional renderIf={governmentIdType === PASSPORT_ID}>
          <Column className={'col--relative'} size={3}>
            <DatePicker
              id={'issueDate'}
              name={'issueDate'}
              key={governmentIdType}
              className="high-yield-savings__form-field"
              value={issueDate}
              label={'Issue Date'}
              format={'MM/DD/YYYY'}
              required={true}
              onBlur={blurHandler}
              onChange={datePickerChangeHandler}
              error={errorIssueDate}
            />
          </Column>
        </Optional>
      )}
      {!optionalFieldNames.includes('expiryDate') && (
        <Column className={'col--relative'} size={3}>
          <DatePicker
            name={'expiryDate'}
            key={governmentIdType}
            className="high-yield-savings__form-field"
            value={expiryDate}
            id={'expiryDate'}
            label={'Expiry Date'}
            format={'MM/DD/YYYY'}
            required={true}
            error={errorExpiryDate}
            onBlur={blurHandler}
            onChange={datePickerChangeHandler}
          />
        </Column>
      )}
      {!optionalFieldNames.includes('governmentIdNumber') && (
        <Column className={'col--relative'} size={3}>
          <TextField
            id={'governmentIdNumber'}
            className="high-yield-savings__form-field fs-exclude"
            key={governmentIdType}
            onChange={textFieldChangeHandler}
            name={'governmentIdNumber'}
            label={'ID Number'}
            onBlur={blurHandler}
            error={errorIdNumber}
            required={true}
            data-heap-redact-text="true"
            value={governmentIdNumber}
          />
        </Column>
      )}
    </>
  );
};

export default GovernmentId;
