import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { viewedAcknowledgement } from "../slice";
import { useDisclosureInfo } from "./useDisclosureInfo";

export const useAcknowledgementViewed = () => {
  const dispatch = useDispatch();
  const { selectedAcknowledgement } = useDisclosureInfo();
  useEffect(() => {
    if (selectedAcknowledgement) {
      dispatch(
        viewedAcknowledgement({ acknowledgement: selectedAcknowledgement })
      );
    }
  }, [selectedAcknowledgement, dispatch]);
};
