import {useSelector} from "react-redux";
import {AMTProspectCreationStatus, AMTState} from "../types";
import {AppState} from "../../../store";

export const useAMTInfo = () =>
  useSelector<AppState, AMTState>(({ amtInfo }) => amtInfo);

export const useAMTInfoProspectCreated = () =>
  useSelector<AppState, boolean>(
    ({ amtInfo }) =>
      amtInfo.prospectCreationStatus === AMTProspectCreationStatus.Passed
  );
