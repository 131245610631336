import { ProfileInfo } from "../ProfileCreationPage/types";

export interface AMTProspectCreationProps {
  prospectInfo: ProfileInfo;
}

export interface AMTAuthorizationProps {
  applicationId: number;
}

export enum AMTInitializationStatus {
  Pending,
  Passed,
  Failed,
}

export enum AMTProspectCreationStatus {
  Pending,
  Passed,
  Failed,
  LoginUser,
}

export interface AMTState {
  initializationStatus: AMTInitializationStatus;
  prospectCreationStatus: AMTProspectCreationStatus;
  bundleLoaded: boolean;
}
