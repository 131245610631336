import { invokeCredentialCreation } from "./async";
import React, { useEffect, useState } from "react";
import {
  AMTAuthorizationFailedError,
  AMTCredentialCreationFailedError,
  AMTInitializationError,
} from "./async/invokeCredentialCreation";
import {
  InvalidApplicationTokenError,
  LoginNeededError,
} from "./async/checkApplicationToken";
import AMTInitializationFailed from "./components/AMTInitializationFailed";
import AMTAuthorizationFailed from "./components/AMTAuthorizationFailed";
import AMTCreateCredentialsFailed from "./components/AMTCreateCredentialsFailed";
import InvalidApplicationToken from "./components/InvalidApplicationToken";
import LoginRequired from "./components/LoginRequired";
import { Loader } from "@laurelroad/lrds-react";
import { Redirect } from "react-router-dom";
import { ACCOUNT_CREATION_ROUTE } from "../AccountCreation/constants";
import { useAmtTktLoadedLatch } from "./useAmtTktLoadedLatch";
import { useAllowNavigationToExternalSite } from "../Navigation/hooks/AllowNavigationToExternalSite/useAllowNavigationToExternalSite";

const runCreateCredentials = async (
  setComponent: (result: JSX.Element) => void,
  skipDataReentry = false,
  profileSavedSessionId?: string
) => {
  try {
    const renderAMTContainer = () =>
      setComponent(<div id="transmitContainer" className="mt-4" />);
    console.log("Invoking credential creation");
    await invokeCredentialCreation(
      renderAMTContainer,
      skipDataReentry,
      profileSavedSessionId
    );
    setComponent(<Redirect to={ACCOUNT_CREATION_ROUTE} />);
  } catch (e) {
    if (e instanceof AMTInitializationError) {
      setComponent(<AMTInitializationFailed />);
    } else if (e instanceof AMTAuthorizationFailedError) {
      setComponent(<AMTAuthorizationFailed />);
    } else if (e instanceof AMTCredentialCreationFailedError) {
      setComponent(<AMTCreateCredentialsFailed />);
    } else if (e instanceof LoginNeededError) {
      setComponent(<LoginRequired />);
    } else if (e instanceof InvalidApplicationTokenError) {
      setComponent(<InvalidApplicationToken />);
    }
  }
};

export const useCreateCredentials = (
  skipDataReentry = false,
  profileSavedSessionId?: string
) => {
  useAllowNavigationToExternalSite();

  const [component, setComponent] = useState<JSX.Element>(<Loader />);
  const latch = useAmtTktLoadedLatch();

  useEffect(() => {
    if (latch) {
      runCreateCredentials(
        setComponent,
        skipDataReentry,
        profileSavedSessionId
      );
    }
  }, [skipDataReentry, profileSavedSessionId, latch]);
  return component;
};
