import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import {AccountCreationResponse, AccountCreationState, AccountStatus} from "../../AccountCreation/types";

export const useConfirmationInfo = () =>
  useSelector<AppState, AccountCreationState>(
    ({ accountCreation }) => accountCreation
  );

export const useApprovedAccounts = () =>
    useSelector<AppState, AccountCreationResponse[]>(
        ({ accountCreation: { accountCreation } }) =>
            accountCreation.filter(r => r.accountStatus === AccountStatus.Approved),
        (l, r) => l === r
    );
