import React, { FC, useMemo } from 'react';
import { Column, Grid } from '@laurelroad/lrds-react';
import celebrationIcon from '../../../assets/celebration-icon.svg';
import { useSendConfirmCompleteEvent } from '../../../services/google-tag-manager/useSendConfirmCompleteEvent';
import AccountDetails from './AccountDetails/AccountDetails';
import confirmationPage from '../confirmationPage';
import { useSetCurrentViewConfirmationPage } from '../hooks/useSetCurrentViewConfirmaitonPage';
import { productTextSupplier } from '../../../constants/constants';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';
import { useApprovedAccounts } from '../hooks/useConfirmationInfo';
import { approvedViewBodyFactory } from './approved.viewbody.factory';

export const ApprovedView: FC = () => {
  useSendConfirmCompleteEvent();
  useSetCurrentViewConfirmationPage();

  const approvedAccounts = useApprovedAccounts();
  const title = useMemo(
    () =>
      approvedAccounts
        .map(({ productCode }) => productTextSupplier[productCode].productName)
        .join(' and '),
    [approvedAccounts]
  );

  const ApprovedBody = useMemo(
    () => approvedViewBodyFactory(approvedAccounts),
    [approvedAccounts]
  );

  return (
    <div className={'high-yield-savings'} data-testid="confirmation-approved-view">
      <Grid>
        <Column size={1} />
        <Column className={'high-yield-savings__confirmation'} size={10}>
          <img
            className="high-yield-savings__confirmation__icon"
            src={celebrationIcon}
            alt="celebration-icon"
            data-testid="celebration-icon"
          />
          <div className={'high-yield-savings__confirmation__message'}>
            <span className={'h3 hys-text secondary'}>
              Congratulations! You opened a {title} account.
            </span>
          </div>
          <div className={'high-yield-savings__confirmation__details'}>
            {approvedAccounts.map(account => {
              return (
                <AccountDetails
                  accountNumber={account.accountNumber || ''}
                  routingNumber={account.routingNumber || ''}
                  productCode={account.productCode}
                  key={generateUniqueID()}
                />
              );
            })}
            {ApprovedBody}
          </div>
        </Column>
        <Column size={1} />
      </Grid>
    </div>
  );
};

export default confirmationPage(ApprovedView);
