import { getRequest } from "../../../utils/crud";
import { CHECK_AUTH_APPLICATION_FINISHED_ENDPOINT } from "../constants";
import { ApplicationFinishedResponse } from "./types";

export const checkApplicationFinished = async (): Promise<ApplicationFinishedResponse> => {
  const result = await getRequest<ApplicationFinishedResponse>(
    CHECK_AUTH_APPLICATION_FINISHED_ENDPOINT
  );
  return result;
};
