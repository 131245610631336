import React, { FC, memo } from "react";
import {
  Button,
  Column,
  Grid,
  Icon,
  Paragraph,
  themeColors,
} from "@laurelroad/lrds-react";
import { useSLOSRedirectUrl } from "../../../hooks/useSLOSRedirectUrl";

const TimeoutBundleView: FC = () => {
  const slosRedirectUrl = useSLOSRedirectUrl();

  return (
    <div className="high-yield-savings" data-testid="confirmation-denied-view">
      <Grid>
        <Column size={1} />
        <Column className="high-yield-savings__confirmation" size={10}>
          <Icon
            className="high-yield-savings__confirmation__icon"
            faIconConfig={{ size: "8x" }}
            color={themeColors.pending}
            icon="folder-open"
          />
          <div className="high-yield-savings__confirmation__message">
            <span className={"hys-text h3 secondary"}>That was Unexpected</span>
          </div>
          <div className="high-yield-savings__confirmation__details">
            <Grid>
              <Column size={12}>
                <Paragraph className={"hys-text bold timeout-body"}>
                  If you have any questions, please call Member Services at
                  1-833-427-2265 (TDD/TTY at 1-800-539-8336).
                </Paragraph>
              </Column>
              <Column
                className={
                  "high-yield-savings__form-submit-container high-yield-savings__form-submit-container--confirmation"
                }
                size={12}
              >
                <Button
                  onClick={() => (window.location.href = slosRedirectUrl!)}
                >
                  Return to Student Loan Refinance
                </Button>
              </Column>
            </Grid>
          </div>
        </Column>
        <Column size={1} />
      </Grid>
    </div>
  );
};

export default memo(TimeoutBundleView);
