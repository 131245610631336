import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AMTInitializationStatus,
  AMTProspectCreationStatus,
  AMTState,
} from "./types";

export const initialState: AMTState = {
  initializationStatus: AMTInitializationStatus.Pending,
  prospectCreationStatus: AMTProspectCreationStatus.Pending,
  bundleLoaded: false,
};

const updateStatusField =
  (fieldName: keyof AMTState) =>
  (state: AMTState, action: PayloadAction<number>) => ({
    ...state,
    [fieldName]: action.payload,
  });

const amtInfoSlice = createSlice({
  name: "amtInfo",
  initialState,
  reducers: {
    updateInitializationStatus: updateStatusField("initializationStatus"),
    updateProspectCreationStatus: updateStatusField("prospectCreationStatus"),
    toggleAmtBundleLoaded: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      bundleLoaded: payload,
    }),
  },
});

export const { updateInitializationStatus, updateProspectCreationStatus, toggleAmtBundleLoaded } =
  amtInfoSlice.actions;
export const { reducer: AMTInfoReducer } = amtInfoSlice;
