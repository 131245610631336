import { BundleState, replenishStatus } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postMultiproductInfo } from "../ProfileCreationPage/asyncActions";
import { ApplicationCreatedStatus } from "../../types/ApplicationCreatedStatus";
import { fetchMultiproductProductCodes } from "../AccountCreation/asyncActions";

export const initialState: BundleState = {
  productCodes: [],
  createdApplications: [],
  createdApplicationsStatus: ApplicationCreatedStatus.Pending,
  showModal: true,
  showTooltip: false,
  replenishBundleStatus: "idle",
};

const bundleSlice = createSlice({
  name: "bundleSlice",
  initialState,
  reducers: {
    hideBundleModal: (state) => {
      return {
        ...state,
        showModal: false,
        showTooltip: true,
      };
    },
    hideBundleToolTip: (state) => {
      return {
        ...state,
        showTooltip: false,
      };
    },
    optInHandler: (state: BundleState, action: PayloadAction<string>) => {
      return {
        ...state,
        productCodes: [...state.productCodes, action.payload],
      };
    },
    optOutHandler: (state: BundleState, action: PayloadAction<string>) => {
      const updatedProductCodes: string[] = state.productCodes.filter(
        (productCodes) => productCodes !== action.payload
      );
      return {
        ...state,
        productCodes: updatedProductCodes,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMultiproductInfo.fulfilled, (state: BundleState, {payload}) => {
        return {
          ...state,
          createdApplicationsStatus: ApplicationCreatedStatus.Complete,
          createdApplications: payload,
        };
      })
      .addCase(fetchMultiproductProductCodes.rejected, (state: BundleState) => {
        return {
          ...state,
          replenishBundleStatus: "idle",
        };
      })
      .addCase(fetchMultiproductProductCodes.pending, (state: BundleState) => {
        return {
          ...state,
          replenishBundleStatus: "running",
        };
      })
      .addCase(
        fetchMultiproductProductCodes.fulfilled,
        (state: BundleState, action) => {
          let replenish = "complete" as replenishStatus;
          let productCodes = action.payload.map(
            ({ productCode }) => productCode
          );
          //for bundle apps we already havee productCodes. we wait for DB to update properly before continuing
          // in return users isntance the db has enough time due to account creation, iin those cases bundle product codes
          // will be 0, which we aren't worried about
          if (state.productCodes.length > 0 && action.payload.length === 0) {
            productCodes = state.productCodes;
            replenish = "idle" as replenishStatus;
          }
          return {
            ...state,
            createdApplications: action.payload,
            productCodes: productCodes,
            replenishBundleStatus: replenish,
          };
        }
      );
  },
});

export const { reducer: BundleReducer } = bundleSlice;
export const {
  hideBundleModal,
  optInHandler,
  optOutHandler,
  hideBundleToolTip,
} = bundleSlice.actions;
