import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '../../store';
import { getJarvisEligibilityInfo } from '../ProfileCreationPage/asyncActions';
import { AMTProspectCreationStatus } from './types';
import { updateProspectCreationStatus } from './slice';

export const onProspectCreation = createAsyncThunk<
  void,
  AMTProspectCreationStatus,
  { state: AppState; dispatch: AppDispatch }
>('amtInfo/onProspectCreation', (receivedAmtStatus, { dispatch, getState }) => {
  if (
    getState().application.productCode === 'LRLLCH' &&
    [AMTProspectCreationStatus.LoginUser, AMTProspectCreationStatus.Passed].includes(
      receivedAmtStatus
    )
  ) {
    dispatch(getJarvisEligibilityInfo());
  }
  dispatch(updateProspectCreationStatus(receivedAmtStatus));
});
