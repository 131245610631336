import React, { FC } from "react";
import { Loader } from "@laurelroad/lrds-react";

const FinishApplicationLoader: FC = () => {
  return (
    <div className={"finish-application__modal"}>
      <Loader />
      <h6>Please do not close this window while we run some final checks...</h6>
    </div>
  );
};

export default FinishApplicationLoader;
