import React, { FC } from "react";
import { Button } from "@laurelroad/lrds-react";
import { redirectToLaurelRoad } from "../utils/redirectToLaurelRoad";

const AMTCreateCredentialsFailed: FC = () => {
  return (
    <div className={"create-credentials__modal"}>
      <h4>Your request can't be completed</h4>
      <h6>
        There was an error creating credentials for your account. Please refresh
        to try again.
      </h6>
      <h6>
        If you are experiencing an issue after trying again, please call
        1-833-427-2265 if you need assistance. For members using a TDD/TTY
        device, please call 1-800-539-8336.
      </h6>
      <Button onClick={redirectToLaurelRoad}>OK</Button>
    </div>
  );
};

export default AMTCreateCredentialsFailed;
