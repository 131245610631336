export const DOCTORATE_DEGREES = [
  "Juris Doctor",
  "Doctor of Audiology",
  "Doctor of Chiropractic",
  "Doctor of Dental Studies",
  "Doctor of Medicine",
  "Doctor of Osteopathic Medicine",
  "Doctor of Naturopathic Medicine",
  "Doctor of Nursing",
  "Doctor of Optometry",
  "Doctor of Pharmacy",
  "Doctor of Physical Therapy",
  "Doctor of Podiatry",
  "Doctor of Veterinary Medicine",
  "PhD in Business (non MBA)",
  "PhD in Education",
  "PhD in Engineering",
  "PhD in Mathematics",
  "PhD in Science",
  "PhD in Social Science",
];
