import { Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const MPFreedomCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’re one step closer to stress-free student loan repayment. Plus, with our High Yield
        Savings account, you can fast track your savings goals at the same time!
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Next, you’ll need to fund your new accounts. See our{' '}
        <a href="https://www.laurelroad.com/checking-switch-guide-download/">Deposit Guide</a>{' '}
        for some helpful tips on setting up direct deposit, making your first transfer, and
        other account features.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You’ll receive an email shortly with information on how to access Online Banking with
        the User Name and ID you just created. Keep in mind that it can take up to 24 hours for
        your new checking account to appear.
      </Paragraph>
    </>
  );
};

export default memo(MPFreedomCheckingInstructions);
