import { Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const BundleCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’ll receive an email confirming your account opening shortly. You've also been
        automatically enrolled in our online banking system, which you can access with your
        User ID and password.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Your next step with your new Linked Checking account is to set up direct deposit. To do
        so, sign in to online banking from the link within your account confirmation email, or
        from laurelroad.com. (FYI, it can take up to 24 hours for your account to appear in
        online banking.)
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Right now, we'll return you to your student loan refinance application, so you can
        close out your loan.
      </Paragraph>
    </>
  );
};

export default memo(BundleCheckingInstructions);
