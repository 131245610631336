import { createAsyncThunk } from '@reduxjs/toolkit';
import { executePrefillData, getPrefillData } from '../components/App/asyncActions';
import { updateProspectCreationStatus } from '../components/AMT/slice';
import { AMTProspectCreationStatus } from '../components/AMT/types';
import {
  setAuthenticated,
  setLoading,
  setOptionalFieldNames,
  setProductText,
} from './state/uiSlice';
import { AppDispatch, AppState } from './index';
import { onAppBoot } from '../init';

export const onAppLoadAction = createAsyncThunk<
  void,
  void,
  { state: AppState; dispatch: AppDispatch }
>('global/app-boot', async (p, { dispatch, getState }) => {
  dispatch(setLoading(true));

  const optionalFields = await onAppBoot();
  dispatch(setOptionalFieldNames(optionalFields));
  executePrefillData()
    .then(({ authenticated, showLoginModal, obpPartyId, ...otherFields }) => {
      if (getState().appView.currentView === 'Profile Creation Page') {
        dispatch({ type: getPrefillData.fulfilled.type, payload: otherFields });
        dispatch(
          updateProspectCreationStatus(
            authenticated
              ? AMTProspectCreationStatus.Passed
              : AMTProspectCreationStatus.Pending
          )
        );
      }
      dispatch(setProductText(otherFields.productCode));
      dispatch(setAuthenticated(authenticated));
      dispatch(setLoading(false));
    })
    .catch(() => dispatch(setLoading(false)));
});
