import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { submitDisclosures } from "../asyncActions";
import { useDisclosureInfo } from "./useDisclosureInfo";

export const useSubmitDisclosures = () => {
  const dispatch = useDispatch();
  const { overDraftProtection } = useDisclosureInfo();
  return useCallback(async () => {
    dispatch(submitDisclosures({ overDraftProtection }));
  }, [dispatch, overDraftProtection]);
};
