import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../../../store";
import { PersonalInfo } from "../types";
import { convertApplicationToPersonalInfo } from "../utils";

export const usePersonalInfo = () =>
  useSelector<AppState, PersonalInfo>(
    ({ application }) =>convertApplicationToPersonalInfo(application),
    (left, right) => JSON.stringify(left) === JSON.stringify(right)
  );
