import React, { ChangeEventHandler, FC, FocusEventHandler, useCallback } from 'react';
import { Column, TextField } from '@laurelroad/lrds-react';
import { useProspectName } from './hooks';
import { useDispatch } from 'react-redux';
import { updateTextFieldValueWithUppercaseFirstLetter } from '../../../utils/applicationDispatchFunctions';
import { SUFFIX_SELECT_OPTIONS } from './constants/SuffixDropdownOptions';
import Prefix from '../Prefix/Prefix';
import Select from 'react-select';
import { updateApplication } from '../../../store/state/applicationSlice';
import { LrdsSelectStyles } from './constants/LrdsSelectStyles';
import { useProfileInfoErrorMessages } from '../hooks/useProfileInfoErrorMessages';
import { validateProfileFields } from '../slice';
import { useProfileInfo } from '../hooks';
import useUiState from '../../../hooks/useUiState';

const ProspectName: FC = () => {
  const dispatch = useDispatch();
  const { firstName, middleName, lastName, suffix } = useProspectName();
  const profileInfo = useProfileInfo();

  const blurHandler: FocusEventHandler<HTMLInputElement> = event => {
    dispatch(validateProfileFields(profileInfo));
  };

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      dispatch(updateTextFieldValueWithUppercaseFirstLetter(event));
    },
    [dispatch]
  );

  const selectFieldChangeHandler = useCallback(
    option => dispatch(updateApplication({ key: 'suffix', value: option?.value || '' })),
    [dispatch]
  );

  const { optionalFieldNames } = useUiState();
  return (
    <>
      {!optionalFieldNames.includes('prefix') && (
        <Column size={2}>
          <Prefix />
        </Column>
      )}
      <Column size={3}>
        <TextField
          id={'firstName'}
          name={'firstName'}
          className="high-yield-savings__form-field"
          value={firstName}
          onChange={textFieldChangeHandler}
          label={'First Name'}
          required={true}
          onBlur={blurHandler}
          error={useProfileInfoErrorMessages('firstName')}
        />
      </Column>
      <Column size={2}>
        <TextField
          name={'middleName'}
          className="high-yield-savings__form-field"
          value={middleName}
          onChange={textFieldChangeHandler}
          label={'Middle Name'}
          onBlur={blurHandler}
          required={false}
          error={useProfileInfoErrorMessages('middleName')}
        />
      </Column>
      <Column size={3}>
        <TextField
          id={'lastName'}
          name={'lastName'}
          className="high-yield-savings__form-field"
          value={lastName}
          onChange={textFieldChangeHandler}
          label={'Last Name'}
          onBlur={blurHandler}
          error={useProfileInfoErrorMessages('lastName')}
          required={true}
        />
      </Column>
      {!optionalFieldNames.includes('suffix') && (
        <Column size={2}>
          <Select
            className={'high-yield-savings__select-dropdown'}
            id={'suffix'}
            name={'suffix'}
            classNamePrefix={'suffix'}
            selected={SUFFIX_SELECT_OPTIONS.filter(option => option.value === suffix)}
            placeholder={'Suffix'}
            options={SUFFIX_SELECT_OPTIONS}
            onChange={selectFieldChangeHandler}
            isClearable
            isSearchable
            styles={LrdsSelectStyles}
          />
        </Column>
      )}
    </>
  );
};

export default ProspectName;
