export const PRIMARY_INCOME_SOURCES: { [sourceName: string]: string } = {
  Employment: "SOI001",
  "Trust Beneficiary": "SOI002",
  Investments: "SOI003",
  "Social Security/Pension": "SOI004",
  "Disability" : "SOI005",
  "Public Assistance": "SOI006",
  "Real Estate" : "SOI007",
  "Inheritance": "SOI008",
  "No Income": "SOI009",
  Other: "SOI010",
};
