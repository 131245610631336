import React, { FC, memo } from 'react';
import { Divider, Optional } from '@laurelroad/lrds-react';
import { ConfirmationViewProps } from '../../types';
import { productTextSupplier } from '../../../../constants/constants';

const AccountDetails: FC<
  Required<Pick<ConfirmationViewProps, 'accountNumber' | 'routingNumber' | 'productCode'>>
> = ({ accountNumber, routingNumber, productCode }) => {
  let header = productCode ? productTextSupplier[productCode].accountDetailsHeaderTitle : '';

  return (
    <>
      <Optional renderIf={productCode}>
        <h6>{header}</h6>
      </Optional>
      <div className={'high-yield-savings__confirmation__account-details'}>
        <div className={'account-detail'}>
          <div className={'account-detail__name'}>
            <span className="hys-text secondary bold">Account Number</span>
          </div>
          <div className={'fs-exclude'}>
            <a>{accountNumber}</a>
          </div>
        </div>
        <div className={'account-detail'}>
          <div className={'account-detail__name'}>
            <span className={'hys-text secondary bold'}>Routing Number</span>
          </div>
          <div className={'fs-exclude'}>
            <a>{routingNumber}</a>
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default memo(AccountDetails);
