import { IssueDateSchema } from "./IssueDate";
import { ExpiryDateSchema } from "./ExpiryDate";
import { GovernmentIdLocationSchema } from "./GovernmentIdLocation";
import { GovernmentIdNumberSchema } from "./GovernmentIdNumber";
import { GovernmentIdTypeSchema } from "./GovernmentIdType";
import Joi from "joi";
import { GovernmentIdState } from "../../types";

const GovernmentIdSchema = Joi.object<GovernmentIdState>({
  governmentIdType: Joi.any().when('$optionalFieldNames', {
    is: Joi.array().items(Joi.string().valid('governmentIdType').required(), Joi.any().optional()).exist(),
    otherwise: GovernmentIdTypeSchema,
  }),
  governmentIdLocation: Joi.any().when('$optionalFieldNames', {
    is: Joi.array().items(Joi.string().valid('governmentIdLocation').required(), Joi.any().optional()).exist(),
    otherwise: GovernmentIdLocationSchema,
  }),
  governmentIdNumber: Joi.any().when('$optionalFieldNames', {
    is: Joi.array().items(Joi.string().valid('governmentIdNumber').required(), Joi.any().optional()).exist(),
    otherwise: GovernmentIdNumberSchema.required(),
  }),
  expiryDate: Joi.any().when('$optionalFieldNames', {
    is: Joi.array().items(Joi.string().valid('expiryDate').required(), Joi.any().optional()).exist(),
    otherwise: ExpiryDateSchema.required(),
  }),
  issueDate: Joi.any().when('$optionalFieldNames', {
    is: Joi.array().items(Joi.string().valid('issueDate').required(), Joi.any().optional()).exist(),
    otherwise: IssueDateSchema,
  }),
});

export { GovernmentIdSchema };
