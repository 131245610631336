import Joi from "joi";

export const ZipCodeSchema = Joi.string()
  .min(5)
  .pattern(/^[0-9]{5}(?:-[0-9]{4})?$/, "zip code validation")
  .messages({
    "any.required" : "Zip code is required",
    "string.empty": "Zip code is required",
    "string.min":
      "Your ZIP code must match a standard U.S. postal code format. Examples: 12345 or 12345-1234",
    "string.pattern.base":
      "Your ZIP code must match a standard U.S. postal code format. Examples: 12345 or 12345-1234",
    "string.pattern.name":
      "Your ZIP code must match a standard U.S. postal code format. Examples: 12345 or 12345-1234",
  })
  .required();
