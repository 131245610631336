import Joi from "joi";
import { GovernmentIdSchema } from "./GovernmentId/constants/schemas";
import { EducationSchema } from "./Education/constants/schemas";
import { IssueDateSchema } from "./GovernmentId/constants/schemas/IssueDate";
import { PASSPORT_ID } from "./GovernmentId/constants/SelectOptions";
import { EmployerNameSchema } from "./Occupation/constants/schemas/EmployerName";
import {
  EmploymentStatusSchema,
  NoEmploymentStatusSchema,
} from "./Occupation/constants/schemas/EmploymentStatus";
import { OccupationClassificationSchema } from "./Occupation/constants/schemas/OccupationClassification";
import { IncomeSchema } from "./Income/constants/schemas";
import { CitizenshipSchema } from "./Citizenship/constants/schemas";
import { ForeignGovernmentDisclosureSchema } from "./ForeignGovernmentDisclosure/constants/schemas";
import { AccountPurposeSchema } from "./AccountPurpose/constants/schemas";
import { ExplanationSchema } from "./Income/constants/schemas/IncomeExplanation";
import { EmployerIndustrySchema } from "./Occupation/constants/schemas/EmployerIndustrySchema";
import { postRequest } from "../../utils/crud";

export const PERSONAL_INFO_ENDPOINT = "/applications/personal-info";
const PEPPR_REFERRAL_CODE_ENDPOINT = "jarvis/pas/validation/referralOrg"
/*
  Overriding occupation schema for personal info requirements
 */
export const personalInfoOccupationSchema = Joi.object({
  employmentStatus: EmploymentStatusSchema,
  employerName: Joi.any().when("employmentStatus", {
    is: NoEmploymentStatusSchema,
    then: Joi.optional(),
    otherwise: EmployerNameSchema,
  }),
  employerIndustry: EmployerIndustrySchema,
  occupationClassification: Joi.any().when("employmentStatus", {
    is: NoEmploymentStatusSchema,
    then: Joi.optional(),
    otherwise: OccupationClassificationSchema,
  }),
}).unknown();

const personalInfoGovernmentIdSchema = GovernmentIdSchema.append({
  issueDate: Joi.when("governmentIdType", {
    is: PASSPORT_ID,
    then: IssueDateSchema.required(),
    otherwise: Joi.optional(),
  }),
});



export const ReferralCodeSchema = Joi.object({
  employerReferralCode: Joi.string().allow('').external(async (value, helpers) => {
    if (!value) {
      return true; //empty values are valid
    }
    const resp = await postRequest(PEPPR_REFERRAL_CODE_ENDPOINT, {
      referralCode: value,
    }).catch((e) => {
      console.debug(e);
      return false;
    });
    if (!resp) {
      throw new Error('We don’t recognize that referral code');
    }
    return resp;
  }).messages({ "any.invalid": "We don’t recognize that referral code" })
});

export const PersonalInfoSchema = Joi.object({
  foreignGovernmentDisclosure: ForeignGovernmentDisclosureSchema,
  slosRedirectUrl: Joi.optional(),
  loginUser: Joi.optional(),
})
  .concat(personalInfoOccupationSchema)
  .concat(EducationSchema)
  .concat(IncomeSchema)
  .concat(CitizenshipSchema)
  .concat(personalInfoGovernmentIdSchema)
  .concat(AccountPurposeSchema)
  .concat(ExplanationSchema)
  .options({ abortEarly: false });
