import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { MultiSelectOption } from "../../MultiSelect/types";
import {
  ExpectedSourceOfOngoingFundingId,
  ExpectedSourceOfOngoingFundingName,
} from "./types";

export const useSourcesOfOngoingAccountFunding = () =>
  useSelector<
    AppState,
    Array<
      MultiSelectOption<
        ExpectedSourceOfOngoingFundingId,
        ExpectedSourceOfOngoingFundingName
      >
    >
  >(({ application }) => application.expectedSourcesOfOngoingAccountFunding);
