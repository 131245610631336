/**
 * Performs POST request via Fetch with the supplied parameters
 * @param url - url to send request to
 * @param data - data of type T to be sent with the request
 * @return {Promise<E>} - Promise which resolves to type E
 */
import { SetError } from "../../components/Network/slice";
import {BAD_REQUEST, jsonHeaders} from "./constants";
import store from "../../store";
import { APIResponse } from "../../components/Network/types";

const postRequest = async <T, E>(url: string, data?: T): Promise<E> => {
  const requestResult: APIResponse = await fetch(url, {
    method: "POST",
    headers: jsonHeaders(),
    body: JSON.stringify(data),
  });


  const result = await requestResult.json();

  if (requestResult.status >= BAD_REQUEST) {
    const error = {
      status: requestResult.status,
      message: `POST request to ${url} failed`,
      fs_ref_number: result.fs_ref_number,
    };

    store.dispatch(SetError(error));

    return Promise.reject(error);
  }

  return result;
};

export { postRequest };