import { Button, Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const MPFreedomCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Accounts
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Please bear with us while we finish setting up your account. You’ll receive an email
        confirming your account opening in a few hours. Then, you’ll be able to log into our
        Online Banking system with the User ID and password you created.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You’re one step closer to stress-free student loan repayment. Plus, with our High Yield
        Savings account, you can fast track your savings goals at the same time!
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Once your accounts are up and running, you’ll:
        <ul style={{ marginInlineStart: '2em' }}>
          <li>
            <a href="https://www.laurelroad.com/direct-deposit/">Set up direct deposit</a> to
            your Freedom Plus Checking account so you can begin earning rewards, and
          </li>
          <li>
            <a href="https://www.laurelroad.com/faq-category/high-yield-savings/">
              Fund your High Yield Savings
            </a>{' '}
            account by bank transfer, wire, digital check, or direct deposit.
          </li>
        </ul>
        <br />
        <Button href={window.gradFinUrl}>Back to Membership Portal</Button>
      </Paragraph>
    </>
  );
};

export default memo(MPFreedomCheckingInstructions);
