import Joi from "joi";
import { InputFieldError } from "../components/PersonalInfoPage/types";

export const convertJoiValidationToInputErrors= (joiValidationResult: Joi.ValidationResult<any>) => {
  if (joiValidationResult.error) {
    return joiValidationResult.error.details.map(function (detail) {
      return {
        key: detail.path,
        message: detail.message,
      } as InputFieldError;
    });
  }
  return [];
}
export const sortInputFieldErrors = ( errors: InputFieldError[], order: (string | number)[]) => {
  return errors
  .filter((error) => order.includes(error.key[0]))
  .sort(function (a, b) {
    return order.indexOf(a.key[0]) - order.indexOf(b.key[0]);
  });
}
export const convertJoiToSortedErrors = (
  joiResp: Joi.ValidationResult<any>,
  order: (string | number)[]
) => {
  return sortInputFieldErrors(
    convertJoiValidationToInputErrors(joiResp), 
    order);
};
