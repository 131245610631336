import { FORBIDDEN, SERVICE_UNAVAILABLE, UNAUTHORIZED, UNPROCESSABLE_ENTITY } from "../../../../utils/crud/constants";
import { postRequest } from "../../../../utils/crud/postRequest";
import { OBS_ENDPOINT } from "../../constants";
import { ObsResponse } from "../../types";

export type OBSCallExceptionStatus =
  | typeof SERVICE_UNAVAILABLE
  | typeof FORBIDDEN
  | typeof UNPROCESSABLE_ENTITY
  | typeof UNAUTHORIZED;

export interface OBSCallException {
  status: OBSCallExceptionStatus;
}

export class OBSRunError extends Error {}
export class OBSTimeoutError extends Error {}
export class OBSNotRunError extends Error {}
export class OBSApplicationNotCompleteError extends Error {}

export const runOBS = async () => {
  try {
    return await postRequest<null, ObsResponse>(OBS_ENDPOINT);
  } catch (e: any) {
    const error: OBSCallException = e;
    const { status: errorStatusCode } = error;
    if (errorStatusCode === UNAUTHORIZED) {
      throw new OBSNotRunError();
    } else if (errorStatusCode === SERVICE_UNAVAILABLE) {
      throw new OBSTimeoutError();
    } else if (errorStatusCode === FORBIDDEN) {
      throw new OBSApplicationNotCompleteError();
    }
    throw new OBSRunError();
  }
};