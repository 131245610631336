import React, { ChangeEventHandler, FC, useCallback, useEffect } from 'react';
import {
  Column,
  Icon,
  Optional,
  RadioGroup,
  Tooltip,
  themeColors,
} from '@laurelroad/lrds-react';
import MultiSelect from '../../MultiSelect/MultiSelect';
import { useDispatch } from 'react-redux';
import {
  resetFields,
  updateCitizenshipCountries,
  updateRadioField,
  updateResidencyCountries,
} from '../../../utils/applicationDispatchFunctions';
import { useCitizenshipInfo } from './hooks';
import {
  CITIZENSHIP_OPTIONS,
  BUNDLED_OAO_CITIZENSHIP_OPTIONS,
  COUNTRY_OPTIONS,
} from './constants/SelectOptions';
import { useIsBundled } from '../../../hooks/useIsBundled';
import { usePersonalInfoErrorMessages } from '../hooks/usePersonalInfoErrorMessages';
import { usePersonalInfo } from '../hooks';
import { validatePersonalFields } from '../asyncActions';
import './styles.scss'

const Citizenship: FC = () => {
  const dispatch = useDispatch();
  const isBundled = useIsBundled();
  const { citizenshipStatus, citizenshipCountries, residencyCountries } = useCitizenshipInfo();
  const personalInfo = usePersonalInfo();

  const citizenshipCountriesChangeHandler = useCallback(
    selectedCountryOptions => dispatch(updateCitizenshipCountries(selectedCountryOptions)),
    [dispatch]
  );

  const residencyCountriesChangeHandler = useCallback(
    selectedCountryOptions => dispatch(updateResidencyCountries(selectedCountryOptions)),
    [dispatch]
  );

  const radioGroupChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.target.value === 'US') {
        dispatch(resetFields(['citizenshipCountries', 'residencyCountries']));
      } else if (event.target.value === 'Permanent Resident') {
        dispatch(resetFields(['residencyCountries']));
      }
      dispatch(updateRadioField(event));
    },
    [dispatch]
  );

  const countryOfResidenceToolTipContent = (
    <>
      <p>
        Permanent residency is the country in which you have established a residence and plan
        to permanently remain aside from temporary travel. Please select U.S. if you are a
        Refugee seeking Asylum or if you are an International Student and plan to remain/live
        in the U.S. permanently or until graduation.
      </p>
    </>
  );

  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  return (
    <>
      <Column size={5} className="personal-info-creation__disclosure-question">
        What is your U.S. citizenship status?
        <span
          className="personal-info-creation__required-asterisk"
          style={{ color: themeColors.error }}>
          *
        </span>
      </Column>
      <Column size={5}>
        <span onBlur={blurHandler}>
          <RadioGroup
            id={'citizenshipStatus'}
            onChange={radioGroupChangeHandler}
            name={'citizenshipStatus'}
            options={isBundled ? BUNDLED_OAO_CITIZENSHIP_OPTIONS : CITIZENSHIP_OPTIONS}
            error={usePersonalInfoErrorMessages('citizenshipStatus')}
          />
        </span>
      </Column>
      <Optional renderIf={citizenshipStatus.length > 0 && citizenshipStatus !== 'US'}>
        <Column size={3}>Country of Citizenship</Column>
        <Column size={9}>
          <MultiSelect
            id={'citizenshipCountries'}
            selected={citizenshipCountries}
            placeholder="Country of Citizenship"
            onChange={citizenshipCountriesChangeHandler}
            options={COUNTRY_OPTIONS}
            required={true}
            error={usePersonalInfoErrorMessages('citizenshipCountries')}
          />
        </Column>
      </Optional>
      <Optional renderIf={citizenshipStatus === 'Non-Resident'}>
        <Column size={3}>
          Country of Residence{' '}
          <Tooltip content={countryOfResidenceToolTipContent}>
            <Icon icon="info-circle" />
          </Tooltip>
        </Column>

        <Column size={9}>
          <MultiSelect
            id={'citizenshipResidences'}
            selected={residencyCountries}
            placeholder="Country of Residence"
            onChange={residencyCountriesChangeHandler}
            options={COUNTRY_OPTIONS}
            required={true}
            error={usePersonalInfoErrorMessages('citizenshipResidences')}
          />
        </Column>
      </Optional>
    </>
  );
};

export default Citizenship;
