import React, { FC } from 'react';
import { Button, Paragraph } from '@laurelroad/lrds-react';

const FreedomCheckingAccountNotCreated: FC = () => {
  return (
    <>
      {' '}
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Account
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Please bear with us while we finish setting up your account. You’ll receive an email
        confirming your account opening in a few hours. Then, you’ll be able to log into our
        Online Banking system with the User ID and password you created.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Next, you’ll need to fund your new account. For some tips on setting up direct deposit
        and other account features – check out this handy{' '}
        <a href="https://www.laurelroad.com/checking-switch-guide-download/">guide</a>.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Once you start paying your student loans from this account, you'll be able to track
        your progress towards forgiveness in Laurel Road Online Banking.
      </Paragraph>
      <Paragraph>
        <Button href={window.gradFinUrl}>Back to Membership Portal</Button>
      </Paragraph>
    </>
  );
};
export default FreedomCheckingAccountNotCreated;
