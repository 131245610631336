import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { OccupationState } from "./types";

export const useOccupationInfo = () => {
  return useSelector<AppState, OccupationState>(({ application }) => {
    const {
      employmentStatus,
      employerName,
      occupationClassification,
      employerIndustry,
      employerReferralCode,
    } = application;
    return {
      employmentStatus,
      employerName,
      occupationClassification,
      employerIndustry,
      employerReferralCode
    };
  });
};
