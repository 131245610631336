import './styles.scss';
import { Button, DialogModal } from '@laurelroad/lrds-react';
import React, { useCallback, useMemo } from 'react';
import AccountPurpose from '../../../PersonalInfoPage/AccountPurpose/AccountPurpose';
import ForeignGovernmentDisclosure from '../../../PersonalInfoPage/ForeignGovernmentDisclosure/ForeignGovernmentDisclosure';
import Income from '../../../PersonalInfoPage/Income/Income';
import Occupation from '../../../PersonalInfoPage/Occupation/Occupation';
import SourcesOfOngoingAccountFunding from '../../../PersonalInfoPage/SourcesOfOngoingAccountFunding/SourcesOfOngoingAccountFunding';
import { putRequest } from '../../../../utils/crud';
import { PersonalInfo } from '../../../PersonalInfoPage/types';
import { ApplicationUpdatedResponse } from '../../../../types/ApplicationUpdatedResponse';
import { usePersonalInfo } from '../../../PersonalInfoPage/hooks';
import Joi from 'joi';
import { AccountPurposeSchema } from '../../../PersonalInfoPage/AccountPurpose/constants/schemas';
import { IncomeSchema } from '../../../PersonalInfoPage/Income/constants/schemas';
import { personalInfoOccupationSchema } from '../../../PersonalInfoPage/constants';
import { ForeignGovernmentDisclosureSchema } from '../../../PersonalInfoPage/ForeignGovernmentDisclosure/constants/schemas';

/**
 *
 *  This is temp code to handle in flight PENDING apps. Should be removed by end of August
 */

export const RsqPopUp = (props: any) => {
  const { onRsqSaved = () => {} } = props;
  const submitRsqData = useSubmitRsqData();
  const rsqDataValidated = useRsqDataValidated();

  return (
    <div className="rsq-modal-container">
      <DialogModal
        isOpen={true}
        className={'rsq-modal'}
        title="We need some more information before we process your account.">
        <Income />
        <Occupation />
        <AccountPurpose />
        <SourcesOfOngoingAccountFunding />
        <ForeignGovernmentDisclosure />
        <Button
          isDisabled={!rsqDataValidated}
          onClick={() =>
            submitRsqData()
              .then((applicationUpdatedResponse: ApplicationUpdatedResponse) => {
                if (!applicationUpdatedResponse) {
                  Promise.reject();
                }
              })
              .then(onRsqSaved)
          }>
          Submit
        </Button>
      </DialogModal>
    </div>
  );
};

const useSubmitRsqData = () => {
  const {
    primaryIncomeSource,
    employmentStatus,
    employerIndustry,
    employerName,
    occupationClassification,
    otherOrNoIncomeExplanation,
    accountPurpose,
    expectedSourcesOfOngoingAccountFunding,
    foreignGovernmentDisclosure,
  } = usePersonalInfo();
  return useCallback(async () => {
    return await putRequest<Partial<PersonalInfo>, ApplicationUpdatedResponse>(
      '/applications/rsq',
      {
        primaryIncomeSource,
        employmentStatus,
        employerIndustry,
        employerName,
        occupationClassification,
        otherOrNoIncomeExplanation,
        accountPurpose,
        expectedSourcesOfOngoingAccountFunding,
        foreignGovernmentDisclosure,
      }
    );
  }, [
    primaryIncomeSource,
    employmentStatus,
    employerIndustry,
    employerName,
    occupationClassification,
    otherOrNoIncomeExplanation,
    accountPurpose,
    expectedSourcesOfOngoingAccountFunding,
    foreignGovernmentDisclosure,
  ]);
};
const useRsqDataValidated = () => {
  const {
    primaryIncomeSource,
    employmentStatus,
    employerIndustry,
    employerName,
    occupationClassification,
    otherOrNoIncomeExplanation,
    accountPurpose,
    expectedSourcesOfOngoingAccountFunding,
    foreignGovernmentDisclosure,
  } = usePersonalInfo();
  return useMemo(() => {
    const result = RsqDataScheme.validate({
      primaryIncomeSource,
      employmentStatus,
      employerIndustry,
      employerName,
      occupationClassification,
      otherOrNoIncomeExplanation,
      accountPurpose,
      expectedSourcesOfOngoingAccountFunding,
      foreignGovernmentDisclosure,
    });
    return !result.error;
  }, [
    primaryIncomeSource,
    employmentStatus,
    employerIndustry,
    employerName,
    occupationClassification,
    otherOrNoIncomeExplanation,
    accountPurpose,
    expectedSourcesOfOngoingAccountFunding,
    foreignGovernmentDisclosure,
  ]);
};

const RsqDataScheme = Joi.object({
  foreignGovernmentDisclosure: ForeignGovernmentDisclosureSchema,
})
  .concat(personalInfoOccupationSchema)
  .concat(IncomeSchema)
  .concat(AccountPurposeSchema);
