import { runBundleAml } from "../Aml/runBundleAml";
import { runBundleOBS } from "../Obs/runBundleOBS";
import store from "../../../../store";
import { updateAmlFailures } from "../../../ConfirmationPage/BundleView/slice";

export const runBundleAccountCreation = async () => {
  const amlResults = await runBundleAml();

  amlResults.forEach((result) => {
    if (!result.amlPassed) {
      store.dispatch(updateAmlFailures(result.productCode));
    }
  });

  const obsResults = await runBundleOBS();

  return obsResults;
};
