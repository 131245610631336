import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../utils/crud";
import { PREFILL_ENDPOINT } from "./constants";
import { PrefilledApplication } from "./types";

export const getPrefillDataActionPrefix = "app/getPrefillData";

export const executePrefillData = async () =>
  await getRequest<PrefilledApplication>(PREFILL_ENDPOINT);

export const getPrefillData = createAsyncThunk(
  getPrefillDataActionPrefix,
  executePrefillData
);
