import Joi from "joi";
import {
  EMPLOYMENT_STATUS_OPTIONS,
  mapSelectOptionToId,
} from "../SelectOptions";
import { NON_EMPLOYED_EMPLOYMENT_STATUSES } from "../EmployeeStatuses";

const VALID_EMPLOYMENT_STATUS_ID_OPTIONS =
  EMPLOYMENT_STATUS_OPTIONS.map(mapSelectOptionToId);

export const NoEmploymentStatusSchema = Joi.string().valid(
  ...NON_EMPLOYED_EMPLOYMENT_STATUSES
);

export const EmploymentStatusSchema = Joi.any()
  .valid(...VALID_EMPLOYMENT_STATUS_ID_OPTIONS)
  .required()
  .messages({
    "any.only": "Employment status is not valid",
  });

export const EmployerNameSchema = Joi.string().min(1).required().messages({
  "string.empty": "Employer name is not valid",
});
