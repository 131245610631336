import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { OverdraftProtectionState } from "./types";

export const useOverDraftProtectionInfo= () => {
    return useSelector<AppState, OverdraftProtectionState >(({ application }) => {
      const { overDraftProtection } = application;
      return {
          overDraftProtection
      };
    });
  };