import { AppState } from "../../../store";
import { useSelector } from "react-redux";
import { StepConfig } from "@laurelroad/lrds-react";

export const useCurrentStep = () => {
  return useSelector(({ appView }: AppState) => {
    switch (appView.currentView) {
      case "Profile Creation Page":
        return 1;
      case "Personal Info Page":
        return 2;
      case "Disclosures Page":
        return 3;
      case "Confirmation Page":
        return 4;
      default:
        return 0;
    }
  });
};


export const useNavigationSteps = () => {
  return useSelector<AppState, StepConfig[]>( ({ui}) => ui.desktopNavigationSteps);
}