import Joi from "joi";
import { mapSelectOptionToId } from "../../../GovernmentId/constants/SelectOptions";
import { ACCOUNT_PURPOSE_OPTIONS } from "../SelectOptions";

const VALID_ACCOUNT_PURPOSE_ID_OPTIONS =
  ACCOUNT_PURPOSE_OPTIONS.map(mapSelectOptionToId);

export const AccountPurposeSchema = Joi.object({
  accountPurpose: Joi.any()
    .valid(...VALID_ACCOUNT_PURPOSE_ID_OPTIONS)
    .required()
    .messages({
      "any.only": "The primary purpose of this account is not valid",
    }),
});
