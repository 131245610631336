import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { InputFieldError } from "../../PersonalInfoPage/types";

export const useProfileInfoErrorMessages = (key: string | number) =>
  useSelector<AppState, string | undefined>(({ profileCreationViewState }) => {
    const errors = profileCreationViewState.profileFieldErrors;
    function isTheKey(error: InputFieldError) {
      return error.key[0] === key;
    }
    let errorMessage;
    if (profileCreationViewState.showFieldErrors) {
      const foundError = errors.find(isTheKey);
      errorMessage = foundError?.message;
    }
    return errorMessage;
  });
