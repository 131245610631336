import { useEffect } from "react";
import { usePersonalInfoViewState } from "./usePersonalInfoViewState";
import { usePersonalInfo } from "./usePersonalInfo";
import { useDispatch } from "react-redux";
import { changeView } from "../../App/slice";
import { updateModalStatus } from "../slice";
import { ModalStatus } from "../types";
import { runAml } from "../../AccountCreation/async/Aml/runAml";
import { useIsEligibleForAccount } from "./useIsEligableForAccount";

export const usePersonalInfoSubmittedEffect = () => {
  const dispatch = useDispatch();
  const { personalInfoSubmitted, modalStatus } = usePersonalInfoViewState();
  const personalInfo = usePersonalInfo();
  const isEligibleForAccount = useIsEligibleForAccount();

  useEffect(() => {
    if (isEligibleForAccount) {
      dispatch(changeView({ view: "Disclosures Page" }));
    } else if (
      personalInfoSubmitted &&
      modalStatus !== ModalStatus.AML_MODAL
    ) {
      runAml();
      dispatch(updateModalStatus(ModalStatus.AML_MODAL));
    }
  }, [
    dispatch,
    personalInfoSubmitted,
    personalInfo,
    modalStatus,
    isEligibleForAccount,
  ]);
};
