import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileCreationViewState, ProfileInfo } from "./types";
import { getPrefillData } from "../App/asyncActions";
import {
  getZipCodeInfo,
  postProfileInfo,
  putProfileInfo,
} from "./asyncActions";
import Joi from "joi";
import { profileInfoErrorOrder, ProfileInfoSchema } from "./constants";
import { convertJoiToSortedErrors } from "../../utils/convertJoiToSortedErrors";

export const initialState: ProfileCreationViewState = {
  errors: {},
  showModal: false,
  profileCreated: false,
  zipCodeLookupFinished: false,
  profileFieldErrors: [],
  showFieldErrors: false,
};

const profileSavedReducer = (state: ProfileCreationViewState) => {
  return {
    ...state,
    profileCreated: true,
  };
};

const profileSavedPendingReducer = (state: ProfileCreationViewState) => ({
  ...state,
  showModal: true,
});

const profileCreationViewStateSlice = createSlice({
  name: "profileCreationViewState",
  initialState,
  reducers: {
    clearProfileCreationErrors: (state) => {
      return {
        ...state,
        errors: {},
      };
    },
    validateProfileFields: (state, action: PayloadAction<ProfileInfo>) => {
      const result: Joi.ValidationResult<any> = ProfileInfoSchema.validate(
        action.payload
      );
      const sortedErrors = convertJoiToSortedErrors(
        result,
        profileInfoErrorOrder
      );

      return {
        ...state,
        profileFieldErrors: sortedErrors,
      };
    },
    showProfileFieldErrors: (state) => {
      return {
        ...state,
        showFieldErrors: true,
      };
    },
    hideProfileLoadingModal: (state) => {
      return {
        ...state,
        showModal: false,
      };
    },
    updateProspectCreateSessionId: (state, action: PayloadAction<string>) => {
      return { ...state, prospectCreateSessionId: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProfileInfo.fulfilled, profileSavedReducer)
      .addCase(putProfileInfo.fulfilled, profileSavedReducer)
      .addCase(postProfileInfo.pending, profileSavedPendingReducer)
      .addCase(putProfileInfo.pending, profileSavedPendingReducer)
      .addCase(getPrefillData.fulfilled, (state: ProfileCreationViewState) => ({
        ...state,
      }))
      .addCase(getZipCodeInfo.fulfilled, (state: ProfileCreationViewState) => {
        return {
          ...state,
          zipCodeLookupFinished: true,
        };
      })
      .addCase(getZipCodeInfo.rejected, (state: ProfileCreationViewState) => {
        return {
          ...state,
          errors: { ...state.errors, zipCode: "Invalid Zip Code specified" },
          showModal: false,
        };
      });
  },
});

export const {
  clearProfileCreationErrors,
  hideProfileLoadingModal,
  updateProspectCreateSessionId,
  validateProfileFields,
  showProfileFieldErrors,
} = profileCreationViewStateSlice.actions;

export const { reducer: ProfileCreationViewStateReducer } =
  profileCreationViewStateSlice;
