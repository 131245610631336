import React, { ReactElement } from 'react';
import { AccountCreationResponse } from '../../AccountCreation/types';
import ApprovedBody from './ApprovedBody/ApprovedBody';
import {
  isCheckingProduct,
} from '../../../constants/constants';
import SomeAccountsNotCreatedBody from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/SomeAccountsNotCreatedBody';
import CheckingAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/CheckingAccountNotCreated';
import SavingsAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/SavingsAccountNotCreated';
import MPFreedomCheckingAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/MPFreedomCheckingAccountNotCreated';
import MPBundleFreedomCheckingAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/MPBundleFreedomCheckingAccountNotCreated';
import FreedomCheckingAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/FreedomCheckingAccountNotCreated';
import BundleFreedomCheckingAccountNotCreated from './ApprovedBody/ApprovedBodyInstructions/AccountNotCreated/BundleFreedomCheckingAccountNotCreated';
import { ProductCode } from '../../../constants/ProductCode';

interface ViewRule {
  test: (accounts: AccountCreationResponse[]) => ReactElement;
}
class ViewRuleDecisionTree implements ViewRule {
  private positive: ViewRule;
  private negative: ViewRule;
  readonly predicate: (accounts: AccountCreationResponse[]) => boolean;
  constructor(
    positive: ViewRule,
    negative: ViewRule,
    predicate: (accounts: AccountCreationResponse[]) => boolean
  ) {
    this.positive = positive;
    this.negative = negative;
    this.predicate = predicate;
  }
  test = (accounts: AccountCreationResponse[]): ReactElement =>
    this.predicate(accounts) ? this.positive.test(accounts) : this.negative.test(accounts);
}

const hasSomeAccountsNotCreated = (approvedAccounts: AccountCreationResponse[]) =>
  approvedAccounts.some(({ accountCreated }) => !accountCreated);

const isBundleFreedomProduct = (approvedAccounts: AccountCreationResponse[]) =>
  approvedAccounts.some(({ productCode }) => productCode === ProductCode.BUNDLE_IDR_CHECKING || productCode === ProductCode.BUNDLE_PSLF_CHECKING);

const isFreedomProduct = (approvedAccounts: AccountCreationResponse[]) =>
  approvedAccounts.some(({ productCode }) => productCode === ProductCode.IDR_CHECKING || productCode === ProductCode.PSLF_CHECKING);

const viewRuleTree = new ViewRuleDecisionTree(
  new ViewRuleDecisionTree(
    new ViewRuleDecisionTree(
      {
        test: () => <MPFreedomCheckingAccountNotCreated />,
      },
      new ViewRuleDecisionTree(
        {
          test: () => <MPBundleFreedomCheckingAccountNotCreated />,
        },
        {
          test: () => <SomeAccountsNotCreatedBody />,
        },
        accounts => isBundleFreedomProduct(accounts)
      ),

      accounts => isFreedomProduct(accounts)
    ),
    new ViewRuleDecisionTree(
      new ViewRuleDecisionTree(
        {
          test: () => <FreedomCheckingAccountNotCreated />,
        },
        new ViewRuleDecisionTree(
          {
            test: () => <BundleFreedomCheckingAccountNotCreated />,
          },
          {
            test: () => <CheckingAccountNotCreated />,
          },
          accounts => isBundleFreedomProduct(accounts)
        ),

        accounts => isFreedomProduct(accounts)
      ),
      {
        test: () => <SavingsAccountNotCreated />,
      },

      accounts => isCheckingProduct(accounts[0]?.productCode)
    ),
    accounts => accounts.length > 1
  ),
  {
    test: accounts => <ApprovedBody accounts={accounts} />,
  },
  hasSomeAccountsNotCreated
);
export const approvedViewBodyFactory = (approvedAccounts: AccountCreationResponse[]) => {
  return viewRuleTree.test(approvedAccounts);
};
