import React, { FC, useCallback } from "react";
import { Column, DatePicker } from "@laurelroad/lrds-react";
import { useProspectDateOfBirth } from "./hooks";
import { useDispatch } from "react-redux";
import { updateDateField } from "../../../utils/applicationDispatchFunctions";
import { DatePickerChangeEvent } from "@laurelroad/lrds-react/dist/src/components/DatePicker";
import { useProfileInfoErrorMessages } from "../hooks/useProfileInfoErrorMessages";
import { validateProfileFields } from "../slice";
import { useProfileInfo } from "../hooks";

const DateOfBirth: FC = () => {
  const dateOfBirth = useProspectDateOfBirth();
  const dispatch = useDispatch();
  const profileInfo = useProfileInfo();

  const datePickerChangeHandler = useCallback(
    (event: DatePickerChangeEvent) => {
      dispatch(updateDateField(event));
    },
    [dispatch]
  );

  const blurHandler = () => {
    dispatch(validateProfileFields(profileInfo));
  };

  return (
    <Column size={4} className="fs-exclude">
      <DatePicker
        id={"dateOfBirth"}
        name={"dateOfBirth"}
        className="high-yield-savings__form-field fs-exclude"
        value={dateOfBirth}
        onChange={datePickerChangeHandler}
        label={"Date of Birth"}
        error={useProfileInfoErrorMessages("dateOfBirth")}
        format={"MM/DD/YYYY"}
        required={true}
        data-heap-redact-text="true"
        onBlur={blurHandler}
      />
    </Column>
  );
};

export default DateOfBirth;
