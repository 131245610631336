import React, { FC, memo } from 'react';
import { Column, Optional, Paragraph } from '@laurelroad/lrds-react';
import { useInstructionsParagraph } from '../../hooks/useInstructionsParagraph';
import { ProductCode } from '../../../../constants/ProductCode';
import SavingsInstructions from './ApprovedBodyInstructions/SavingsInstructions';
import { useApplicationInfo } from '../../hooks';
import { useConfirmationEventData } from '../../hooks/useConfirmationEventData';
import { useBundleInfo } from '../../../BundleModal/hook/useBundleInfo';
import MPFreedomCheckingInstructions from './ApprovedBodyInstructions/MPFreedomCheckingInstructions';
import MPBundleFreedomCheckingInstructions from './ApprovedBodyInstructions/MPBundleFreedomCheckingInstructions';

const BundleBody: FC = () => {
  const bundleInfo = useBundleInfo();
  const application = useApplicationInfo();
  const confirmationEventData = useConfirmationEventData();
  const primaryProductCode = application.productCode || confirmationEventData.productCode;
  const secondaryProductCodes = bundleInfo.productCodes;
  const productCodes = [primaryProductCode, ...secondaryProductCodes];
  const checkingInstructions = useInstructionsParagraph();
  const hasCheckings =
    productCodes.includes(ProductCode.CHECKING) ||
    productCodes.includes(ProductCode.LOYALTY_CHECKING) ||
    productCodes.includes(ProductCode.BUNDLE_CHECKING);
  const hasSavings =
    productCodes.includes(ProductCode.SAVINGS) ||
    productCodes.includes(ProductCode.BUNDLED_SAVINGS);
  const hasFreedomChecking =
    productCodes.includes(ProductCode.PSLF_CHECKING) ||
    productCodes.includes(ProductCode.IDR_CHECKING);
  const hasBundleFreedomChecking =
    productCodes.includes(ProductCode.BUNDLE_IDR_CHECKING) ||
    productCodes.includes(ProductCode.BUNDLE_PSLF_CHECKING);

  return (
    <Column size={12}>
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Managing Your New Accounts Online
        </span>
      </Paragraph>
      <Optional renderIf={hasCheckings}>{checkingInstructions}</Optional>
      <Optional renderIf={hasFreedomChecking}>
        <MPFreedomCheckingInstructions />
      </Optional>
      <Optional renderIf={hasBundleFreedomChecking}>
        <MPBundleFreedomCheckingInstructions />
      </Optional>
      <Optional renderIf={hasSavings && !hasFreedomChecking && !hasBundleFreedomChecking}>
        <SavingsInstructions />
      </Optional>
    </Column>
  );
};

export default memo(BundleBody);
