import Joi from "joi";
import { PRIMARY_INCOME_SOURCES } from "../PrimaryIncomeSources";

const VALID_PRIMARY_INCOME_SOURCE_ID_OPTIONS = Object.keys(
  PRIMARY_INCOME_SOURCES
).map((sourceName) => PRIMARY_INCOME_SOURCES[sourceName]);

export const PrimaryIncomeSourceSchema = Joi.any()
  .valid(...VALID_PRIMARY_INCOME_SOURCE_ID_OPTIONS)
  .required()
  .messages({
    "any.only": "Primary income source is invalid",
  });
