import { FC } from 'react';
import { Paragraph } from '@laurelroad/lrds-react';
import React from 'react';

const SomeAccountsNotCreatedBody: FC = () => {
  return (
    <>
      {' '}
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Accounts
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Please bear with us while we finish setting up your account. You’ll receive an email
        confirming your account opening in a few hours. Then, you’ll be able to log into our
        Online Banking system with the User ID and password you created.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Once your accounts are up and running, you’ll:
        <ul style={{ marginInlineStart: '2em' }}>
          <li>
            <a href="https://www.laurelroad.com/direct-deposit/">Set up direct deposit</a> to
            your Loyalty Checking account so you can begin earning rewards, and
          </li>
          <li>
            <a href="https://www.laurelroad.com/faq-category/high-yield-savings/">
              Fund your High Yield Savings
            </a>{' '}
            account by bank transfer, wire, digital check, or direct deposit.
          </li>
        </ul>
      </Paragraph>
    </>
  );
};
export default SomeAccountsNotCreatedBody;
