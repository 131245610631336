import { themeColors } from "@laurelroad/lrds-react";
import { StylesConfig } from "react-select";
import { CSSObject } from "@emotion/serialize";

type OptionType = {
  label: string;
  value: string;
};
type IsMulti = false;

export const LrdsSelectStyles: StylesConfig<OptionType, IsMulti> = {
  container: (base) => ({
    ...base,
    width: "100%",
    paddingTop: "6px",
    maxHeight: "50px",
    alignSelf: "baseline",
    display: "inline-block",
  }),
  control: (_, { isFocused }) => ({
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    transition: "all 100ms",
    cursor: "pointer",
    height: "50px",
    outline: "none",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: themeColors.secondary,
    caretColor: themeColors.primary,
    borderColor: isFocused ? themeColors.pending : themeColors.primary,
    boxShadow: isFocused
      ? "inset 1px 1px #f2aa04, inset -1px 0 #f2aa04, inset 0 -1px #f2aa04"
      : "inset 1px 0 transparent, inset -1px 0 transparent, inset 0 -1px transparent",
    "&:focus": {
      boxShadow:
        "inset 1px 1px #f2aa04, inset -1px 0 #f2aa04, inset 0 -1px #f2aa04",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "15px 20px 14px 20px",
  }),
  option: (base) => {
    return {
      ...base,
      cursor: "pointer",
      alignSelf: "center",
      color: themeColors.secondary,
      background: themeColors.surface,
      padding: "15px 20px 14px 20px",
      "&:hover,&:active": { background: themeColors.surface2 },
      "&:not(:last-child)": { borderBottom: "solid 1px silver" },
    };
  },
  menu: (base: CSSObject) => ({
    ...base,
    marginTop: 2,
  }),
  menuList: (base) => ({
    ...base,
    outline: "none",
    borderBottom: "10px solid #f2a900",
    borderRadius: "0 0 8px 8px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  input: () => ({
    fontSize: "16px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingRight: "12px",
    color: themeColors.primary,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingRight: "8px",
    color: themeColors.primary,
    "&:hover": {
      opacity: 0.75,
      transform: "scale(1.1,1.1)",
      transition: "all ease-in-out 100ms",
    },
  }),
};
