import React, { FC, useCallback, useEffect, useState } from "react";
import SearchableDropdown from "../../SearchableDropdown/searchableDropdown";
import {Column, Optional} from "@laurelroad/lrds-react";
import { useDispatch } from "react-redux";
import { useEducationInfo } from "./hooks";
import { SelectFieldEvent } from "@laurelroad/lrds-react/dist/src/components/SelectField";
import {
  resetFields,
  updateSelectField,
} from "../../../utils/applicationDispatchFunctions";
import { EducationErrors } from "./types";
import {
  DEGREE_TYPE_OPTIONS,
  DEGREE_TYPE_TO_DEGREE_OPTIONS,
} from "./constants/SelectOptions";
import { usePersonalInfoErrorMessages } from "../hooks/usePersonalInfoErrorMessages";
import { usePersonalInfo } from "../hooks";
import {validatePersonalFields} from "../asyncActions";

const DEFAULT_DEGREE_TYPE_OPTIONS = [{ id: "invalid", text: "invalid" }];

const Education: FC = () => {
  const dispatch = useDispatch();
  const { degree, degreeType } = useEducationInfo();
  const [errors, updateErrors] = useState<EducationErrors>({});
  const personalInfo = usePersonalInfo();

  const [degreeTypeItems, setDegreeTypeItems] = useState(
    DEFAULT_DEGREE_TYPE_OPTIONS
  );

  useEffect(() => {
    setDegreeTypeItems(DEGREE_TYPE_TO_DEGREE_OPTIONS[degree]);
  }, [degree]);

  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const degreeDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
      dispatch(resetFields(["degreeType"]));
      updateErrors({ ...errors, [event.fieldName!]: undefined });
    },
    [dispatch, updateErrors, errors]
  );
  const degreeTypeDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
      updateErrors({ ...errors, [event.fieldName!]: undefined });
    },
    [dispatch, updateErrors, errors]
  );

  return (
    <>
      <Column className={"col--relative"} size={3}>
        <SearchableDropdown
          className="high-yield-savings__form-field"
          id={"degree"}
          name={"degree"}
          label={"Select Degree Type"}
          value={degree}
          items={DEGREE_TYPE_OPTIONS}
          onChange={degreeDropdownChangeHandler}
          onBlur={blurHandler}
          error={usePersonalInfoErrorMessages("degree")}
          required={true}
        />
      </Column>
      <Optional renderIf={degree && degree !== 'no_results'}>
          <Column className={"col--relative"} size={3}>
              <SearchableDropdown
                  className="high-yield-savings__form-field"
                  id={"degreeType"}
                  key={degreeType}
                  value={degreeType}
                  name={"degreeType"}
                  label={"Which best describes your degree?"}
                  items={degreeTypeItems}
                  onChange={degreeTypeDropdownChangeHandler}
                  onBlur={blurHandler}
                  error={usePersonalInfoErrorMessages("degreeType")}
                  required={true}
              />
          </Column>
      </Optional>
    </>
  );
};

export default Education;
