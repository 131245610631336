import Joi from "joi";
import { SsnSchema } from "./SocialSecurityNumberSchema";
import { SsnConfirmationSchema } from "./SocialSecurityNumberConfirmationSchema";

export const SocialSecurityNumberSchema = Joi.object({
  socialSecurityNumber: SsnSchema,
  socialSecurityNumberConfirmation: SsnConfirmationSchema,
});

export const Schema = Joi.object({
socialSecurityNumber: Joi.string()
.length(9)
  .regex(/^\d{9}$/)
  .messages({
    "string.empty": "Social Security Number is required",
    "string.length": "Please enter your 9-digit Social Security Number",
    "string.ref": "Please enter your 9-digit Social Security Number",
    "string.pattern.base": "Please enter your 9-digit Social Security Number",
  })
  .required(),
socialSecurityNumberConfirmation: Joi.string().required().valid(Joi.ref('socialSecurityNumber'))
});

export const ConfirmSsnSchema = Joi.string()
  .length(9)
  .regex(/^\d{9}$/)
  .messages({
    "string.empty": "Social Security Number Confirmation is required",
    "string.length": "Please enter your 9-digit Social Security Number",
    "string.ref": "Please enter your 9-digit Social Security Number",
    "string.pattern.base": "Please enter your 9-digit Social Security Number",
  })
  .required();