import { Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const LoyaltyInstructions: FC = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’ll receive an email confirming your account opening shortly. You've also been
        automatically enrolled in our online banking system, which you can access with your
        User ID and password.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Your next step is to set up direct deposit so you can begin earning rewards. To do so,
        sign in to your online banking dashboard by downloading our mobile app or clicking the
        link below. You can also access online banking from the link within your account
        confirmation email, or from laurelroad.com. (FYI, it can take up to 24 hours for your
        account to appear in online banking.)
      </Paragraph>
    </>
  );
};

export default memo(LoyaltyInstructions);
