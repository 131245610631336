import { getRequest } from "../../../utils/crud";

import { CheckApplicationTokenResponse } from "./types";
import { CHECK_AUTH_TOKEN_ENDPOINT } from "../constants";
import {AMTCredentialCreationFailedError} from "./invokeCredentialCreation";
import AMTCreateCredentialsFailed from "../components/AMTCreateCredentialsFailed";
import React from "react";

export class InvalidApplicationTokenError extends Error {}
export class LoginNeededError extends Error {}

export const getAuthTokenFromUrl = (): string => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const authToken = urlParams.get("authToken");
  if (!authToken) {
    throw new InvalidApplicationTokenError();
  }
  return authToken;
};

export const checkApplicationToken = async (
    authToken: string
): Promise<CheckApplicationTokenResponse> => {
  const url = `${CHECK_AUTH_TOKEN_ENDPOINT}?authToken=${authToken}`;
  return getRequest<CheckApplicationTokenResponse>(url)
      .then((result) => {

        if (result.applicationId === null) {
          throw new InvalidApplicationTokenError();
        }

        if (result.loginNeeded) {
          throw new LoginNeededError();
        }

        return result;
      }).catch((error) => {

        if (error.status === 404) {
          throw new InvalidApplicationTokenError();
        }

        if(error instanceof LoginNeededError){
            throw new LoginNeededError();
        }

        if(error instanceof InvalidApplicationTokenError){
            throw new InvalidApplicationTokenError();
        }

        throw new Error();
      });
};
