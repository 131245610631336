import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { AccountPurposeState } from "./types";

export const useAccountPurpose = () => {
  return useSelector<AppState, AccountPurposeState>(({ application }) => {
    const { accountPurpose } = application;
    return {
      accountPurpose,
    };
  });
};
