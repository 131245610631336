import Joi from 'joi';
import { StreetAddressOneSchema } from './StreetAddressOneSchema';
import { ZipCodeSchema } from './ZipCodeSchema';
import { StreetAddressTwoSchema } from './StreetAddressTwoSchema';

export const AddressSchema = Joi.object({
  streetAddress: StreetAddressOneSchema.required(),
  streetAddressTwo: StreetAddressTwoSchema.allow(''),
  zipCode: ZipCodeSchema.required(),
});
