import React, { FC, memo } from "react";
import { Loader, Optional } from "@laurelroad/lrds-react";
import { useDisclosureInfo } from "../hooks";
import OverdraftProtection from "./OverdraftProtection/OverdraftProtection";
import { useIsViewingOverdraftDisclosure } from "../hooks/useIsViewingOverdraftDisclosure";
import "./disclosures-body.scss";
import AcknowledgementViewer from "./AcknowledgementViewer/AcknowledgementViewer";
import Acknowledgements from "./AcknowledgementList/Acknowledgements";
import { useIsCheckingApplication } from "../../../hooks/useIsCheckingApplication";

const DisclosuresBody: FC = () => {
  const { acknowledgements, selectedAcknowledgement } = useDisclosureInfo();
  const isViewingOverdraftDisclosure = useIsViewingOverdraftDisclosure();
  const isChecking = useIsCheckingApplication();

  return (
    <div className={"disclosures__body"}>
      <Optional renderIf={acknowledgements.length === 0}>
        <Loader />
      </Optional>
      <Optional renderIf={acknowledgements.length > 0}>
        <Acknowledgements />
        <AcknowledgementViewer acknowledgement={selectedAcknowledgement} />
      </Optional>
      <Optional renderIf={isChecking && isViewingOverdraftDisclosure}>
        <OverdraftProtection />
      </Optional>
    </div>
  );
};

export default memo(DisclosuresBody);
