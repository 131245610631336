import moment from "moment";

export const toIsoString = (dateString: string, format = "MM/DD/YYYY") => {
  return moment.utc(dateString, format).toISOString();
};

export const toYearMonthDateString = (dateString: string) => {
  return moment.utc(dateString).format("YYYY-MM-DD");
};

export const toMonthDateYearString = (dateString: string) => {
  return moment.utc(dateString).format("MM/DD/YYYY");
};
