import React, { FC } from "react";
import { Button } from "@laurelroad/lrds-react";
import { redirectToLaurelRoad } from "../utils/redirectToLaurelRoad";

const InvalidApplicationToken: FC = () => {
  return (
    <div className={"create-credentials__modal"}>
      <h6>
      You have already completed this step of the application and the link is expired. Please check your email for an update on the status of your application and next steps. If you continue to receive this error message and would like further assistance, please contact Member Services at 1-833-427-2265 (TDD/TTY at 1-800-539-8336).
      </h6>
      <Button onClick={redirectToLaurelRoad}>OK</Button>
    </div>
  );
};

export default InvalidApplicationToken;
