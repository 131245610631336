import "./styles.scss";
import { useDispatch } from "react-redux";
import React, { FC, useCallback } from "react";
import { DialogModal } from "@laurelroad/lrds-react";
import { useLeavingModalDestination } from "../../hooks/useUiState";
import { setLeavingModalDestination } from "../../store/state/uiSlice";

const LeavingModal: FC = () => {
  const dispatch = useDispatch();
  const destination = useLeavingModalDestination();

  const continueButtonClickHandler = useCallback(() => {
    window.open(destination);
    dispatch(setLeavingModalDestination(""));
  }, [dispatch, destination]);

  const closeButtonClickHandler = useCallback(() => {
    dispatch(setLeavingModalDestination(""));
  }, [dispatch]);

  return (
    <DialogModal
      hasLeftButton
      hasRightButton
      isOpen={!!destination}
      className="leaving-modal"
      leftButtonText="Go Back"
      rightButtonText="Continue"
      title="You are leaving laurelroad.com"
      onClickOut={closeButtonClickHandler}
      rightButtonProps={{ onClick: continueButtonClickHandler }}
      leftButtonProps={{ type: "secondary", onClick: closeButtonClickHandler }}
    >
      <div className="leaving-modal__message">
        By clicking Continue, you will be taken to a third-party website.
        <br />
        Laurel Road, a Brand of KeyBank N.A., is not responsible for the
        content, links,
        <br />
        privacy policy, or security policy of third-party websites.
      </div>
    </DialogModal>
  );
};

LeavingModal.defaultProps = {};

export default LeavingModal;
