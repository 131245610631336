import { Acknowledgement, DisclosureInfoState } from "./types";
import {
  DisclosureInfoCheckingSchema,
  DisclosureInfoLoyaltySchema,
  DisclosureInfoSavingSchema,
} from "./constants";
import { OVER_DRAFT_PROTECTION_NAME } from "./DisclosuresBody/OverdraftProtection/constants";

export const validateSavingsDisclosures = (disclosureInfo: DisclosureInfoState, ) => {
  const result = DisclosureInfoSavingSchema.validate(disclosureInfo);
  return !result.error;
};


export const validateCheckingDisclosures = (disclosureInfo: DisclosureInfoState, ) => {
  const result = DisclosureInfoCheckingSchema.validate(disclosureInfo);
  return !result.error;
};

export const validateLoyaltyDisclosures = (disclosureInfo: DisclosureInfoState, ) => {
  const result = DisclosureInfoLoyaltySchema.validate(disclosureInfo);
  return !result.error;
};

export const convertFetchDisclosuresToAckowledgements = (
  acknowledgements: Acknowledgement[]
) => {
  const preventDisclosureContinue = [OVER_DRAFT_PROTECTION_NAME];
  return acknowledgements.map((acknowledgement) => {
    const preventContinue = preventDisclosureContinue.includes(
      acknowledgement.displayName
    );
    return { ...acknowledgement, preventContinue };
  });
};
