import Joi from "joi";
import {
  mapSelectOptionToId,
  OCCUPATION_CLASSIFICATION_OPTIONS,
} from "../SelectOptions";

const VALID_OCCUPATION_CLASSIFICATION_ID_OPTIONS =
  OCCUPATION_CLASSIFICATION_OPTIONS.map(mapSelectOptionToId);

export const OccupationClassificationSchema = Joi.any()
  .valid(...VALID_OCCUPATION_CLASSIFICATION_ID_OPTIONS)
  .messages({
    "any.only": "Occupation classification is invalid",
  });

export const EmployerNameSchema = Joi.string().min(1).required().messages({
  "string.empty": "Employer name is not valid",
  "string.length": "Employer name is not valid",
});
