import { useEffect } from "react";
import { useBundleInfo } from "../../components/BundleModal/hook/useBundleInfo";
import { useApplicationInfo } from "../../components/ConfirmationPage/hooks";
import { ApplicationCreatedStatus } from "../../types/ApplicationCreatedStatus";
import { sendToGTM } from "./sendToGTM";

export interface GtmBundleApplication {
  applicationId: number;
  productCode: string;
}


/*
    format used for applications is
    <productCode> | <appId> 
    priimary app is first
*/
export const useSendMultiproductAppCreateEvent = () => {
  const { id, productCode } = useApplicationInfo();
  const { createdApplications, createdApplicationsStatus } = useBundleInfo();

  useEffect(() => {
    if (createdApplicationsStatus === ApplicationCreatedStatus.Complete) {
        const data = createdApplications.reduce( (acc, curr) => {
            return [...acc, curr.id, curr.productCode, ]
        }, [id, productCode])
      sendToGTM({
        event: "oao_bundle_applications",
        applications:  data.join(" | ")
      });
    }
  }, [createdApplications, createdApplicationsStatus, id, productCode]);
};
