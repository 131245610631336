import React, { FC, memo } from "react"
import { AcknowledgmentListProps } from "../../types"
import AcknowledgementRow from "./AcknowledgementRow/AcknowledgementRow";

const AcknowledgementList: FC <AcknowledgmentListProps> = ({
  acknowledgements,
  selectHandler,
  selectedAcknowledgement
}) => {
    const acknowledgementRows = acknowledgements.map((acknowledgement, index) => (
        <AcknowledgementRow
          acknowledgement={acknowledgement}
          key={index}
          onClick={selectHandler}
          isActiveRow={acknowledgement?.file === selectedAcknowledgement?.file}
        />
      ));

      return <>{acknowledgementRows}</>
};

export default memo(AcknowledgementList);