import React, {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import { Checkbox, Column, Optional, Tooltip } from "@laurelroad/lrds-react";
import { useDispatch } from "react-redux";
import { updateCheckboxField } from "../../../utils/applicationDispatchFunctions";
import { useElectronicDisclosure } from "./hooks";
import ElectronicDisclosureModal from "./ElectronicDisclosureModal/ElectronicDisclosureModal";
import "./ElectronicDisclosure.scss";
import { useProfileInfoErrorMessages } from "../hooks/useProfileInfoErrorMessages";

const ElectronicDisclosure: FC = () => {
  const dispatch = useDispatch();
  const electronicCommunicationDisclosureAgreement = useElectronicDisclosure();
  const [hasViewedDisclosures, setHasViewedDisclosures] = useState(false);
  const [isDisclosureModalOpen, setIsDisclosureModalOpen] = useState(false);

  const viewedDisclosuresHandler = () => {
    setIsDisclosureModalOpen((isDisclosureModalOpen) => !isDisclosureModalOpen);
    setHasViewedDisclosures(true);
  };

  const disclosureTextKeyPress: KeyboardEventHandler = useCallback((event) => {
    if (event.key === "Enter") {
      viewedDisclosuresHandler();
    }
  }, []);

  const checkboxChangeHandler: MouseEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        dispatch(updateCheckboxField(event));
      },
      [dispatch]
    );

  const modalHandler = (isOpen: boolean) => {
    setIsDisclosureModalOpen(isOpen);
    setHasViewedDisclosures(true);
  };

  return (
    <Column size={12} className="student-loan-cc__form-field student-checkbox">
      <ElectronicDisclosureModal
        isOpen={isDisclosureModalOpen}
        modalHandler={modalHandler}
      />
      <Tooltip
        content="Please view the disclosures and check the box to show agreement."
        position="top-left"
      >
        <span className="disclosure-agreement-checkbox__asterisk">*</span>
        <Optional renderIf={!hasViewedDisclosures}>
          <div
            className="disclosure-agreement-checkbox__click-event"
            onClick={viewedDisclosuresHandler}
          />
        </Optional>
        <Checkbox
          id="electronicCommunicationDisclosureAgreement"
          name="electronicCommunicationDisclosureAgreement"
          className="disclosure-agreement-checkbox"
          checked={electronicCommunicationDisclosureAgreement}
          onClick={checkboxChangeHandler}
          disabled={!hasViewedDisclosures}
        />
        <span
          onClick={() => {
            viewedDisclosuresHandler();
          }}
        >
          <span
            data-color="primaryText"
            className="electronic-disclosure__label"
          >
            By checking this box I agree that I am able to{" "}
            <div
              tabIndex={0}
              style={{ display: "inline", color: "#326295" }}
              onKeyPress={disclosureTextKeyPress}
            >
              open and review the E-Sign Election, Privacy Policy and Wireless
              Express Consent and Identification Disclosures
            </div>
            , have retained a copy for my records and agree to the terms of all
            these disclosures.
          </span>
        </span>
      </Tooltip>
      <Optional
        renderIf={useProfileInfoErrorMessages(
          "electronicCommunicationDisclosureAgreement"
        )}
      >
        <span className={"electronic-disclosures__error"}>
          You must agree to these disclosures in order to proceed with your
          application.
        </span>
      </Optional>
    </Column>
  );
};

export default ElectronicDisclosure;
