import Joi from 'joi';
import { EmailSchema } from './ProspectEmail/constants/schemas';
import {
  FirstNameSchema,
  LastNameSchema,
  MiddleNameSchema,
  SuffixSchema,
} from './ProspectName/constants/schemas';
import { AddressSchema } from './Address/constants/schemas';
import { SocialSecurityNumberSchema } from './SocialSecurityNumber/constants/schemas';
import { DateOfBirthSchema } from './DateOfBirth/constants/schemas';
import { MobilePhoneSchema } from './MobilePhone/constants/schemas';
import { ProductCode } from '../../constants/ProductCode';
import { PrefixSchema } from './Prefix/constants/PrefixSchema';

export const ZIPCODE_LOOKUP_ENDPOINT = '/api/zip/lookup';
export const PROFILE_ENDPOINT = '/applications/profile';
export const JARVIS_ENDPOINT = '/jarvis/eligibility';
export const PAS_ENDPOINT = '/jarvis/pas';
export const MULTIPRODUCT_ENDPOINT = '/multiproduct';
export const ADDRESS_AC_ENDPOINT = '/api/address/autocomplete';
export const ADDRESS_DETAILS_ENDPOINT = '/api/address/components';

const nameSchema = Joi.string().allow('');
const idSchema = Joi.number().allow(null).allow('');
const productCodeSchema = Joi.string().allow(
  ProductCode.CHECKING,
  ProductCode.BUNDLED_SAVINGS,
  ProductCode.SAVINGS,
  ProductCode.BUNDLE_CHECKING
);

const agreementSchema = Joi.boolean().valid(true).required();

export const ProfileInfoSchema = Joi.object({
  firstName: FirstNameSchema.required(),
  middleName: MiddleNameSchema,
  lastName: LastNameSchema.required(),
  dateOfBirth: DateOfBirthSchema,
  email: EmailSchema,
  mobilePhone: MobilePhoneSchema,
  electronicCommunicationDisclosureAgreement: agreementSchema,
  city: nameSchema.allow(null),
  state: nameSchema.allow(null),
  id: idSchema,
  productCode: productCodeSchema,
  prefix: PrefixSchema,
  suffix: SuffixSchema,
})
  .concat(AddressSchema)
  .concat(SocialSecurityNumberSchema)
  .options({ abortEarly: false });

export const profileInfoErrorOrder: (string | number)[] = [
  'firstName',
  'middleName',
  'lastName',
  'dateOfBirth',
  'email',
  'mobilePhone',
  'socialSecurityNumber',
  'socialSecurityNumberConfirmation',
  'streetAddress',
  'streetAddressTwo',
  'zipCode',
  'electronicCommunicationDisclosureAgreement',
];
