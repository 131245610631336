import { initialize } from "../../../utils/amt";
import { useEffect } from "react";
import { useAMTInfo } from "./useAMTInfo";
import { AMTInitializationStatus } from "../types";
import { useDispatch } from "react-redux";
import { updateInitializationStatus } from "../slice";
import { PayloadAction } from "@reduxjs/toolkit";

const executeAMTInitialization = async (
  dispatch: (a: PayloadAction<AMTInitializationStatus>) => void
) => {
  const initialized = await initialize();
  const status = initialized
    ? AMTInitializationStatus.Passed
    : AMTInitializationStatus.Failed;
  dispatch(updateInitializationStatus(status));
};

export const useInitializeAMT = () => {
  const dispatch = useDispatch();
  const { initializationStatus } = useAMTInfo();
  useEffect(() => {
    if (initializationStatus === AMTInitializationStatus.Pending) {
      executeAMTInitialization(dispatch);
    }
  }, [dispatch, initializationStatus]);
};
