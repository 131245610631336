import { PersonalInfo } from "./types";
import { Application } from "../../types/Application";
import { PersonalInfoSchema, ReferralCodeSchema } from "./constants";
import { toYearMonthDateString } from "../../utils/dateUtils";
import { Context, ValidationError, ValidationErrorItem, ValidationResult } from "joi";

export const convertApplicationToPersonalInfo = (
  application: Application
): PersonalInfo => {
  const {
    governmentIdType,
    governmentIdLocation,
    expiryDate,
    governmentIdNumber,
    primaryIncomeSource,
    otherOrNoIncomeExplanation,
    degree,
    degreeType,
    employmentStatus,
    employerName,
    employerIndustry,
    occupationClassification,
    expectedSourcesOfOngoingAccountFunding,
    citizenshipStatus,
    citizenshipCountries,
    residencyCountries,
    foreignGovernmentDisclosure,
    slosRedirectUrl,
    loginUser,
    issueDate,
    accountPurpose,
    employerReferralCode
  } = application;

  return {
    governmentIdType,
    governmentIdLocation,
    expiryDate,
    governmentIdNumber,
    primaryIncomeSource,
    otherOrNoIncomeExplanation,
    degree,
    degreeType,
    employmentStatus,
    employerName,
    employerIndustry,
    occupationClassification,
    expectedSourcesOfOngoingAccountFunding,
    citizenshipStatus,
    citizenshipCountries,
    residencyCountries: residencyCountries?.length
      ? residencyCountries
      : [{ value: "US", label: "United States" }],
    foreignGovernmentDisclosure:
      foreignGovernmentDisclosure === "PEPSTAT004"
        ? "PEPSTAT001, PEPSTAT002, PEPSTAT003, PEPSTAT004"
        : foreignGovernmentDisclosure,
    slosRedirectUrl,
    loginUser,
    issueDate,
    accountPurpose,
    employerReferralCode
  };
};

export const validatePersonalInfo = (personalInfo: PersonalInfo, context?: Context) => {
  let result = PersonalInfoSchema.validate(personalInfo, {
    context
  })
  return result;
};

export const validateReferralCode = async (employerReferralCode: string | undefined) => {
  return await ReferralCodeSchema.validateAsync({ employerReferralCode }, {errors: {label: false}})
    .then(() => ({ value: employerReferralCode } as ValidationResult))
    .catch((error) => ({
      value: employerReferralCode,
      error: {
        details: [{
          message: error.message,
          path: ['employerReferralCode']
        }]
      } as ValidationError
    } as ValidationResult))
}

export const convertToPersonalInfoWithFormattedDates = (
  personalInfo: PersonalInfo
) => {
  const { expiryDate, issueDate } = personalInfo;
  const formattedExpiryDate = expiryDate ? toYearMonthDateString(expiryDate) : undefined;
  const formattedIssueDate = issueDate
    ? toYearMonthDateString(issueDate)
    : issueDate;
  return {
    ...personalInfo,
    expiryDate: formattedExpiryDate,
    issueDate: formattedIssueDate,
  };
};

export const isEligibleForAccount = (personalInfo: PersonalInfo) => {
  const { citizenshipStatus, foreignGovernmentDisclosure, employerIndustry } =
    personalInfo;
  return !(!citizenshipStatus ||
    citizenshipStatus === "Non-Resident" ||
    !foreignGovernmentDisclosure ||
    foreignGovernmentDisclosure ===
    "PEPSTAT001, PEPSTAT002, PEPSTAT003, PEPSTAT004" ||
    (employerIndustry && employerIndustry !== "none"));


};

export const personalInfoErrorOrder: (string | number)[] = [
  "governmentIdType",
  "governmentIdLocation",
  "issueDate",
  "expiryDate",
  "governmentIdNumber",
  "degree",
  "degreeType",
  "primaryIncomeSource",
  "employmentStatus",
  "employerName",
  "occupationClassification",
  "employerIndustry",
  "accountPurpose",
  "expectedSourcesOfOngoingAccountFunding",
  "citizenshipStatus",
  "citizenshipCountries",
  "citizenshipResidences",
  "foreignGovernmentDisclosure",
  "employerReferralCode"
];
