import { checkApplicationToken, getAuthTokenFromUrl } from './checkApplicationToken';
import { CheckApplicationTokenResponse } from './types';
import { authorizeWithAMT } from './authorizeWithAMT';
import { invokeAMTCredentialCreation } from './invokeAMTCredentialCreation';
import { initializeAMT } from './initializeAMT';
import { sendOaoReturnEvent } from '../../../services/google-tag-manager/sendOaoReturnEvent';
import { setProductText } from '../../../store/state/uiSlice';
import store from '../../../store';
import { updateApplication } from '../../../store/state/applicationSlice';
import { parseOriginationId } from '../../../utils/parseOriginationId';

export class AMTAuthorizationFailedError extends Error {}
export class AMTCredentialCreationFailedError extends Error {}
export class AMTInitializationError extends Error {}

export const invokeCredentialCreation = async (
  amtInitializedCallback: () => void = () => {},
  skipDataReentry = false,
  profileCreatedSessionId?: string // Not required it appears to be - window.KeyAmtTkt.getSessionId() is used.
) => {
  console.log('Inside invokeCredentialCreation');
  try {
    const authToken = getAuthTokenFromUrl();
    const applicationTokenResponse: CheckApplicationTokenResponse =
      await checkApplicationToken(authToken);
    console.log('Response of checkApplicationToken', applicationTokenResponse);
    const { applicationId, productCode } = applicationTokenResponse;
    const originationId = parseOriginationId(applicationId!, productCode!);
    if (productCode) {
      store.dispatch(updateApplication({ key: 'productCode', value: productCode }));
      store.dispatch(setProductText(productCode));
    }
    sendOaoReturnEvent(applicationId!, productCode!);
    amtInitializedCallback();
    //Need a small interval so react can render the amt container
    await new Promise(r => setTimeout(r, 1));

    if (!skipDataReentry) {
      const initializationSucceeded = await initializeAMT();
      if (!initializationSucceeded) {
        throw new AMTInitializationError();
      }

      console.log('Before Authorization with AMT');
      const authorizationSucceeded = await authorizeWithAMT(originationId!);
      if (!authorizationSucceeded) {
        console.log('No success in authorizeWithAMT');
        throw new AMTAuthorizationFailedError();
      }
    }
    console.log('Before invokeAMTCredentialCreation');
    const credentialCreationResult = await invokeAMTCredentialCreation(
      originationId!,
      skipDataReentry,
      profileCreatedSessionId
    );
    if (!credentialCreationResult) {
      console.log('No success in invokeAMTCredentialCreation');
      throw new AMTCredentialCreationFailedError();
    }
  } catch (e) {
    throw e;
  }
};
