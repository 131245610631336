import React, { FC } from 'react';
import { NavigationBar } from '@laurelroad/lrds-react';
import './oao-navigation-bar.scss';
import SavingsTooltip from '../../BundleModal/BundleTooltip/SavingsTooltip';
import { useNavigationLinks } from './hooks/useNavigationLinks';
import { useProductText } from '../../../hooks/useUiState';

const OaoNavigationBar: FC = () => {
  const navTitle = useProductText().headerTitle;
  const links = useNavigationLinks();
  return (
    <>
      <SavingsTooltip />
      <NavigationBar subheader={navTitle} links={links} />
    </>
  );
};

export default OaoNavigationBar;
