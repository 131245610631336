import { useProfileInfo } from "./useProfileInfo";
import { useMemo } from "react";
import { validateProfileInfo } from "../utils";
import { useAddressInfo } from "../Address/hooks";

export const useIsValidProfileInfo = () => {
  const profileInfo = useProfileInfo();
  const { serviceErrors } = useAddressInfo();
  return useMemo(
    () => validateProfileInfo(profileInfo) && !serviceErrors?.zipCode,
    [profileInfo, serviceErrors]
  );
};
