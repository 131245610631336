import { EmployeeStatus, EMPLOYMENT_STATUSES } from "./EmployeeStatuses";
import { OCCUPATION_CLASSIFICATIONS } from "./OccupationClassifications";

interface EmployeeStatusOption {
  text: string;
  id: EmployeeStatus;
}
export const EMPLOYMENT_STATUS_OPTIONS: EmployeeStatusOption[] = Object.keys(
  EMPLOYMENT_STATUSES
).map((statusName) => ({
  text: statusName,
  id: EMPLOYMENT_STATUSES[statusName],
}));

interface OccupationClassificationOption {
  text: string;
  id: string;
}

export const OCCUPATION_CLASSIFICATION_OPTIONS: OccupationClassificationOption[] = OCCUPATION_CLASSIFICATIONS.map( ({text, id}) => {return {text, id}})

export const mapSelectOptionToId = (o: { id: string }) => o.id;
