import Joi from "joi";

export const SsnSchema = Joi.string()
  .length(9)
  .regex(/^\d{9}$/)
  .messages({
    "string.empty": "Social Security Number is required",
    "string.length": "Please enter your 9-digit Social Security Number",
    "string.ref": "Please enter your 9-digit Social Security Number",
    "string.pattern.base": "Please enter your 9-digit Social Security Number",
  })
  .required();
