export enum ProductCode {
  BUNDLED_SAVINGS = 'LRLBS',
  SAVINGS = 'LRLHYS',
  CHECKING = 'LRLCH',
  BUNDLE_CHECKING = 'LRLBCH',
  LOYALTY_CHECKING = 'LRLLCH',
  PSLF_CHECKING = 'LRLPCH',
  IDR_CHECKING = 'LRLICH',
  BUNDLE_PSLF_CHECKING = 'LRLBPCH',
  BUNDLE_IDR_CHECKING = 'LRLBICH',
}
