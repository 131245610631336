import { useSelector } from "react-redux";
import { EducationState } from "./types";
import { AppState } from "../../../store";

export const useEducationInfo = () => {
  return useSelector<AppState, EducationState>(({ application }) => {
    const { degree, degreeType } = application;
    return {
      degree,
      degreeType,
    };
  });
};
