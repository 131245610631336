import React, { FC, memo } from "react";
import AcknowledgementRow from "./AcknowledgementRow/AcknowledgementRow";
import "./acknowledgement-list.scss";
import { AcknowledgmentListProps, BundleAcknowledgments } from "../../types";
import { useApplicationInfo } from "../../../ConfirmationPage/hooks";
import { useBundleInfo } from "../../../BundleModal/hook/useBundleInfo";
import { useConfirmationEventData } from "../../../ConfirmationPage/hooks/useConfirmationEventData";
import AcknowledgmentHeader from "./AcknowledgmentHeader/AcknowledgmentHeader";
import { Optional } from "@laurelroad/lrds-react";
import { groupAcknowledgmentsByProductCode } from "./groupAcknowledgmentsByProductCode";
import { productTextSupplier } from "../../../../constants/constants";

const AcknowledgementBundleList: FC<AcknowledgmentListProps> = ({
  acknowledgements,
  selectHandler,
  selectedAcknowledgement,
}) => {
  const application = useApplicationInfo();
  const confirmationEventData = useConfirmationEventData();
  const primaryProductCode =
    application.productCode || confirmationEventData.productCode;

  const bundleInfo = useBundleInfo();
  const bundleProductCodes = bundleInfo.productCodes;

  let bundleAcknowledgements: BundleAcknowledgments =
    groupAcknowledgmentsByProductCode(acknowledgements, [
      primaryProductCode,
      ...bundleProductCodes,
    ]);

  let acknowledgementsView: JSX.Element[] = [];
  Object.keys(bundleAcknowledgements).forEach((productCode) =>
    bundleAcknowledgements[productCode].forEach((acknowledgement, index) =>
      acknowledgementsView.push(
        <>
          <Optional renderIf={index === 0}>
            <AcknowledgmentHeader
              header={productTextSupplier[productCode].disclosureSubHeader}
            />
          </Optional>
          <AcknowledgementRow
            acknowledgement={acknowledgement}
            key={index}
            onClick={selectHandler}
            isActiveRow={
              acknowledgement?.file === selectedAcknowledgement?.file
            }
          />
        </>
      )
    )
  );
  return <>{acknowledgementsView}</>;
};

export default memo(AcknowledgementBundleList);
