export enum EmployeeStatus {
  HOURLY = "EMPSTAT004",
  MANAGER = "EMPSTAT006",
  RETIRED = "EMPSTAT003",
  SALARY_NON_MANAGER = "EMPSTAT005",
  SELF_EMPLOYED = "EMPSTAT007",
  STUDENT = "EMPSTAT001",
  UNEMPLOYED = "EMPSTAT002",
  BUSINESS_OWNER = "EMPSTAT008"
}

export const NON_EMPLOYED_EMPLOYMENT_STATUSES = [
  EmployeeStatus.UNEMPLOYED,
  EmployeeStatus.STUDENT,
  EmployeeStatus.RETIRED,
];

export const EMPLOYED_EMPLOYMENT_STATUSES = [
  EmployeeStatus.HOURLY,
  EmployeeStatus.MANAGER,
  EmployeeStatus.SALARY_NON_MANAGER,
  EmployeeStatus.SELF_EMPLOYED,
];

export const EMPLOYMENT_STATUSES: { [statusName: string]: EmployeeStatus } = {
  Hourly: EmployeeStatus.HOURLY,
  "Self-Employed": EmployeeStatus.SELF_EMPLOYED,
  "Manager or Executive": EmployeeStatus.MANAGER,
  "Salary Non-Manager": EmployeeStatus.SALARY_NON_MANAGER,
  Unemployed: EmployeeStatus.UNEMPLOYED,
  Student: EmployeeStatus.STUDENT,
  Retired: EmployeeStatus.RETIRED,
  "Business Owner/Partner": EmployeeStatus.BUSINESS_OWNER
};
