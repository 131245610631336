import { useCallback } from "react";
import { sanitizeProfileInfo } from "../utils";
import { useProfileCreationAction, useProfileInfo } from "./index";
import { getZipCodeInfo } from "../asyncActions";
import { useDispatch } from "react-redux";

export const useContinueButtonHandler = () => {
  const dispatch = useDispatch();
  const profileCreationAction = useProfileCreationAction();
  const profileInfo = useProfileInfo();
  return useCallback(() => {
    const sanitizedProfileInfo = sanitizeProfileInfo(profileInfo);
    dispatch(profileCreationAction(sanitizedProfileInfo));
    dispatch(getZipCodeInfo(sanitizedProfileInfo.zipCode));
  }, [dispatch, profileInfo, profileCreationAction]);
};
