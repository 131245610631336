import Joi from "joi";
import { NO_DEGREE } from "../NoDegree";
import { ASSOCIATES_DEGREES } from "../AssociatesDegrees";
import { BACHELORS_DEGREES } from "../BachelorsDegrees";
import { MASTERS_DEGREES } from "../MastersDegrees";
import { DOCTORATE_DEGREES } from "../DoctorateDegrees";

export const DegreeTypeSchema = Joi.any()
  .valid(
    ...NO_DEGREE,
    ...ASSOCIATES_DEGREES,
    ...BACHELORS_DEGREES,
    ...MASTERS_DEGREES,
    ...DOCTORATE_DEGREES
  )
  .required()
  .messages({
    "any.only": "Degree Type is required",
  });
