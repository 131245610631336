import { MultiSelectOption } from "../MultiSelect/types";
import { CountryCode, CountryName } from "./Citizenship/types";
import {
  ExpectedSourceOfOngoingFundingId,
  ExpectedSourceOfOngoingFundingName,
} from "./SourcesOfOngoingAccountFunding/types";

export interface PersonalInfo {
  governmentIdType: string;
  governmentIdLocation: string;
  governmentIdNumber: string;
  issueDate?: string;
  expiryDate?: string;
  primaryIncomeSource: string;
  otherOrNoIncomeExplanation: string;
  degree: string;
  degreeType: string;
  employmentStatus: string;
  employerName: string;
  employerIndustry: string;
  occupationClassification: string;
  expectedSourcesOfOngoingAccountFunding: Array<
    MultiSelectOption<
      ExpectedSourceOfOngoingFundingId,
      ExpectedSourceOfOngoingFundingName
    >
  >;
  citizenshipStatus: string;
  citizenshipCountries: Array<MultiSelectOption<CountryCode, CountryName>>;
  residencyCountries: Array<MultiSelectOption<CountryCode, CountryName>>;
  foreignGovernmentDisclosure: string;
  slosRedirectUrl: null | string;
  loginUser: boolean;
  accountPurpose?: string;
  employerReferralCode?: string;
}

export enum ModalStatus {
  NOT_SHOWN,
  LOADING,
  AML_MODAL,
}

export interface PersonalInfoViewState {
  personalInfoSubmitted: boolean;
  modalStatus: ModalStatus;
  personalFieldErrors: InputFieldError[];
  showFieldErrors: boolean;
}

export interface InputFieldError {
  key: (string | number)[];
  message: string;
}

export interface PersonalInfoUpdatePayload {
  key: string;
  value: string | boolean;
}
