import "../styles.scss";
import React, { FC } from "react";

const SavingsModalFooter: FC = () => {
  return (
    <div className="bundle-modal__footer">
      <h6>
        <a
          target="_blank"
          href="https://www.laurelroad.com/high-yield-savings-account/#disclaimers"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
      </h6>
      <ol>
        <li>
          The variable Annual Percentage Yield (APY) indicated is accurate today
          only and is subject to change before or after account opening. No
          minimum balance is required to earn the APY. Fees may reduce earnings
          on the account.{" "}
          <a
            href="https://www.laurelroad.com/high-yield-savings-account/#rates-fees-jump"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here for current rates.
          </a>
        </li>
        <li>
          Deposits are insured up to $250,000. Laurel Road is a brand of KeyBank
          N.A. KeyBank is Member FDIC. To learn more, contact the FDIC toll-free
          at 1.877.ASK.FDIC (1.877.275.3342) or visit{" "}
          <a href="https://www.fdic.gov" target="_blank">
            www.fdic.gov.{" "}
          </a>
        </li>
      </ol>
    </div>
  );
};

SavingsModalFooter.defaultProps = {};

export default SavingsModalFooter;
