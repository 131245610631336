import { useEffect } from "react";
import { ApplicationCreatedStatus } from "../../../types/ApplicationCreatedStatus";
import { useBundleInfo } from "../../BundleModal/hook/useBundleInfo";
import { useSendPasInfo } from "../../ProfileCreationPage/hooks";

export const useSendMultiproductPasInfo = () => {
  const { createdApplications, createdApplicationsStatus } = useBundleInfo();
  const sendPasInfo = useSendPasInfo();
  useEffect(() => {
    if (createdApplicationsStatus == ApplicationCreatedStatus.Complete) {
      createdApplications.forEach((app) => {
        sendPasInfo(app.id);
      });
    }
  }, [createdApplications, createdApplicationsStatus]);
};
