import { AccountCreationResponse, AccountStatus } from '../AccountCreation/types';

export const hasDuplicateAccountStatus = (accounts: AccountCreationResponse[]) => {
  const map = new Map<AccountStatus, number>();
  return accounts.some(item => {
    return (
      (map
        .set(item.accountStatus, (map.get(item.accountStatus) || 0) + 1)
        .get(item.accountStatus) || 0) > 1
    );
  });
};
