import { DisclosureModalStatus, DisclosuresSubmitStatus } from "../types";
import { useIsBundled } from "../../../hooks/useIsBundled";
import { useMemo } from "react";
import { useDisclosureInfo } from "./useDisclosureInfo";
import { useApplicationInfo } from "../../ConfirmationPage/hooks";
import { useAMTInfo } from "../../AMT/hooks";
import { AMTProspectCreationStatus } from "../../AMT/types";
import { useAreMultiProductCallsComplete } from "../../../services/multiProduct/hooks/useAreMultiProductCallsComplete";

export const useDisclosureModalStatus = (): DisclosureModalStatus => {
  const { disclosuresSubmitStatus, token, loginComplete } = useDisclosureInfo();
  const { loginUser } = useApplicationInfo();
  const isBundled = useIsBundled();
  const { prospectCreationStatus } = useAMTInfo();
  const areMultiProductNetworkCallsComplete = useAreMultiProductCallsComplete();
  return useMemo(() => {
    if (disclosuresSubmitStatus === DisclosuresSubmitStatus.Running  ) {
      return DisclosureModalStatus.SubmitDisclosures;
    }

    if (disclosuresSubmitStatus === DisclosuresSubmitStatus.Passed) {
      if ( !areMultiProductNetworkCallsComplete ) {
        return DisclosureModalStatus.SubmitDisclosures
      }
      if (isBundled) {
        return DisclosureModalStatus.AccountCreation;
      } else if (loginComplete) {
        return DisclosureModalStatus.RedirectToFinishApp;
      } else if (!loginUser && token) {
        return DisclosureModalStatus.CreateCredentials;
      } else if (loginUser) {
        if (prospectCreationStatus === AMTProspectCreationStatus.LoginUser) {
          return DisclosureModalStatus.RedirectToIbxLogin;
        }
        return DisclosureModalStatus.ConfirmationPending;
      } else {
        return DisclosureModalStatus.ConfirmationPending;
      }
    }
    return DisclosureModalStatus.NotShown;
  }, [
    disclosuresSubmitStatus,
    isBundled,
    loginUser,
    token,
    prospectCreationStatus,
    loginComplete,
    areMultiProductNetworkCallsComplete
  ]);
};
