import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { SocialSecurityNumberState } from "./types";

export const useSocialSecurityNumberInfo = () =>
  useSelector<AppState, SocialSecurityNumberState>(({ application }) => {
    const {
      socialSecurityNumber,
      socialSecurityNumberConfirmation,
    } = application;
    return {
      socialSecurityNumber,
      socialSecurityNumberConfirmation,
    };
  });
