import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postMultiproductInfo, postMultiproductPrefix, postPasInfo } from "../../components/ProfileCreationPage/asyncActions";

type NetworkStatusValues  = "idle" | "running" | "complete";
export interface MultiProductNetworkCallsState {
    create: NetworkStatusValues; 
    sendPasInfo: NetworkStatusValues;
}
export const initialState: MultiProductNetworkCallsState = {
    create: "idle",
    sendPasInfo: "idle",
}
const multiProductNetworkCallsSlice = createSlice({
    name: "multiProductEndpoints",
    initialState,
    reducers: {
        initiate: (
            state: MultiProductNetworkCallsState,
            action: PayloadAction<NetworkStatusValues>
        ) => {
            return { ...state,create: action.payload, sendPasInfo: action.payload}
        }
    },
    extraReducers(builder) {
        builder.addCase(postMultiproductInfo.pending, (state: MultiProductNetworkCallsState) => {
            return {
                ...state,
                create: "running"
            }
        })
        builder.addCase(postMultiproductInfo.fulfilled, (state: MultiProductNetworkCallsState) => {
            return {
                ...state,
                create: "complete"
            }
        })
        builder.addCase(postPasInfo.pending, (state: MultiProductNetworkCallsState) => {
            return {
                ...state,
                sendPasInfo: "running"
            }
        })
        builder.addCase(postPasInfo.fulfilled, (state: MultiProductNetworkCallsState) => {
            return {
                ...state,
                sendPasInfo: "complete"
            }
        })       
    },
});

export const MultiProductNetworkCallsReducer = multiProductNetworkCallsSlice.reducer;
export const {
    initiate,
} = multiProductNetworkCallsSlice.actions