import { Button } from "@laurelroad/lrds-react";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import HYSBundleBanner from "../../../assets/HYS_bundle_banner.png";
import moneyIcon from "../../../assets/money-icon.svg";
import piggyIcon from "../../../assets/piggy-icon.svg";
import walletIcon from "../../../assets/wallet-icon.svg";
import { ProductCode } from "../../../constants/ProductCode";
import SavingsModalFooter from "./SavingsModalFooter";
import "../styles.scss";
import { hideBundleModal, optInHandler } from "../slice";
import { useDispatch } from "react-redux";
import { themeColors } from "@laurelroad/lrds-react";
import { getRequest } from "../../../utils/crud";
import { APYResponse } from "../types";
import { initiate } from "../../../services/multiProduct/MultiProductNetworkCallsSlice";


export const SavingsBundleModal: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [apyInterest, setApyInterest] = useState("0");

  const optInButtonHandler = (bundleProductCode: string) => {
    dispatch(hideBundleModal());
    dispatch(initiate("running"))
    dispatch(optInHandler(bundleProductCode));
  };
  const optOutButtonHandler = () => {
    dispatch(initiate("idle"))
    dispatch(hideBundleModal());
  };

  let apyUrl =
    window.cmsApiUrl ||
    "https://cms.lr.pr.gcp.keybank.com/cms/public/api/v1/rates-summary";

  const executeFetchAPYRate = useCallback(async () => {
    return await getRequest<APYResponse>(apyUrl);
  }, [apyUrl]);

  useEffect(() => {
    executeFetchAPYRate().then((value) => {
      const savingsId = value.highYieldSavingsProductId;
      const interestNumber = Number(
        value.productRates[savingsId].tiers[0].apyInterest
      );
      const interestDecimalThree = interestNumber.toFixed(3);
      setApyInterest(
        interestDecimalThree.endsWith("0")
          ? interestNumber.toFixed(2)
          : interestDecimalThree
      );
    });
  }, [executeFetchAPYRate]);
  return (
    <>
      <img
        className="bundle-modal__banner-image"
        alt={`Banner Add a LaurelRoad High Yield Savings Account. Earn ${apyInterest}% Annual Percentage Yield - 10 times the nation average`}
        src={HYSBundleBanner}
      />
      <div className="bundle-modal__savings-text-container">
        <p style={{ color: themeColors.pending }}>While we have you here...</p>
        <br />
        <p style={{ color: "white" }}>
          Add a Laurel Road High Yield Savings <sup>®</sup>
        </p>
        <br />
        <p style={{ color: themeColors.pending }}>
          {`Earn ${apyInterest}% Annual Percentage Yield (APY)¹ -`}{" "}
        </p>
        <p style={{ color: themeColors.pending }}>with $0 costs to open.</p>
      </div>
      <div className="bundle-modal__icon-container">
        <div className="bundle-modal__icon">
          {" "}
          <img alt="yellow piggy bank" src={piggyIcon} />
          <p>
            {`${apyInterest}% APY on your`} <br /> entire balance
          </p>
        </div>
        <div className="bundle-modal__icon">
          {" "}
          <img alt="yellow dollar bill outline" src={moneyIcon} />
          <p>
            Deposits are <br /> FDIC-insured²
          </p>
        </div>
        <div className="bundle-modal__icon">
          {" "}
          <img alt="outline of a yellow wallet" src={walletIcon} />
          <p>
            $0 monthly account <br /> maintenance fees
          </p>
        </div>
      </div>
      <div className="bundle-modal__button-container">
        <Button
          type="secondary"
          onClick={() => {
            optOutButtonHandler();
          }}
        >
          No Thanks
        </Button>
        <Button
          onClick={() => {
            optInButtonHandler(ProductCode.SAVINGS);
          }}
        >
          Yes Please
        </Button>
      </div>
      <SavingsModalFooter />
    </>
  );
};
