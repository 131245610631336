import "./index.scss";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import Router from "./components/Router/router";
import { onAppLoadAction } from "./store/actions";

// Needed to prevent leaving this page dialog from appearing
// when routing to different page
window.onbeforeunload = () => {};

store.dispatch(onAppLoadAction());

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
