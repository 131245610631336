import React, { ChangeEventHandler, FC, useCallback, useState } from "react";
import { Column, TextField } from "@laurelroad/lrds-react";
import { useDispatch } from "react-redux";
import { updateTextField } from "../../../utils/applicationDispatchFunctions";
import { useProspectEmail } from "./hooks";
import { useProfileInfoErrorMessages } from "../hooks/useProfileInfoErrorMessages";
import { validateProfileFields } from "../slice";
import { useProfileInfo } from "../hooks";

const ProspectEmail: FC = () => {
  const dispatch = useDispatch();
  const profileInfo = useProfileInfo();
  const email = useProspectEmail();

  const blurHandler = () => {
    dispatch(validateProfileFields(profileInfo));
  };

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        dispatch(updateTextField(event));
      },
      [dispatch]
    );
  return (
    <Column size={4}>
      <TextField
        id={"email"}
        name={"email"}
        className="high-yield-savings__form-field"
        value={email}
        onChange={textFieldChangeHandler}
        onBlur={blurHandler}
        label={"Email Address"}
        error={useProfileInfoErrorMessages("email")}
        required={true}
      />
    </Column>
  );
};

export default ProspectEmail;
