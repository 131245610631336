import { Button } from "@laurelroad/lrds-react";
import React, { FC, memo } from "react";
import { useDispatch } from "react-redux";
import { ElectronicDisclosureButtonsProps } from "../../types";
import { updateApplication } from "../../../../store/state/applicationSlice";
import "./ElectronicDisclosureModal.scss";

const ElectronicDisclosureButtons: FC<ElectronicDisclosureButtonsProps> = ({
  modalHandler,
}) => {
  const dispatch = useDispatch();
  const agreeButtonHandler = () => {
    modalHandler(false);
    dispatch(
      updateApplication({
        key: "electronicCommunicationDisclosureAgreement",
        value: true,
      })
    );
  };
  const closeButtonHandler = () => {
    modalHandler(false);
  };
  return (
    <div className="electronic-disclosure__button-container lrds-disclaimer__buttons-container">
      <Button type="primary" onClick={agreeButtonHandler}>
        Agree
      </Button>
      <Button type="secondary" onClick={closeButtonHandler}>
        Close
      </Button>
    </div>
  );
};
export default memo(ElectronicDisclosureButtons);
