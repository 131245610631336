import { MultiproductUpdatedResponse } from "../ProfileCreationPage/types";

export interface AmlResponse {
  amlPassed: boolean;
  productCode: string;
}

export interface ObsResponse {
  depositDecision: string;
  obpPartyId: string;
  accountNumber: string | null;
  routingNumber: string | null;
  productCode: string;
  applicationId: number;
  aoxSubmissionId: string;
  accountCreated: boolean;
}

export enum AccountStatus {
  Pending,
  Referred,
  Approved,
  Denied,
}

export interface AccountCreationState {
  accountCreation: AccountCreationResponse[];
}


export interface AccountCreationResponse {
  accountStatus: AccountStatus;
  routingNumber?: string;
  accountNumber?: string;
  obpPartyId?: string;
  productCode: string;
  applicationId?: number;
  aoxSubmissionId?: string;
  accountCreated: boolean;
}

export interface MultiproductProductCodesResponse
  extends MultiproductUpdatedResponse {}
