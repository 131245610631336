import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { NetworkState, State } from "../components/Network/types";

const useNetworkState = (): NetworkState => {
  return useSelector<State, NetworkState>(({ network }) => network, isEqual);
};

export default useNetworkState;


