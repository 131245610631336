import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { usePersonalInfo } from "./usePersonalInfo";
import { convertToPersonalInfoWithFormattedDates } from "../utils";
import { submitPersonalInfo } from "../asyncActions";

export const useSubmitPersonalInfoCallback = () => {
  const dispatch = useDispatch();
  const personalInfo = usePersonalInfo();
  return useCallback(() => {
    dispatch(
      submitPersonalInfo(convertToPersonalInfoWithFormattedDates(personalInfo))
    );
  }, [dispatch, personalInfo]);
};
