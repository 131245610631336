import { useEffect } from "react"
import { AccountStatus } from "../../components/AccountCreation/types";
import { useConfirmationInfo } from "../../components/ConfirmationPage/hooks";
import { CONFIRMATION_COMPLETE_EVENT, CONFIRMATION_DENIED_EVENT, CONFIRMATION_PENDING_EVENT, CONFIRMATION_REFERRED_EVENT } from "./events";
import { sendToGTM } from "./sendToGTM";

export const useSendMultiproductConfirmationEvent = () => {
    const { accountCreation } = useConfirmationInfo();

    const accountStatusToEventName = {
        [AccountStatus.Approved] : CONFIRMATION_COMPLETE_EVENT,
        [AccountStatus.Denied]: CONFIRMATION_DENIED_EVENT,
        [AccountStatus.Pending]: CONFIRMATION_PENDING_EVENT,
        [AccountStatus.Referred]: CONFIRMATION_REFERRED_EVENT
    };

    useEffect( () => {
        if( accountCreation instanceof Array ) {
           const gtmEvents  = accountCreation.map( ({ applicationId, obpPartyId, productCode, accountStatus, aoxSubmissionId }) => {
                return {
                    event: accountStatusToEventName[accountStatus],
                    applicationId,
                    obpPartyId,
                    productCode,
                    //only add property if we are in complete event
                    ...(CONFIRMATION_COMPLETE_EVENT === accountStatusToEventName[accountStatus] && {aoxSubmissionId: aoxSubmissionId})
                }
           })
           gtmEvents.forEach( gtmEvent => sendToGTM(gtmEvent) );
        }
    });
}