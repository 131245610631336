import React, { FC, memo } from 'react';
import { useDisclosureInfo } from '../hooks';
import { useHistory } from 'react-router-dom';
import { CREATE_CREDENTIALS_ENDPOINT } from '../../CreateCredentials/constants';
import { Button, DialogModal } from '@laurelroad/lrds-react';
import './create-credentials-redirect-modal.scss';

const CreateCredentialsRedirectModal: FC = () => {
  const { token } = useDisclosureInfo();
  const history = useHistory();
  const redirectToCredentialCreation = () => {
    const createCredentialsRoute = `${CREATE_CREDENTIALS_ENDPOINT}?authToken=${token}`;
    history.push(createCredentialsRoute);
  };
  return (
    <DialogModal isOpen={true} className={'create-credentials-redirect'}>
      <div className={'create-credentials-redirect__modal'}>
        <h4>
          <strong>Just a few more steps in your application.</strong>
        </h4>
        <h6>
          Before you see your application decision, we’ll need to first verify your identity.
          Then you’ll be asked to create a User ID and password which you’ll use to access
          Laurel Road online banking and the mobile banking app.
        </h6>
        <Button onClick={redirectToCredentialCreation}>Create Credentials</Button>
      </div>
    </DialogModal>
  );
};

export default memo(CreateCredentialsRedirectModal);
