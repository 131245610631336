import { AccountCreationResponse, AccountCreationState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: AccountCreationState = {
  accountCreation: [],
};

const accountCreationSlice = createSlice({
  name: 'accountCreation',
  initialState,
  reducers: {
    updateAccountCreationState: (
      state: AccountCreationState,
      { payload }: PayloadAction<AccountCreationResponse[]>
    ) => {
      return {
        accountCreation: payload,
      };
    },
  },
});

export const { reducer: AccountCreationReducer } = accountCreationSlice;
export const { updateAccountCreationState } = accountCreationSlice.actions;
