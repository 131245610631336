import React, { FC } from 'react';
import { Paragraph } from '@laurelroad/lrds-react';

const CheckingAccountNotCreated: FC = () => {
  return (
    <>
      {' '}
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Account
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Please bear with us while we finish setting up your account. You’ll receive an email
        confirming your account opening in a few hours. Then, you’ll be able to log into our
        Online Banking system with the User ID and password you created.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Your next step will be to set up direct deposit so you can begin earning rewards! Take
        a look at our{' '}
        <a href="https://www.laurelroad.com/direct-deposit/">Direct Deposit Guide</a> for what
        you can expect.
      </Paragraph>
    </>
  );
};
export default CheckingAccountNotCreated;
