import React, { FC } from 'react';
import { Paragraph } from '@laurelroad/lrds-react';

const PendingBody: FC<{ bundleProductNames?: string; productName: string }> = ({
  bundleProductNames,
  productName,
}) => {
  return (
    <>
      {' '}
      <Paragraph className={'hys-text secondary'}>
        Thank you for applying for a {bundleProductNames ? bundleProductNames : productName}{' '}
        account! We greatly appreciate your interest, and we&#39;ve started our review of your
        application. When we&#39;ve completed our review, we&#39;ll send an email to the
        address you provided.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        We need a little more time to review your application. As soon as we’ve finished, we’ll
        reach out by email with next steps. There’s nothing else you need to do for now, so
        feel free to close this window.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        We appreciate your patience and your interest in Laurel Road - you’ll hear from us
        within a few days!
      </Paragraph>
    </>
  );
};
export default PendingBody;
