import { AppDispatch, AppState } from "../../store";
import { hideBundleToolTip } from "../BundleModal/slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, putRequest } from "../../utils/crud";
import {
  FETCH_DISCLOSURES_ENDPOINT,
  SUBMIT_DISCLOSURES_ENDPOINT,
} from "./constants";
import { Acknowledgement, DisclosureInfoPayload } from "./types";
import { ApplicationUpdatedResponse } from "../../types/ApplicationUpdatedResponse";

import { groupAcknowledgmentsByProductCode } from "./DisclosuresBody/AcknowledgementList/groupAcknowledgmentsByProductCode";
import { postMultiproductInfo } from "../ProfileCreationPage/asyncActions";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
export const fetchDisclosuresActionPrefix = "disclosureInfo/fetchDisclosures";
export const submitDisclosuresActionPrefix = "disclosureInfo/submitDisclosures";

/*
 Reason for algorithm:
  in multiproduct scenario we want all to iterate through all of  one product then all of the next.
  payload for disclosures is only ordered by display order, so selecting next disclosure would result in jumping between products
  to fix:
    group disclosures based on product
    flatten that array
*/
export const executeFetchMultiproductDisclosures = async (
  payload: void,
  { getState }: BaseThunkAPI<AppState, unknown, AppDispatch>
) => {
  const disclosures = await getRequest<Acknowledgement[]>(
    `${FETCH_DISCLOSURES_ENDPOINT}/multiproduct`
  );
  const productCodes = [
    getState().application.productCode,
    ...getState().bundle.productCodes,
  ];
  const bundled = groupAcknowledgmentsByProductCode(disclosures, productCodes);

  return Object.keys(bundled).reduce(
    (acc: Acknowledgement[], productCode: string) => {
      return [...acc, ...bundled[productCode]];
    },
    [] as Acknowledgement[]
  );
};

export const executeFetchDisclosures = async () =>
  await getRequest<Acknowledgement[]>(`${FETCH_DISCLOSURES_ENDPOINT}`);

type SubmitDisclosuresReturnType = ApplicationUpdatedResponse & {
  token: string;
  umg: boolean;
  loginComplete: boolean;
};
export const executeSubmitDisclosures = async (
  disclosureInfo: DisclosureInfoPayload,
  { getState, dispatch }: BaseThunkAPI<AppState, unknown, AppDispatch>
): Promise<SubmitDisclosuresReturnType> => {
  const result = await putRequest<
    DisclosureInfoPayload,
    SubmitDisclosuresReturnType
  >(SUBMIT_DISCLOSURES_ENDPOINT, disclosureInfo).catch(
    () =>
      ({
        umg: getState().disclosureInfo.isUmg || false,
        loginComplete: false,
        token: getState().disclosureInfo.token,
      } as SubmitDisclosuresReturnType)
  );

  if (getState().bundle.productCodes.length > 0) {
    dispatch(postMultiproductInfo());
  }
  dispatch(hideBundleToolTip());
  return result;
};

export const fetchDisclosures = createAsyncThunk(
  fetchDisclosuresActionPrefix,
  executeFetchDisclosures
);

export const fetchMultiproductDisclosures = createAsyncThunk<
  Acknowledgement[],
  void,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(fetchDisclosuresActionPrefix, executeFetchMultiproductDisclosures);

export const submitDisclosures = createAsyncThunk<
  SubmitDisclosuresReturnType,
  DisclosureInfoPayload,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(submitDisclosuresActionPrefix, executeSubmitDisclosures);
