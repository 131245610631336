import { useLayoutEffect } from 'react';

export const useFitToWidthViewerMode = (
  file: string,
  current: {
    listen: (listener: () => void) => void;
  }
) => {
  useLayoutEffect(() => {
    let cancelTimeOut: string | number | NodeJS.Timeout | null | undefined = null;
    let cancel: string | number | NodeJS.Timeout | null | undefined = null;
    current.listen(() => {
      if (cancel) clearInterval(cancel);
      if (cancelTimeOut) clearTimeout(cancelTimeOut);
      cancel = setInterval(() => {
        const dropDown = document.querySelector(
          '.disclosures__acknowledgement-viewer ul li > select'
        );
        if (dropDown) {
          if (cancel) clearInterval(cancel);

          (dropDown as HTMLSelectElement).dispatchEvent(
            new Event('click', { bubbles: true, cancelable: true })
          );
          setTimeout(() => {
            (dropDown.querySelector('option[value="1"]') as HTMLOptionElement)?.click();
            (dropDown as HTMLSelectElement).value = '1';
          });
          setTimeout(() => {
            (dropDown as HTMLSelectElement).dispatchEvent(
              new Event('change', { bubbles: true, cancelable: true })
            );
          });
        } else {
          const dropDownTwo = document.querySelector(
            '.disclosures__acknowledgement-viewer ul li:nth-child(8) div a'
          );
          if (dropDownTwo) {
            (dropDownTwo as HTMLAnchorElement).click();
            if (cancel) clearInterval(cancel);
            cancelTimeOut = setTimeout(() => {
              const popover = document.querySelector(
                '.disclosures__acknowledgement-viewer ul li:nth-child(8) div > div:last-child a'
              );
              if (popover) {
                (popover as HTMLAnchorElement).click();
              }
            }, 200);
          }
        }
      }, 100);
    });
    return () => {
      if (cancel) clearInterval(cancel);
      if (cancelTimeOut) clearTimeout(cancelTimeOut);
    };
  }, [file, current]);
};
