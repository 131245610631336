
import {useAMTInfo} from "../AMT/hooks";

/**
 * This hook is to conditionally check whether KeyAmt* libraries are loaded in window, before we try use them.
 * We have seen some weird race conditions in using /creat-credentials/** path to bootstrap the app and fail due to
 * the App loading the component that looks for AMT before it is present in window.
 */
export const useAmtTktLoadedLatch = () => {
  return useAMTInfo().bundleLoaded;
};
