import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppViewState, ChangeViewPayload } from "./types";

export const initialState: AppViewState = {
  currentView: "Profile Creation Page",
};

const appViewSlice = createSlice({
  name: "appView",
  initialState,
  reducers: {
    changeView: (
      state: AppViewState,
      action: PayloadAction<ChangeViewPayload>
    ) => {
      return { currentView: action.payload.view };
    },
  },
});

export const { changeView } = appViewSlice.actions;
export const { reducer: AppViewReducer } = appViewSlice;
