import { useCallback } from "react";
import { changeView } from "../../App/slice";
import { useDispatch } from "react-redux";

export const useTransitionToPersonalInfoPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(changeView({ view: "Personal Info Page" }));
  }, [dispatch]);
};
