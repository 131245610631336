import React, { FC, memo } from "react";
import {
  useAcceptAcknowledgement,
  useAcknowledgementViewed,
  useSubmitDisclosures,
  useTransitionToPersonalInfoPage,
} from "./hooks";
import DisclosuresModal from "./DisclosuresModal/DisclosuresModal";
import DisclosuresFooter from "./DisclosuresFooter/DisclosuresFooter";
import DisclosuresBody from "./DisclosuresBody/DisclosuresBody";
import "./styles.scss";
import { OAOForm } from "../OAOForm/OAOForm";
import { LrIconName } from "@laurelroad/lrds-react";
import { useSendDisclosuresEvent } from "../../services/google-tag-manager/useSendDisclosuresEvent";
import { useIsMultiproduct } from "../BundleModal/hook/useIsMultiproduct";
import DisclosuresBundleWrapper from "./DisclosureWrapper/DisclosuresBundleWrapper";
import DisclosuresWrapper from "./DisclosureWrapper/DisclosuresWrapper";
import { useSendMultiproductAppCreateEvent } from "../../services/google-tag-manager/useSendMultiproductAppCreatedEvent";
import { useSendMultiproductPasInfo } from "../App/hooks/useSendMultiProductPasInfo";

const DisclosuresPage: FC = () => {
  useAcknowledgementViewed();
  useSendDisclosuresEvent();
  useSendMultiproductAppCreateEvent();  
  useSendMultiproductPasInfo();
  const isMultiproduct = useIsMultiproduct();
  const acceptHandler = useAcceptAcknowledgement();
  const submitHandler = useSubmitDisclosures();
  const cancelHandler = useTransitionToPersonalInfoPage();

  const headerData = {
    icon: "file" as LrIconName,
    text:
      "Please review, download for your records and agree to the following additional disclosures.",
  };

  return (
    <OAOForm
      headerData={headerData}
      className={"disclosures"}
      data-testid="disclosures-page"
    >
      {isMultiproduct ? <DisclosuresBundleWrapper /> : <DisclosuresWrapper />}
      <DisclosuresModal />
      <DisclosuresBody />
      <DisclosuresFooter
        onSubmit={submitHandler}
        onCancel={cancelHandler}
        onAgree={acceptHandler}
      />
    </OAOForm>
  );
};

export default memo(DisclosuresPage);
