import Joi from "joi";
import moment from "moment";
import { isValidDateFormat } from "../../../../../utils/stringUtils";

const MINIMUM_AGE = 18;
const MINIMUM_AGE_YEARS_AGO = moment().subtract(MINIMUM_AGE, "years");

const isValidDate = (dateString: string, helpers: any) => {
  if (!dateString) {
    return helpers.error("date.base");
  }

  const givenDate = moment(dateString, "MM/DD/YYYY");

  if (!isValidDateFormat(dateString)) {
    return helpers.error("date.format");
  }

  if (!givenDate.isValid()) {
    return helpers.error("date.valid");
  }

  if (givenDate.isValid() && givenDate.isSameOrAfter(MINIMUM_AGE_YEARS_AGO)) {
    return helpers.error("date.max");
  }
};

export const DateOfBirthSchema = Joi.custom(isValidDate)
  .messages({
    "date.base": "Date of Birth is required",
    "date.max": "Applicant must be 18 or older to apply",
    "date.format": "Date must be in format MM/DD/YYYY",
    "date.valid": "Date of Birth must be a valid date",
  })
  .required();
