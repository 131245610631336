import { AccountStatus } from "../../types";
import { stringToAccountStatus } from "../../utils";
import { runAml } from "../Aml/runAml";
import { runOBS } from "../Obs/runOBS";

export class AmlFailureError extends Error {}
export class OBSDeclinedError extends Error {}

export const runAccountCreation = async () => {
  const amlResult = await runAml();
  if (!amlResult.amlPassed) {
    throw new AmlFailureError();
  }
  const obsResult = await runOBS();
  const { depositDecision } = obsResult;
  if (stringToAccountStatus(depositDecision) === AccountStatus.Denied) {
    throw new OBSDeclinedError();
  }
  return obsResult;
};
