import { useSelector } from 'react-redux';
import { ProductCode } from '../constants/ProductCode';
import { AppState } from '../store';

export const useIsBundleFreedomCheckingApplication = (optProductCode = '') => {
  return useSelector<AppState, boolean>(({ application, confirmationEventData }: AppState) => {
    const productCode =
      optProductCode || confirmationEventData.productCode || application.productCode;
    return (
      productCode === ProductCode.BUNDLE_IDR_CHECKING ||
      productCode === ProductCode.BUNDLE_PSLF_CHECKING
    );
  });
};
