import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { NetworkState, NetworkError } from "./types";

const initialState: NetworkState = {
  error: {
    status: 0,
    message: ""
  }
};

const networkSlice = createSlice<NetworkState, SliceCaseReducers<NetworkState>>({
  name: "network",
  initialState: initialState,
  reducers: {
    SetError: (state, { payload }: PayloadAction<NetworkError>) => {
      return { ...state, 
        error: {
          status: payload.status,
          message: payload.message,
          fs_ref_number: payload.fs_ref_number
      }};
    },
  },
});

export const NetworkReducer = networkSlice.reducer;
export const {
  SetError
} = networkSlice.actions;