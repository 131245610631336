import React, { FC, memo } from "react";
import { Button, Optional } from "@laurelroad/lrds-react";
import { DisclosuresFooterProps } from "./types";
import "./disclosure-footer.scss";
import { useDisclosureInfo, useDisclosureInfoValidated } from "../hooks";
import { useIsCheckingApplication } from "../../../hooks/useIsCheckingApplication";

const DisclosuresFooter: FC<DisclosuresFooterProps> = ({
  onCancel,
  onAgree,
  onSubmit
}) => {
  const { selectedAcknowledgement, acknowledgements } = useDisclosureInfo();
  const isValidated = useDisclosureInfoValidated();
  const isChecking = useIsCheckingApplication();
  const primaryOnClick = isValidated ? onSubmit : onAgree;
  const primaryTitle = isValidated ? "Agree & Submit" : "I Agree";
  const showButton = acknowledgements.find((a) => a.file === selectedAcknowledgement?.file && a.acknowledged);

  return (
    <Optional renderIf={acknowledgements.length > 0}>
      <div className={"disclosures__footer"}>
        <div className="hys-text bold">
          <Optional renderIf={isChecking && isValidated}>
            <span className={"disclosures__footer__agree-submit-message"}>
              By clicking "Agree & Submit,” you are agreeing to open a checking
              account with Laurel Road
            </span>
          </Optional>
        </div>

        <div>
          <Button type={"secondary"} onClick={onCancel}>
            Cancel
          </Button>

          <Button
            type={"primary"}
            onClick={primaryOnClick}
            isDisabled={selectedAcknowledgement?.preventContinue}
          >
            {(showButton && !isValidated) ? 'Next Step' : primaryTitle}
          </Button>
        </div>
      </div>
    </Optional>
  );
};

export default memo(DisclosuresFooter);
