import { createAsyncThunk } from '@reduxjs/toolkit';
import {InputFieldError, PersonalInfo} from './types';
import { putRequest } from '../../utils/crud';
import { PERSONAL_INFO_ENDPOINT } from './constants';
import { ApplicationUpdatedResponse } from '../../types/ApplicationUpdatedResponse';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AppDispatch, AppState } from '../../store';
import Joi from 'joi';
import { convertJoiValidationToInputErrors, sortInputFieldErrors } from '../../utils/convertJoiToSortedErrors';
import { personalInfoErrorOrder, validatePersonalInfo, validateReferralCode } from './utils';
import { setPersonalFieldErrors } from './slice';
export const submitPersonalInfoActionPrefix = 'personalInfo/submitPersonalInfo';

export const executeSubmitPersonalInfo = async (personalInfo: PersonalInfo) => {
  await putRequest<PersonalInfo, ApplicationUpdatedResponse>(
    PERSONAL_INFO_ENDPOINT,
    personalInfo
  );
};

export const submitPersonalInfo = createAsyncThunk(
  submitPersonalInfoActionPrefix,
  executeSubmitPersonalInfo
);

export const executePersonalInfoValidation =   (
  personalInfo: PersonalInfo,
  { getState, dispatch }: BaseThunkAPI<AppState, unknown, AppDispatch>
): void => {
  const { optionalFieldNames } = getState().ui;
  const { personalFieldErrors } = getState().personalInfoViewState;

  const result: Joi.ValidationResult = validatePersonalInfo(personalInfo, {
    optionalFieldNames,
  });
  let inputErrors = convertJoiValidationToInputErrors(result);

  const referallCodeError = personalFieldErrors.find(item => item.key.includes("employerReferralCode"))
  if(referallCodeError){ inputErrors.push(referallCodeError);}

  let sortedErrors = sortInputFieldErrors(inputErrors, personalInfoErrorOrder);

  dispatch(setPersonalFieldErrors(sortedErrors));
}

export const validatePersonalFields = createAsyncThunk<
  void,
  PersonalInfo,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(
  'personalInfo/validate',
  executePersonalInfoValidation
);

/**
 * This one could perhaps be implemented as a boolean field in ui slice or, if existed, a brand new validation slice.
 *
 * This implementation sets invalid code first before async call to from the server overwrites with its response. This
 * prevents the following edge case: user has all fields filled up and valid. Referral code - not valid for example - entered.
 * User then clicks on the continue button without click out. At this point, without this function, there are no errors and
 * user is allowed to continue without waiting for the server to respond. That is because on blur event fires at the same time
 * button onclick fires. The conditions are truthy for the button to continue before server responds.
 *
 * Another way to handle this is to validate referral code as part of onClick event handler of the continue button. Only when
 * valid response to allow further.
 * @param personalFieldErrors
 * @param dispatch
 */
const invalidateReferralCode = (
  personalFieldErrors: InputFieldError[],
  dispatch: AppDispatch
) => {
  if (personalFieldErrors.find(e => e.key.includes('employerReferralCode')) === undefined) {
    dispatch(
      setPersonalFieldErrors(
        sortInputFieldErrors(
          personalFieldErrors.concat({ key: ['employerReferralCode'], message: '' }),
          personalInfoErrorOrder
        )
      )
    );
  }
};
export const validateReferralCodeField = createAsyncThunk<
  void,
  string | undefined,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(
  'personalInfo/referralCode/validate',
  (
    referralCode: string | undefined,
    { getState, dispatch }: BaseThunkAPI<AppState, unknown, AppDispatch>
  ): void => {
    const { personalFieldErrors } = getState().personalInfoViewState;

    invalidateReferralCode(personalFieldErrors, dispatch);

    const nonRefErrors = personalFieldErrors.filter(
      item => !item.key.includes('employerReferralCode')
    );

    validateReferralCode(referralCode)
      .then(resp => convertJoiValidationToInputErrors(resp))
      .then(inputError => [...nonRefErrors, ...inputError])
      .then(ret => sortInputFieldErrors(ret, personalInfoErrorOrder))
      .then(sortedErrors => dispatch(setPersonalFieldErrors(sortedErrors)));
  }
);
