import React, { FC } from "react";
import {
  Column,
  Grid,
  Icon,
  Paragraph,
  themeColors,
} from "@laurelroad/lrds-react";
import confirmationPage from "../confirmationPage";
import { useSetCurrentViewConfirmationPage } from "../hooks/useSetCurrentViewConfirmaitonPage";
import { ConfirmationViewProps } from "../types";
import { createBundleProductNamesAndProductTypes } from "../createBundleProductNames";
import { useProductText } from "../../../hooks/useUiState";
import { productTextSupplier } from "../../../constants/constants";

export const ReferredView: FC<ConfirmationViewProps> = ({
  productCode,
  accounts,
}) => {
  useSetCurrentViewConfirmationPage();
  const primaryProductCodeName = useProductText().productName;
  const productName = productCode
    ? productTextSupplier[productCode].productName
    : primaryProductCodeName;
  let bundleProductNames;
  if (accounts) {
    [bundleProductNames] = createBundleProductNamesAndProductTypes(accounts);
  }
  return (
    <div
      className="high-yield-savings"
      data-testid="confirmation-referred-view"
    >
      <Grid>
        <Column size={1} />
        <Column className="high-yield-savings__confirmation" size={10}>
          <Icon
            className="high-yield-savings__confirmation__icon"
            faIconConfig={{ size: "8x" }}
            color={themeColors.pending}
            icon="folder-open"
          />
          <div className="high-yield-savings__confirmation__message">
            <span className={"hys-text h3 secondary "}>
              We need a little more time to review your application.
            </span>
          </div>
          <div className="high-yield-savings__confirmation__details">
            <Grid>
              <Column size={12}>
                <Paragraph>
                  <span className={"hys-text secondary subtitle2 bold"}>
                    Status for{" "}
                    {bundleProductNames ? bundleProductNames : productName}{" "}
                    account
                  </span>
                </Paragraph>
                <Paragraph className={"hys-text secondary"}>
                  Thank you for applying for a{" "}
                  {bundleProductNames ? bundleProductNames : productName}{" "}
                  account! We received all of your information, but we still
                  need a little more time to review your application.
                </Paragraph>
                <Paragraph className={"hys-text secondary"}>
                  When we&apos;ve finished this review, we&apos;ll send an email
                  with a decision about your application to the address you
                  provided. We appreciate your patience &mdash; you&apos;ll hear
                  from us within a few days
                </Paragraph>
              </Column>
            </Grid>
          </div>
        </Column>
        <Column size={1} />
      </Grid>
    </div>
  );
};

export default confirmationPage(ReferredView);
