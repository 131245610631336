import React, { FC, useEffect } from 'react';
import { useFinishApplication } from './useFinishApplication';
import { Modal } from '@laurelroad/lrds-react';
import './finish-application.scss';
import { fetchMultiproductProductCodes } from '../AccountCreation/asyncActions';
import { useDispatch } from 'react-redux';
import { useBundleInfo } from '../BundleModal/hook/useBundleInfo';

const FinishApplication: FC = () => {
  const component = useFinishApplication();
  const dispatch = useDispatch();
  const { replenishBundleStatus } = useBundleInfo();
  useEffect(() => {
    if (replenishBundleStatus === 'idle') {
      dispatch(fetchMultiproductProductCodes());
    }
  }, [dispatch, replenishBundleStatus]);
  return (
    <Modal className={'finish-application'} isOpen={true}>
      {component}
    </Modal>
  );
};

export default FinishApplication;
