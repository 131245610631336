import React, { FC, memo } from 'react';
import CheckingBody from './CheckingBody';
import SavingsBody from './SavingsBody';
import { Button, Column, Optional } from '@laurelroad/lrds-react';
import { useSLOSRedirectUrl } from '../../../../hooks/useSLOSRedirectUrl';
import { PERKS_OFFERS_REDIRECT_URL, LAUREL_ROAD_KEY_URL } from '../../constants';
import { useIsCheckingApplication } from '../../../../hooks/useIsCheckingApplication';
import { useIsNonBundledProduct } from '../../../../hooks/useisNonBundledProduct';
import { useIsBundled } from '../../../../hooks/useIsBundled';
import { ConfirmationViewProps } from '../../types';
import BundleBody from './BundleBody';
import { useIsFreedomAndBundleFreedomCheckingApplication } from '../../../../hooks/useIsFreedomAndBundleFreedomCheckingApplication';
import { useIsBundleFreedomCheckingApplication } from '../../../../hooks/useIsBundleFreedomChecking';

const ApprovedBody: FC<ConfirmationViewProps> = ({ productCode, accounts }) => {
  const IsNonBundleChecking = useIsNonBundledProduct();
  const isFreedomChecking = useIsFreedomAndBundleFreedomCheckingApplication(productCode);
  const isFreedomCheckingBundle = useIsBundleFreedomCheckingApplication(productCode);
  const IsBundled = useIsBundled();
  const slosRedirectUrl = useSLOSRedirectUrl();
  const isChecking = useIsCheckingApplication(productCode);
  const isMoreThanPrimary = accounts!.length > 1;

  const body = isChecking ? <CheckingBody /> : <SavingsBody />;
  const welcomeCta = IsNonBundleChecking ? 'Go to Online Banking' : 'See Perks & Offers';
  const redirecURL = IsNonBundleChecking ? LAUREL_ROAD_KEY_URL : PERKS_OFFERS_REDIRECT_URL;

  const slosRedirectButton = (url: string | null) => {
    if (url && IsBundled) {
      return (
        <Button onClick={() => (window.location.href = url)}>
          Finish Student Loan Refinance
        </Button>
      );
    }
    return <Button onClick={() => (window.location.href = redirecURL)}>{welcomeCta}</Button>;
  };

  return (
    <div>
      {isMoreThanPrimary ? <BundleBody /> : body}
      <Column
        className={
          'high-yield-savings__form-submit-container high-yield-savings__form-submit-container--confirmation'
        }
        size={12}>
        <Optional renderIf={!isFreedomChecking}>
          {slosRedirectButton(slosRedirectUrl)}
        </Optional>
        <Optional renderIf={isFreedomChecking}>
          {isFreedomCheckingBundle
            ? <Button href={LAUREL_ROAD_KEY_URL}>Go to Online Banking</Button>
            : <Button href={window.gradFinUrl}>Return to GradFin</Button>
          }
        </Optional>
      </Column>
    </div>
  );
};

export default memo(ApprovedBody);
