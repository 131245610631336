import { PUERTO_RICO, US_STATE_LIST, WASHINGTON_DC } from "./USStates";
import { COUNTRY_LIST } from "./Countries";
import { CANADIAN_PROVINCE_LIST } from "./CanadianProvinces";
import { MEXICAN_STATE_LIST } from "./MexicanStates";
import { UsState } from "../types";

export const DRIVERS_LICENSE_ID = "DLN";
export const STATE_ID = "STI";
export const PASSPORT_ID = "PAS";
export const MILITARY_ID = "MID";
export const GOVERNMENT_ID = "GID";
export const TRIBAL_ID = "TRBID";
export const CANADIAN_DRIVERS_LICENSE_ID = "CADLIC";
export const MEXICAN_DRIVERS_LICENSE_ID = "MXDLIC";

export const GOVERNMENT_ID_TYPE_TO_ID: { [name: string]: string } = {
  "US Driver's License": DRIVERS_LICENSE_ID,
  "State ID": STATE_ID,
  Passport: PASSPORT_ID,
  "Military ID": MILITARY_ID,
  "Government ID": GOVERNMENT_ID,
  "Tribal ID": TRIBAL_ID,
  "Canadian Driver's License": CANADIAN_DRIVERS_LICENSE_ID,
  "Mexican Driver's License": MEXICAN_DRIVERS_LICENSE_ID,
};

export const GOVERNMENT_ID_TYPE_OPTIONS = Object.keys(
  GOVERNMENT_ID_TYPE_TO_ID
).map((name) => ({ id: GOVERNMENT_ID_TYPE_TO_ID[name], text: name }));

const alphabeticalSort = (a: UsState, b: UsState ) => {
  return a.text.toUpperCase().localeCompare(b.text.toUpperCase());
}

export const GOVERNMENT_ID_TYPE_TO_LOCATION_SELECT_OPTIONS: {
  [idType: string]: UsState[];
} = {
  [GOVERNMENT_ID_TYPE_TO_ID["US Driver's License"]]: [...US_STATE_LIST, WASHINGTON_DC, PUERTO_RICO].sort(alphabeticalSort),
  [GOVERNMENT_ID_TYPE_TO_ID["State ID"]]: US_STATE_LIST,
  [GOVERNMENT_ID_TYPE_TO_ID["Passport"]]: COUNTRY_LIST,
  [GOVERNMENT_ID_TYPE_TO_ID["Military ID"]]: COUNTRY_LIST.filter(
    (country) => country.id === "US"
  ),
  [GOVERNMENT_ID_TYPE_TO_ID["Government ID"]]: US_STATE_LIST,
  [GOVERNMENT_ID_TYPE_TO_ID["Tribal ID"]]: US_STATE_LIST,
  [GOVERNMENT_ID_TYPE_TO_ID[
    "Canadian Driver's License"
  ]]: CANADIAN_PROVINCE_LIST,
  [GOVERNMENT_ID_TYPE_TO_ID["Mexican Driver's License"]]: MEXICAN_STATE_LIST,
};

export const mapSelectOptionToId = (o: { id: string }) => o.id;
