import Joi from "joi";

export const FETCH_DISCLOSURES_ENDPOINT = "/disclosures";
export const SUBMIT_DISCLOSURES_ENDPOINT = "/applications/disclosures";

export const DisclosureInfoSavingSchema = Joi.object({
  acknowledgements: Joi.array().items({
    acknowledged: Joi.valid(true).required(),
  }),
}).options({ allowUnknown: true });

export const DisclosureInfoCheckingSchema = Joi.object({
  overDraftProtection: Joi.string().valid("true", "false").required()
}).concat(DisclosureInfoSavingSchema)

export const DisclosureInfoLoyaltySchema = Joi.object().concat(DisclosureInfoSavingSchema)