import { UiState } from '../types';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import {
  NAVIGATION_STEPS,
  NAVIGATION_STEPS_DECLINE_CONFIRMATION,
  NAVIGATION_STEPS_PENDING_CONFIRMATION,
} from '../../components/Navigation/OaoStepper/constants';
import { stringToAccountStatus } from '../../components/AccountCreation/utils';
import { AccountStatus } from '../../components/AccountCreation/types';
import { productTextSupplier } from '../../constants/constants';

export const initialState: UiState = {
  leavingModalDestination: '',
  createCredentialsModalDestination: '',
  desktopNavigationSteps: NAVIGATION_STEPS,
  isLoading: false,
  isAuthenticated: false,
  optionalFieldNames: [],
  productText: {
    headerTitle: '',
    accountDetailsHeaderTitle: '',
    productName: '',
    disclosureSubHeader: '',
  },
};

const uiSlice = createSlice<UiState, SliceCaseReducers<UiState>>({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setLeavingModalDestination: (state, { payload }: PayloadAction<string>) => {
      return { ...state, leavingModalDestination: payload };
    },
    setCreateCredentialsModalDestination: (state, { payload }: PayloadAction<string>) => {
      return { ...state, createCredentialsModalDestination: payload };
    },
    setDesktopNavigationConfirmationSteps: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        desktopNavigationSteps: getDesktopNavigationConfirmationSteps(payload),
      };
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setAuthenticated: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isAuthenticated: payload,
    }),
    setOptionalFieldNames: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      optionalFieldNames: payload || [],
    }),
    setProductText: (state, { payload }: PayloadAction<string>) => {
      const productText = productTextSupplier[payload] || initialState.productText;
      return {
        ...state,
        productText: productText,
      };
    },
  },
});

const getDesktopNavigationConfirmationSteps = (status: string) => {
  switch (stringToAccountStatus(status)) {
    case AccountStatus.Pending:
      return NAVIGATION_STEPS_PENDING_CONFIRMATION;
    case AccountStatus.Denied:
      return NAVIGATION_STEPS_DECLINE_CONFIRMATION;
    default:
      return NAVIGATION_STEPS;
  }
};

export const uiReducer = uiSlice.reducer;
export const {
  setLeavingModalDestination,
  setDesktopNavigationConfirmationSteps,
  setLoading,
  setAuthenticated,
  setOptionalFieldNames,
  setProductText,
} = uiSlice.actions;
