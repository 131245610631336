import React, { FC, memo, useEffect, useRef } from 'react';
import { Acknowledgement } from '../../types';
import './acknowledgement-viewer.scss';
import { PDFViewer } from 'react-view-pdf';
import { useFitToWidthViewerMode } from './hooks/useFitToWidthViewerMode';

const AcknowledgementViewer: FC<{
  acknowledgement: Acknowledgement | null;
}> = ({ acknowledgement }) => {
  const file = acknowledgement ? acknowledgement.file : '';

  const listenerRef = useRef(() => {});
  const onDocLoadedRef = useRef({
    listen: (listener: () => void) => {
      listenerRef.current = listener;
    },
    publish: () => {
      listenerRef.current();
    },
  });

  useFitToWidthViewerMode(file, onDocLoadedRef.current);

  return (
    <div className={'disclosures__acknowledgement-viewer'}>
      <PDFViewer
        url={file}
        onLoadSuccess={_ => {
          onDocLoadedRef.current.publish();
        }}
      />
    </div>
  );
};

export default memo(AcknowledgementViewer);
