import { useEffect } from "react";
import { useConfirmationEventData } from "../../components/ConfirmationPage/hooks/useConfirmationEventData";
import { CONFIRMATION_PENDING_EVENT } from "./events";
import { sendToGTM } from "./sendToGTM";

export const useSendConfirmPendingEvent = () => {
  const { applicationId, productCode } = useConfirmationEventData();
  useEffect(() => {
    const hasSent = window.dataLayer.find( (i: { event: string; applicationId: number | null; }) => i.event === CONFIRMATION_PENDING_EVENT && i.applicationId === applicationId);
    if (!hasSent && productCode && applicationId) {
      sendToGTM({
        event: CONFIRMATION_PENDING_EVENT,
        applicationId,
        productCode,
      });
    }
  }, [applicationId, productCode]);
};
