import React, { FC, memo } from 'react';
import { Column, Paragraph } from '@laurelroad/lrds-react';
import SavingsInstructions from './ApprovedBodyInstructions/SavingsInstructions';

const SavingsBody: FC = () => {
  return (
    <Column size={12}>
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Fund Your New Savings Account
        </span>
      </Paragraph>
      <SavingsInstructions />
    </Column>
  );
};

export default memo(SavingsBody);
