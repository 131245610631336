import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  InputFieldError,
  ModalStatus,
  PersonalInfo,
  PersonalInfoViewState,
} from "./types";
import { submitPersonalInfo } from "./asyncActions";
import { PersonalInfoSchema } from "./constants";
import Joi from "joi";
import { personalInfoErrorOrder } from "./utils";
import { convertJoiToSortedErrors } from "../../utils/convertJoiToSortedErrors";

export const initialState: PersonalInfoViewState = {
  personalInfoSubmitted: false,
  modalStatus: ModalStatus.NOT_SHOWN,
  personalFieldErrors: [],
  showFieldErrors: false,
};

const personalInfoSlice = createSlice({
  name: "personalInfo",
  initialState,
  reducers: {
    updateModalStatus: (
      state: PersonalInfoViewState,
      action: PayloadAction<ModalStatus>
    ) => ({
      ...state,
      modalStatus: action.payload,
    }),
    updatePersonalInfoSubmitted: (
      state: PersonalInfoViewState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      personalInfoSubmitted: action.payload,
      modalStatus: ModalStatus.NOT_SHOWN,
    }),
    setPersonalFieldErrors: (state, { payload }: PayloadAction<InputFieldError[]>) => {

      return {
        ...state,
        personalFieldErrors: payload,
      };
    },
    showPersonalFieldErrors: (state) => {
      return {
        ...state,
        showFieldErrors: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      submitPersonalInfo.pending,
      (state: PersonalInfoViewState) => {
        return { ...state, modalStatus: ModalStatus.LOADING };
      }
    );
    builder.addCase(
      submitPersonalInfo.fulfilled,
      (state: PersonalInfoViewState) => {
        return { ...state, personalInfoSubmitted: true };
      }
    );
  },
});

export const {
  updateModalStatus,
  updatePersonalInfoSubmitted,
  setPersonalFieldErrors,
  showPersonalFieldErrors,
} = personalInfoSlice.actions;
export const { reducer: PersonalInfoCreationReducer } = personalInfoSlice;
