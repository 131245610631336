import React, { FC, memo } from 'react';
import '@laurelroad/lrds-styles/css/lrds.css';
import { ApplicationShell } from '@laurelroad/lrds-react';
import { useAmtTktLoadedLatch } from '../CreateCredentials/useAmtTktLoadedLatch';
interface WrapperProps {
  children: React.ReactNode;
}
const ApplicationShellWrapper: FC<WrapperProps> = props => {
  const year = new Date().getFullYear();
  const content = `Laurel Road is a brand of KeyBank, N.A.  All products are offered by KeyBank, N.A. Member FDIC. ©${year} KeyCorp® All Rights Reserved.`;
  const amtResourcesLoaded = useAmtTktLoadedLatch();
  return (
    <ApplicationShell
      navBar={{}}
      footers={{
        '/no-content': {
          content: null,
        },
        '^(/perks)$': {
          content: (
            <div>
              <p>
                <b>Eligibility:</b>
                <i> Perks! </i>
                <span>
                  are available to current Laurel Road student loan refinance customers who are
                  physicians and dentists, including interns, residents, fellows, clinical
                  professors, researchers, and managing physicians or dentists with one of the
                  following qualifying medical or dental degrees: Doctor of Medicine, Doctor of
                  Osteopathic Medicine, Doctor of Dental Surgery, Doctor of Medicine in
                  Dentistry/Doctor of Dental Medicine, Doctor of Audiology, Doctor of
                  Naturopathic Medicine, Doctor of Optometry, Doctor of Podiatry, or Doctor of
                  Veterinary Medicine.
                </span>
              </p>
              <br />
              <p>
                <b>Laurel Road Perks! - Disclaimer: </b>
                <span>
                  Participating merchants(s) and available offers may vary and are subject to
                  change without notice. By clicking on the merchant link(s) you are leaving
                  the Laurel Road website and are being connected to a third-party external
                  website that is hosted by the merchant, and in no way provided or maintained
                  by Laurel Road. We encourage you to read through and evaluate the privacy and
                  security policies of the sites you are entering. The merchants listed on this
                  website are not agents of Laurel Road and are not considered sponsors or
                  co-sponsors of Laurel Road Perks. Laurel Road’s decision to provide links to
                  the merchant sites is not an endorsement or approval of the merchant’s
                  content, products or services, and Laurel Road does not warrant the same.
                </span>
              </p>
            </div>
          ),
          padding: '30px 0',
        },
        default: {
          content,
          hasFdicLogo: true,
        },
      }}
      disableGutters>
      {amtResourcesLoaded && props.children}
    </ApplicationShell>
  );
};

export default memo(ApplicationShellWrapper);
