import React, { FC, memo } from "react";
import { MobileStepper, Stepper } from "@laurelroad/lrds-react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { MOBILE_NAV_STEPS } from "./constants";
import { useCurrentStep, useNavigationSteps } from "./hooks";
import "./styles.scss";

const OaoStepper: FC = () => {
  const isMobile = useIsMobile();
  const stepNumber = useCurrentStep();
  const NAVIGATION_STEPS = useNavigationSteps();

  return (
    <div className={"high-yield-savings__stepper"}>
      {isMobile ? (
        <MobileStepper
          steps={MOBILE_NAV_STEPS}
          activeStepNumber={stepNumber}
        />
      ) : (
        <Stepper steps={NAVIGATION_STEPS} activeStepNumber={stepNumber} />
      )}
    </div>
  );
};

export default memo(OaoStepper);
