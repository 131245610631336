import { StepConfig } from "@laurelroad/lrds-react/dist/src/components/Stepper";
import { MobileStepperStep } from "@laurelroad/lrds-react";

export const NAVIGATION_STEPS: StepConfig[] = [
  { stepNumber: 1, text: "Profile" },
  { stepNumber: 2, text: "Personal Info" },
  { stepNumber: 3, text: "Disclosures" },
  { stepNumber: 4, text: "Confirmation" },
];

const PENDING_CONFIRMATION_STEP: StepConfig = {
  stepNumber: 4,
  text: "Confirmation",
  activeIcon: { icon: "folder-open" },
};

const DECLINE_CONFIRMATION_STEP: StepConfig = {
  stepNumber: 4,
  text: "Confirmation",
  activeIcon: {
    icon: "times",
  },
  activeColor: "error",
};


export const MOBILE_NAV_STEPS: MobileStepperStep[] = NAVIGATION_STEPS.map(
  (entry) => {
    return { key: `${entry.stepNumber}`, text: `${entry.text}` };
  }
);

export const NAVIGATION_STEPS_PENDING_CONFIRMATION: StepConfig[] = NAVIGATION_STEPS.map(
  (entry) => {
    if (entry.stepNumber === DECLINE_CONFIRMATION_STEP.stepNumber)
      return {...PENDING_CONFIRMATION_STEP};
    return {...entry}
  }
);

export const NAVIGATION_STEPS_DECLINE_CONFIRMATION: StepConfig[] = NAVIGATION_STEPS.map(
  (entry) => {
    if (entry.stepNumber === DECLINE_CONFIRMATION_STEP.stepNumber)
      return {...DECLINE_CONFIRMATION_STEP};
    return {...entry};
  }
);

