import { ChangeEvent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateRadioField } from "../../../utils/applicationDispatchFunctions";
import { updateAcknowledgementPreventContinue } from "../slice";
import { useDisclosureInfo } from "./useDisclosureInfo";
import { useSelectAcknowledgement } from "./useSelectAcknowledgement";

export const useOverDraftProtectionUpdated = () => {
  const dispatch = useDispatch();
  const { selectedAcknowledgement } = useDisclosureInfo();
  const selectAcknowledgement = useSelectAcknowledgement();
  const emptyAcknowledgement = {
    acknowledged: false,
    viewed: false,
    acknowledgment: "",
    displayName: "",
    file: "",
    productCodes: [],
    preventContinue: false
  };
  const acknowledgement = selectedAcknowledgement || emptyAcknowledgement;
  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(updateRadioField(event));
      dispatch(updateAcknowledgementPreventContinue({acknowledgement, preventContinue: false}))
      selectAcknowledgement({...acknowledgement, preventContinue: false});
    },
    [acknowledgement, dispatch, selectAcknowledgement]
  );
};
