import { putRequest } from "../../../../utils/crud";
import { AmlResponse } from "../../types";
import { MP_AML_ENDPOINT } from "../../constants";
import {
  UNPROCESSABLE_ENTITY,
  SERVICE_UNAVAILABLE,
} from "../../../../utils/crud/constants";
import { AmlCallException, AmlRunError, AmlTimeoutError } from "./runAml";

export const runBundleAml = async () => {
  try {
    return await putRequest<null, AmlResponse[]>(MP_AML_ENDPOINT);
  } catch (e: any) {
    const error: AmlCallException= e;
    const { status: errorStatusCode } = error;
    if (errorStatusCode === SERVICE_UNAVAILABLE) {
      throw new AmlTimeoutError();
    }
    throw new AmlRunError();
  }
};
