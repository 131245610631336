import React, { FC, useCallback } from "react";
import { Button, Optional, Tooltip } from "@laurelroad/lrds-react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { optInHandler, optOutHandler } from "../slice";
import { ProductCode } from "../../../constants/ProductCode";
import { useBundleInfo } from "../hook/useBundleInfo";

const SavingsTooltip: FC = () => {
  const dispatch = useDispatch();
  const { showModal, showTooltip, productCodes } = useBundleInfo();
  const optInSavings = productCodes.includes(ProductCode.SAVINGS);

  const optOutButtonHandler = useCallback(() => {
    dispatch(optOutHandler(ProductCode.SAVINGS));
  }, [dispatch]);

  const optInButtonHandler = useCallback(() => {
    dispatch(optInHandler(ProductCode.SAVINGS));
  }, [dispatch]);

  const optButtonText = optInSavings
    ? "Opt out of this Offer"
    : "Opt in to this Offer";
  const optBodyText = optInSavings ? "opted in to" : "opted out of";

  const bundleToolTop = (
    <>
      <h6>Laurel Road High Yield Savings<sup>®</sup></h6>
      <p>
        You’ve {optBodyText} opening a savings account. To learn more about the
        benefits to this account,{" "}
        <a
          href="https://www.laurelroad.com/high-yield-savings-account/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          visit our website
        </a>
        .
      </p>
      <Optional renderIf={optInSavings}>
        <Button
          onClick={() => {
            optOutButtonHandler();
          }}
          type="secondary"
        >
          {optButtonText}
        </Button>
      </Optional>
      <Optional renderIf={!optInSavings}>
        <Button
          onClick={() => {
            optInButtonHandler();
          }}
          type="secondary"
        >
          {optButtonText}
        </Button>
      </Optional>
    </>
  );
  return (
    <>
      <Optional renderIf={!showModal && showTooltip}>
        <div className="bundle-tooltip">
          <Tooltip
            content={bundleToolTop}
            position="bottom-left"
            activateOnHover={false}
          >
            <Button>Your Add-Ons</Button>
          </Tooltip>
        </div>
      </Optional>
    </>
  );
};

export default SavingsTooltip;
