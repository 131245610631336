import { GovernmentIdState } from "./types";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";

export const useGovernmentIdInfo = () => {
  return useSelector<AppState, GovernmentIdState>(({ application }) => {
    const {
      governmentIdType,
      governmentIdLocation,
      governmentIdNumber,
      issueDate,
      expiryDate,
    } = application;
    return {
      governmentIdType,
      governmentIdLocation,
      governmentIdNumber,
      issueDate,
      expiryDate,
    };
  });
};
