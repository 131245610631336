import { ProductCode } from '../constants/ProductCode';

export const parseOriginationId = (id: number, productCode: string) => {
  const isFreedomCheckingAndBundleFreedomChecking =
    productCode === ProductCode.IDR_CHECKING ||
    productCode === ProductCode.PSLF_CHECKING ||
    productCode === ProductCode.BUNDLE_IDR_CHECKING ||
    productCode === ProductCode.BUNDLE_PSLF_CHECKING;
  return isFreedomCheckingAndBundleFreedomChecking ? `OAO_${id}QQ` : `OAO_${id}`;
};
