import React, { FC } from 'react';
import { Column, Grid, Icon, Optional, Paragraph, themeColors } from '@laurelroad/lrds-react';
import { useUpdateNavSteps } from '../hooks/useUpdateNavSteps';
import { useSendConfirmPendingEvent } from '../../../services/google-tag-manager/useSendConfirmPendingEvent';
import confirmationPage from '../confirmationPage';
import { useSetCurrentViewConfirmationPage } from '../hooks/useSetCurrentViewConfirmaitonPage';
import { ConfirmationViewProps } from '../types';
import { createBundleProductNamesAndProductTypes } from '../createBundleProductNames';
import { useBundleErrorInfo } from '../hooks/useBundleErrorInfo';
import { useBundleInfo } from '../../BundleModal/hook/useBundleInfo';
import { useProductText } from '../../../hooks/useUiState';
import {
  FREEDOM_CHECKING_PRODUCT_NAME,
  productTextSupplier,
} from '../../../constants/constants';
import PendingBody from './PendingBody/PendingBody';
import { useIsFreedomCheckingApplication } from '../../../hooks/useIsFreedomChecking';
import { useIsBundleFreedomCheckingApplication } from '../../../hooks/useIsBundleFreedomChecking';

export const PendingView: FC<ConfirmationViewProps> = ({ productCode, accounts }) => {
  useUpdateNavSteps('pending');
  useSendConfirmPendingEvent();
  useSetCurrentViewConfirmationPage();
  const primaryProductCodeName = useProductText().productName;
  const productName = productCode
    ? productTextSupplier[productCode].productName
    : primaryProductCodeName;
  const { productCodes } = useBundleInfo();
  let bundleProductNames;
  const { mcaPending } = useBundleErrorInfo();
  if (accounts || mcaPending) {
    [bundleProductNames] = createBundleProductNamesAndProductTypes(accounts, productCodes);
  }

  return (
    <>
      <div className="high-yield-savings" data-testid="confirmation-pending-view">
        <Grid>
          <Column size={1} />
          <Column className="high-yield-savings__confirmation" size={10}>
            <Icon
              className="high-yield-savings__confirmation__icon"
              faIconConfig={{ size: '8x' }}
              color={themeColors.pending}
              icon="folder-open"
            />
            <div className="high-yield-savings__confirmation__message">
              <span className={'hys-text h3 secondary'}>
                We&apos;re reviewing your{' '}
                {bundleProductNames ? bundleProductNames : productName} application.
              </span>
            </div>
            <div className="high-yield-savings__confirmation__details">
              <Grid>
                <Column size={12}>
                  <Optional renderIf={productName !== FREEDOM_CHECKING_PRODUCT_NAME}>
                    <PendingBody
                      bundleProductNames={bundleProductNames}
                      productName={productName}
                    />
                  </Optional>
                  <Optional renderIf={useIsFreedomCheckingApplication(productCode)}>
                    <Paragraph className={'hys-text secondary'}>
                      Thank you for applying for a Laurel Road Freedom Plus Checking℠ account -
                      the account that helps you track progress toward student loan forgiveness
                      with the convenience of a full-service digital bank.
                    </Paragraph>
                    <Paragraph className={'hys-text secondary'}>
                      We need a little more time to review your application. As soon as we’ve
                      finished, we’ll reach out by email with next steps. There’s nothing else
                      you need to do for now, so feel free to close this window.
                    </Paragraph>
                    <Paragraph className={'hys-text secondary'}>
                      We appreciate your patience and your interest in Laurel Road - you’ll
                      hear from us within a few days!
                    </Paragraph>
                  </Optional>
                  <Optional renderIf={useIsBundleFreedomCheckingApplication(productCode)}>
                    <Paragraph className={'hys-text secondary'}>
                      Thank you for applying for a Freedom Plus Checking account! We received
                      all of your information, but we still need a little more time to review
                      your application.
                    </Paragraph>
                    <Paragraph className={'hys-text secondary'}>
                      Hang tight – when we’ve finished this review, we’ll reach out by email
                      with a decision about your application. There’s nothing else you need to
                      do for now, so feel free to close this window.
                    </Paragraph>
                    <Paragraph className={'hys-text secondary'}>
                      We appreciate your patience — you’ll hear from us within a few days!
                    </Paragraph>
                  </Optional>
                </Column>
              </Grid>
            </div>
          </Column>
          <Column size={1} />
        </Grid>
      </div>
    </>
  );
};

export default confirmationPage(PendingView);
