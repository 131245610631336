import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BundleErrorState } from "../types";

export const initialState: BundleErrorState = {
  amlFailures: [],
  mcaPending: false,
  mcaDecline: false,
};

const bundleErrorSlice = createSlice({
  name: "BundleError",
  initialState,
  reducers: {
    updateAmlFailures: (
      state: BundleErrorState,
      action: PayloadAction<string>
    ) => {
      let amlFailures = [...state.amlFailures];
      amlFailures.push(action.payload);
      return {
        ...state,
        amlFailures,
      };
    },
    updateMcaPending: (
      state: BundleErrorState,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        mcaPending: action.payload,
      };
    },
    updateMcaDecline: (
      state: BundleErrorState,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        mcaDecline: action.payload,
      };
    },
  },
});

export const { reducer: BundleErrorReducer } = bundleErrorSlice;
export const {
  updateAmlFailures,
  updateMcaDecline,
  updateMcaPending,
} = bundleErrorSlice.actions;
