import Joi from "joi";
import moment from "moment";
import { isValidDateFormat } from "../../../../../utils/stringUtils";

const today = moment();

const isValidExpiryDate = (dateString: string, helpers: any) => {
  if (!dateString) {
    return helpers.error("date.base");
  }

  const givenDate = moment(dateString, "MM/DD/YYYY");

  if (!isValidDateFormat(dateString)) {
    return helpers.error("date.format");
  }

  if (!givenDate.isValid()) {
    return helpers.error("date.valid");
  }

  if (givenDate.isValid() && givenDate.isBefore(today)) {
    return helpers.error("date.min");
  }
};

export const ExpiryDateSchema = Joi.custom(isValidExpiryDate).messages({
  "date.base": "Expiry Date is required",
  "date.min": "Expiry Date must be after today's date",
  "date.format": "Date must be in format MM/DD/YYYY",
  "date.valid": "Expiry Date must be a valid date",
});
