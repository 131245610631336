import {
  SERVICE_UNAVAILABLE,
  UNPROCESSABLE_ENTITY,
} from "../../../../utils/crud/constants";
import { getRequest } from "../../../../utils/crud";
import { AML_ENDPOINT } from "../../constants";
import { AmlResponse } from "../../types";

type AmlCallExceptionStatus =
  | typeof UNPROCESSABLE_ENTITY
  | typeof SERVICE_UNAVAILABLE;

export interface AmlCallException {
  status: AmlCallExceptionStatus;
}

export class AmlTimeoutError extends Error {}
export class AmlRunError extends Error {}

export const runAml = async () => {
  try {
    return await getRequest<AmlResponse>(AML_ENDPOINT);
  } catch (e: any) {
    const error: AmlCallException = e;
    const { status: errorStatusCode } = error;
    if (errorStatusCode === SERVICE_UNAVAILABLE) {
      throw new AmlTimeoutError();
    }
    throw new AmlRunError();
  }
};
