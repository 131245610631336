import React, { FC, memo } from "react";
import { AMTProspectCreationProps } from "./types";
import { useAMTProspectCreation } from "./hooks";

const AMTProspectCreation: FC<AMTProspectCreationProps> = ({
  prospectInfo,
}) => {
  useAMTProspectCreation(prospectInfo);
  return <div id="transmitContainer" className="mt-4" />;
};

export default memo(AMTProspectCreation);
