import {
  AccountCreationResponse,
  AccountStatus,
} from "../../AccountCreation/types";
import { BundleAccounts } from "../types";

export const sortDuplicateBundleAccounts = (
  accounts: AccountCreationResponse[]
) => {
  let bundleAccounts: BundleAccounts = {
    [AccountStatus.Approved]: [],
    [AccountStatus.Denied]: [],
    [AccountStatus.Referred]: [],
    [AccountStatus.Pending]: [],
  };
  const sortedBundleAccounts = accounts.reduce<typeof bundleAccounts>(
    (bundleAccountsSorter, account) => {
      const accountStatus = account.accountStatus;
      if (accountStatus === AccountStatus.Approved) {
        bundleAccountsSorter[AccountStatus.Approved].push(account);
      }
      if (accountStatus === AccountStatus.Denied) {
        bundleAccountsSorter[AccountStatus.Denied].push(account);
      }
      if (accountStatus === AccountStatus.Pending) {
        bundleAccountsSorter[AccountStatus.Pending].push(account);
      }
      if (accountStatus === AccountStatus.Referred) {
        bundleAccountsSorter[AccountStatus.Referred].push(account);
      }
      return bundleAccountsSorter;
    },
    bundleAccounts
  );

  return sortedBundleAccounts;
};
