import { useEffect } from "react"
import { sendToGTM } from "./sendToGTM";
import { useApplicationInfo } from "../../components/ConfirmationPage/hooks";

export const useSendPersonalInfoEvent = () => {

    const{ id, productCode } = useApplicationInfo();
    useEffect( () => {
        sendToGTM({ 
            event:   "oao_personal_info",
            applicationId: id,
            productCode
        })
    }, [id, productCode]);
}