import { Column, FormField, Loader, NumberField } from '@laurelroad/lrds-react';
import React, { ChangeEventHandler, FC, FocusEventHandler, useCallback } from 'react';
import { useAddressAutoComplete, useAddressInfo } from './hooks';
import { useDispatch } from 'react-redux';
import {
  updateTextField,
  updateTextFieldValueWithUppercaseFirstLetter,
} from '../../../utils/applicationDispatchFunctions';
import { clearProfileCreationErrors, validateProfileFields } from '../slice';
import SearchableDropdown from '../../SearchableDropdown/searchableDropdown';
import { useProfileInfo } from '../hooks';
import { useProfileInfoErrorMessages } from '../hooks/useProfileInfoErrorMessages';

const Address: FC = () => {
  const dispatch = useDispatch();
  const profileInfo = useProfileInfo();
  const { streetAddress, zipCode, serviceErrors, streetAddressTwo } = useAddressInfo();

  const blurHandler: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    dispatch(validateProfileFields(profileInfo));
    dispatch(clearProfileCreationErrors());
  }, [dispatch, profileInfo]);

  const textFieldUppercaseChangeHandler: (value: any) => void = useCallback(
    (value = '') => {
      dispatch(
        updateTextFieldValueWithUppercaseFirstLetter({
          target: { value: value, name: 'streetAddress' },
        } as React.ChangeEvent<HTMLInputElement>)
      );
    },
    [dispatch]
  );

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      dispatch(updateTextField(event));
    },
    [dispatch]
  );

  const {
    predictions,
    isLoading: isAddressLoading,
    onAddressSelect,
    isLoadingAddrDetails,
  } = useAddressAutoComplete(streetAddress, textFieldUppercaseChangeHandler);

  return (
    <>
      <Column size={6}>
        <SearchableDropdown
          id={'streetAddress'}
          name={'streetAddress'}
          className="high-yield-savings__form-field"
          value={streetAddress}
          onChange={onAddressSelect}
          onInputChange={textFieldUppercaseChangeHandler}
          label={'Residential Street Address'}
          error={useProfileInfoErrorMessages('streetAddress')}
          required={true}
          items={predictions}
          onBlur={() => {}}
          isLoadingItems={isAddressLoading}
          leadingIcon={{ icon: 'search', lr: false }}
          readOnlyTextFilter={isLoadingAddrDetails}
        />
      </Column>
      <Column size={3}>
        <FormField
          label="Address Line 2"
          id={'streetAddressTwo'}
          name={'streetAddressTwo'}
          className="high-yield-savings__form-field"
          onChange={textFieldChangeHandler}
          error={useProfileInfoErrorMessages('streetAddressTwo')}
          onBlur={blurHandler}
          value={streetAddressTwo}
        />
      </Column>
      <Column size={3}>
        <NumberField
          id={'zipCode'}
          name={'zipCode'}
          className="high-yield-savings__form-field"
          value={zipCode}
          onChange={textFieldChangeHandler}
          onBlur={blurHandler}
          label={'Zip Code'}
          error={useProfileInfoErrorMessages('zipCode') || serviceErrors?.zipCode}
          required={true}
          disabled={isLoadingAddrDetails}
          showRequiredAsterisk
        />
        {isLoadingAddrDetails && <Loader size={'small'} style={{ position: 'absolute' }} />}
      </Column>
    </>
  );
};

export default Address;
