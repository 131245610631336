import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { Column, RadioGroup, themeColors } from '@laurelroad/lrds-react';
import { useDispatch } from 'react-redux';
import { updateRadioField } from '../../../utils/applicationDispatchFunctions';
import { FOREIGN_GOVERNMENT_DISCLOSURE_OPTIONS } from './constants/SelectOptions';
import { usePersonalInfoErrorMessages } from '../hooks/usePersonalInfoErrorMessages';
import { usePersonalInfo } from '../hooks';
import './styles.scss';
import { validatePersonalFields } from '../asyncActions';

const ForeignGovernmentDisclosure: FC = () => {
  const dispatch = useDispatch();
  const personalInfo = usePersonalInfo();
  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const radioGroupChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => dispatch(updateRadioField(event)),
    [dispatch]
  );
  return (
    <>
      <Column size={5} className="personal-info-creation__disclosure-question">
        Do any of the following apply to you?
        <span
          className="personal-info-creation__required-asterisk"
          style={{ color: themeColors.error }}>
          *
        </span>
      </Column>
      <ul className="personal-info-creation__foreign-government-list">
        <li>
          <span className={'hys-text caption2 primary-text valign-top'}>
            I am an immediate family member of someone who hold or has held a high position in
            a foreign government.
          </span>
        </li>
        <li>
          <span className={'hys-text caption2 primary-text valign-top'}>
            I am an accountant, attorney, business partner, oligarch and/or close associate of
            someone who holds or has held a high position in a foreign government.
          </span>
        </li>
        <li>
          <span className={'hys-text caption2 primary-text valign-top'}>
            I hold or have held a high position in a foreign government.
          </span>
        </li>
        <li>
          <span className={'hys-text caption2 primary-text valign-top'}>
            Transactions in my account will involve dealings with any of the above.
          </span>
        </li>
        <li className="no-list-style">
          <span className={'hys-text caption1 primary-text valign-top'}>Note:&nbsp;</span>
          <span className={'hys-text caption2 primary-text'}>
            Oligarch is a wealthy individual with a great deal of political influence.
          </span>
        </li>
      </ul>
      <span onBlur={blurHandler}>
        <RadioGroup
          id={'foreignGovernmentDisclosure'}
          className="lrds-grid--auto-cols"
          onChange={radioGroupChangeHandler}
          name={'foreignGovernmentDisclosure'}
          options={FOREIGN_GOVERNMENT_DISCLOSURE_OPTIONS}
          error={usePersonalInfoErrorMessages('foreignGovernmentDisclosure')}
        />
      </span>
    </>
  );
};

export default ForeignGovernmentDisclosure;
