import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  useCallback,
  useState,
} from "react";
import { Column, Optional, TextField } from "@laurelroad/lrds-react";
import SearchableDropdown from "../../SearchableDropdown/searchableDropdown";
import { useIncomeInfo, useIsOtherIncome } from "./hooks";
import { useDispatch } from "react-redux";
import { IncomeErrors } from "./types";
import {
  updateSelectField,
  updateTextField,
} from "../../../utils/applicationDispatchFunctions";
import { SelectFieldEvent } from "@laurelroad/lrds-react/dist/src/components/SelectField";
import { PRIMARY_INCOME_SOURCE_OPTIONS } from "./constants/SelectOptions";
import { ExplanationSchema } from "./constants/schemas/IncomeExplanation";
import { usePersonalInfoErrorMessages } from "../hooks/usePersonalInfoErrorMessages";
import { usePersonalInfo } from "../hooks";
import {validatePersonalFields} from "../asyncActions";

const Income: FC = () => {
  const { primaryIncomeSource, otherOrNoIncomeExplanation } = useIncomeInfo();
  const isOtherIncome = useIsOtherIncome();
  const dispatch = useDispatch();
  const personalInfo = usePersonalInfo();
  const [errors, updateErrors] = useState<IncomeErrors>({});

  const blurhandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => dispatch(updateTextField(event)), [dispatch]);

  const inputFieldBlurHandler: FocusEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const result = ExplanationSchema.validate({
          primaryIncomeSource,
          [event.target.name]: event.target.value,
        });
        if (!result?.error) {
          updateErrors({});
        } else {
          updateErrors({ [event.target.name]: result?.error?.message });
        }
      },
      [errors, updateErrors]
    );

  const searchableDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
      updateErrors({ ...errors, [event.fieldName!]: undefined });
    },
    [dispatch, updateErrors, errors]
  );

  return (
    <>
      <Column className={"col--relative"} size={3}>
        <SearchableDropdown
          className="high-yield-savings__form-field"
          id={"primaryIncomeSource"}
          name={"primaryIncomeSource"}
          label={"Select Primary Income Source"}
          items={PRIMARY_INCOME_SOURCE_OPTIONS}
          onChange={searchableDropdownChangeHandler}
          value={primaryIncomeSource}
          onBlur={blurhandler}
          error={usePersonalInfoErrorMessages("primaryIncomeSource")}
          required={true}
        />
      </Column>
      <Optional renderIf={isOtherIncome}>
        <Column size={3}>
          <TextField
            required
            className="high-yield-savings__form-field"
            value={otherOrNoIncomeExplanation}
            onChange={textFieldChangeHandler}
            name={"otherOrNoIncomeExplanation"}
            label={"Please explain your income source."}
            error={errors.otherOrNoIncomeExplanation}
            onBlur={inputFieldBlurHandler}
          />
        </Column>
      </Optional>
    </>
  );
};

export default Income;
