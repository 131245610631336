import { Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const MPBundleFreedomCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’re one step closer to stress-free student loan repayment. Now that you've finished
        enrollment, our counselors will look at your documents to review the specifics of your
        student loan scenario.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You'll receive a confirmation email shortly with information about your new checking
        account, including instructions for setting up direct deposit. Plus, with your new High
        Yield Savings account, you can fast track your savings goals at the same time!
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Look out for another email from us in the coming days once it's time to continue
        setting up your membership.
      </Paragraph>
    </>
  );
};

export default memo(MPBundleFreedomCheckingInstructions);
