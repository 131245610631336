import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import App from '../App/App';
import store from '../../store';
import { CREATE_CREDENTIALS_ROUTE } from '../CreateCredentials/constants';
import { FINISH_APPLICATION_ROUTE } from '../FinishApplication/constants';
import { OaoNavigationBar } from '../Navigation';
import CreateCredentials from '../CreateCredentials/CreateCredentials';
import AccountCreationModal from '../AccountCreation/AccountCreation';
import { ACCOUNT_CREATION_ROUTE } from '../AccountCreation/constants';
import FinishApplication from '../FinishApplication/FinishApplication';
import {
  CONFIRMATION_APPROVED_ROUTE,
  CONFIRMATION_BUNDLE_ROUTE,
  CONFIRMATION_DENIED_ROUTE,
  CONFIRMATION_PENDING_ROUTE,
  CONFIRMATION_REFERRED_ROUTE,
  CONFIRMATION_TIMEOUT_ROUTE,
} from '../ConfirmationPage/constants';
import TimeoutView from '../ConfirmationPage/TimeoutView/TimeoutView';
import ApprovedView from '../ConfirmationPage/ApprovedView/ApprovedView';
import DeniedView from '../ConfirmationPage/DeniedView/DeniedView';
import ReferredView from '../ConfirmationPage/ReferredView/ReferredView';
import PendingView from '../ConfirmationPage/PendingView/PendingView';
import ApplicationShellWrapper from '../ApplicationShellWrapper/ApplicationShellWrapper';
import BundleView from '../ConfirmationPage/BundleView/BundleView';
import { toggleAmtBundleLoaded } from '../AMT/slice';

export default function Router() {
  useEffect(() => {
    const intCancel = setInterval(() => {
      const keyCommonBundle = (window as any).KeyCommonTkt;
      const keyAmtBundle = (window as any).KeyAmtTkt;
      if (keyCommonBundle && keyAmtBundle) {
        clearInterval(intCancel);
        store.dispatch(toggleAmtBundleLoaded(true));
      }
    }, 100);
    return () => clearInterval(intCancel);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ApplicationShellWrapper>
          <Switch>
            <Route exact path={FINISH_APPLICATION_ROUTE}>
              <OaoNavigationBar />
              <FinishApplication />
            </Route>
            <Route exact path={CREATE_CREDENTIALS_ROUTE}>
              <OaoNavigationBar />
              <CreateCredentials />
            </Route>
            <Route exact path={ACCOUNT_CREATION_ROUTE}>
              <OaoNavigationBar />
              <AccountCreationModal />
            </Route>
            <Route exact path={CONFIRMATION_APPROVED_ROUTE}>
              <ApprovedView />
            </Route>
            <Route exact path={CONFIRMATION_DENIED_ROUTE}>
              <DeniedView />
            </Route>
            <Route exact path={CONFIRMATION_PENDING_ROUTE}>
              <PendingView />
            </Route>
            <Route exact path={CONFIRMATION_REFERRED_ROUTE}>
              <ReferredView />
            </Route>
            <Route exact path={CONFIRMATION_TIMEOUT_ROUTE}>
              <TimeoutView />
            </Route>
            <Route exact path={CONFIRMATION_BUNDLE_ROUTE}>
              <BundleView />
            </Route>
            <Route path={'/*'}>
              <App />
            </Route>
          </Switch>
        </ApplicationShellWrapper>
      </BrowserRouter>
    </Provider>
  );
}
