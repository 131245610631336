export const FUNDING_SOURCES: { [sourceName: string]: string } = {
  "Account to Account Transfers": "ACFND001",
  "Domestic ACH Credits": "ACFND002",
  "International ACH Credits": "ACFND003",
  "Bank by Mail Deposits" : "ACFND004",
  "Cash" : "ACFND005",
  "Check Deposits" : "ACFND006",
  "Domestic Wire Transfers" : "ACFND007", 
  "Foreign Wire Transfers" : "ACFND008",
  "None / Not Applicable" : "ACFND009",
};
