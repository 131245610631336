import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../utils/crud";
import { FETCH_CONFIRMATION_EVENT_DATA_ENDPOINT } from "./constants";
import { ConfirmationEventDataResponse } from "./types";

export const fetchConfirmationEventDataPrefix =
  "confirmationPage/fetchConfirmationEventData";

export const executeFetchConfirmationEventData = async () =>
  await getRequest<ConfirmationEventDataResponse>(
    FETCH_CONFIRMATION_EVENT_DATA_ENDPOINT
  );

export const fetchConfirmationEventData = createAsyncThunk(
  fetchConfirmationEventDataPrefix,
  executeFetchConfirmationEventData
);
