export interface Acknowledgement {
  acknowledgment: string;
  file: string;
  displayName: string;
  acknowledged: boolean;
  viewed: boolean;
  productCodes: string[] | null;
  preventContinue: boolean;
}

export enum DisclosuresSubmitStatus {
  NotRunning,
  Running,
  Passed,
  Failed,
}

export enum DisclosureModalStatus {
  NotShown,
  SubmitDisclosures,
  AccountCreation,
  CreateCredentials,
  ConfirmationPending,
  RedirectToIbxLogin,
  RedirectToFinishApp,
}

export interface DisclosureInfoState {
  acknowledgements: Acknowledgement[];
  disclosuresSubmitStatus: DisclosuresSubmitStatus;
  selectedAcknowledgement: Acknowledgement | null;
  overDraftProtection: string;
  token?: string | null;
  isUmg: boolean;
  loginComplete: boolean;
}

export interface AcknowledgementActionPayload {
  acknowledgement: Acknowledgement;
}

export interface UpdateAcknowledgemenPreventContinuePayload {
  acknowledgement: Acknowledgement;
  preventContinue: boolean;
}
export interface DisclosureInfoPayload {
  overDraftProtection: string;
}

export interface DisclosureMultiproductPayload {
  id?: number;
  productCode?: string;
}

export interface AcknowledgmentListProps {
  acknowledgements: Acknowledgement[];
  selectHandler: (acknowledgement: any) => void;
  selectedAcknowledgement: Acknowledgement | null;
}

export interface AcknowledgementHeaderProps {
  header: string;
}

export interface BundleAcknowledgments {
  [key: string]: Acknowledgement[];
}
