import { Button, Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';

const MPBundleFreedomCheckingInstructions: FC = () => {
  return (
    <>
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Accounts
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You’re one step closer to stress-free student loan repayment. Now that you've finished
        enrollment, our counselors will look at your documents to review the specifics of your
        student loan scenario.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        We need a little more time to finish setting up your new accounts. You’ll receive a
        confirmation email in a few hours with more information, including instructions for
        setting up direct deposit.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Look out for another email from us in the coming days once it's time to continue
        setting up your membership.
        <Paragraph>
          <Button href={window.gradFinUrl}>Back to Membership Portal</Button>
        </Paragraph>
      </Paragraph>
    </>
  );
};

export default memo(MPBundleFreedomCheckingInstructions);
