import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { InputFieldError } from "../types";

export const usePersonalInfoErrorMessages = (key: string | number) =>
  useSelector<AppState, string | undefined>(({ personalInfoViewState }) => {
    const errors = personalInfoViewState.personalFieldErrors;
    function isTheKey(error: InputFieldError) {
      return error.key[0] === key;
    }
    let errorMessage;
    if (personalInfoViewState.showFieldErrors) {
      const foundError = errors.find(isTheKey);
      errorMessage = foundError?.message;
    }
    return errorMessage;
  });
