import React from 'react';
import confirmationPage from '../confirmationPage';
import { useSetCurrentViewConfirmationPage } from '../hooks/useSetCurrentViewConfirmaitonPage';
import { useConfirmationInfo } from '../hooks';
import BundleViewRow from './BundleViewRow';
import { AccountCreationResponse } from '../../AccountCreation/types';
import { useBundleErrorInfo } from '../hooks/useBundleErrorInfo';
import { DeniedView } from '../DeniedView/DeniedView';
import { hasDuplicateAccountStatus } from '../hasDuplicateAccountStatus';
import { Optional } from '@laurelroad/lrds-react';
import BundleMergeRow from './BundleMergeRow';
import { useSendMultiproductConfirmationEvent } from '../../../services/google-tag-manager/useSendMultiproductConfirmationEvent';

export const BundleView = () => {
  useSendMultiproductConfirmationEvent();
  useSetCurrentViewConfirmationPage();
  const confirmationInfo = useConfirmationInfo();
  const { amlFailures } = useBundleErrorInfo();
  const info: AccountCreationResponse[] =
    confirmationInfo.accountCreation instanceof Array ? confirmationInfo.accountCreation : [];
  // Async/Split View
  let component = info.map(account => {
    return (
      <BundleViewRow
        key={`${account.routingNumber}_${account.accountNumber}`}
        productCode={account.productCode}
        accountNumber={account.accountNumber}
        routingNumber={account.routingNumber}
        accountStatus={account.accountStatus}
      />
    );
  });
  // Condensed View
  let bundleComponent = <BundleMergeRow accounts={info} amlFailures={amlFailures} />;
  let hasDuplicateBundleAccounts = hasDuplicateAccountStatus(info);

  let amlFailuresComponent = amlFailures.map(productCode => {
    return <DeniedView key={productCode} productCode={productCode} />;
  });

  return (
    <div>
      <Optional renderIf={hasDuplicateBundleAccounts}>{bundleComponent}</Optional>
      <Optional renderIf={!hasDuplicateBundleAccounts}>
        {component}
        {amlFailuresComponent.length > 0 ? amlFailuresComponent : null}
      </Optional>
    </div>
  );
};

export default confirmationPage(BundleView);
