import { useSelector } from 'react-redux';
import { ProductCode } from '../constants/ProductCode';
import { AppState } from '../store';
import { isCheckingProduct } from '../constants/constants';

export const useIsCheckingApplication = (optProductCode = '') => {
  return useSelector<AppState, boolean>(({ application, confirmationEventData }: AppState) => {
    const productCode =
      optProductCode || application.productCode || confirmationEventData.productCode;
    return isCheckingProduct(productCode);
  });
};
