import { FC, memo } from "react";
import { useMultiproductFetchDisclosures } from "../hooks/useMultiproductFetchDisclosures";

const DisclosuresBundleWrapper: FC = () => {
  useMultiproductFetchDisclosures();

  return null;
};

export default memo(DisclosuresBundleWrapper);
