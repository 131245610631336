import { MultiProductNetworkCallsState } from "../MultiProductNetworkCallsSlice";
import { useMultiproductEndpointInfo } from "./useMultiProductEndpointsInfo";

export const useAreMultiProductCallsComplete = () => {
    const multiProductCalls = useMultiproductEndpointInfo();

    return !isRunning(multiProductCalls) && isComplete(multiProductCalls);

}

const isRunning = (multiProductCalls: MultiProductNetworkCallsState) => {
    return multiProductCalls.create != "idle" &&  multiProductCalls.create == "running" ||
        multiProductCalls.sendPasInfo != "idle" && multiProductCalls.sendPasInfo == "running"
}

const isComplete = (multiProductCalls: MultiProductNetworkCallsState) => {
    return multiProductCalls.create == "idle" ||  multiProductCalls.create == "complete" &&
        multiProductCalls.sendPasInfo == "idle" ||  multiProductCalls.sendPasInfo == "complete";

}
