import React, { FC } from "react";

const CouldNotCompleteAOXCalls: FC = () => {
  return (
    <div className={"account-creation__modal"}>
      <h4>Your request can't be completed</h4>
      <span className={"hys-text primary-text"}>
        We are unable to complete your application at this time, please try
        again shortly. If you are still unable to continue, please contact our
        support team at{" "}
        <a href={"emailto:help@laurelroad.com"}>help@laurelroad.com</a>
      </span>
    </div>
  );
};

export default CouldNotCompleteAOXCalls;
