interface EmploymentIndustryOption {
  text: string;
  id: string;
}

export const EMPLOYMENT_INDUSTRY_OPTIONS: EmploymentIndustryOption[] = [
    {text: "Adult Entertainment", id: "adult_entertainment"},
    {text: "Cannabis", id: "cannabis"},
    {text: "Internet Gambling", id: "internet_gambling"},
    {text: "Internet Sweepstakes Cafe", id: "internet_cafe"},
    {text: "Payday/Title Lending", id:"payday_title_lending"},
    {text: "Embassy/Foreign Consulate", id: "foreign_consulate"},
    {text: "None of the above", id: "none"}
  ];

  const getEmploymentIndustriesFor = (...optionIds: string[]) : EmploymentIndustryOption[]=> {
      return optionIds?.length >= 1 ? EMPLOYMENT_INDUSTRY_OPTIONS.filter(option => optionIds.includes(option.id)) : EMPLOYMENT_INDUSTRY_OPTIONS;
  }

  export const getEmploymentIndustryOptions = (occupationClassification: string | undefined): EmploymentIndustryOption[] => {
    if( !occupationClassification) { return getEmploymentIndustriesFor()}

    const restrictedBusinessManagement = { occupations: ["OCCCL017", "OCCCL022", "OCCCL005"], options: getEmploymentIndustriesFor("adult_entertainment", "cannabis", "internet_gambling", "internet_cafe", "payday_title_lending", "none")};
    const restrictedArtsDesign = {occupations: ["OCCCL003"], options: getEmploymentIndustriesFor("adult_entertainment", "cannabis", "internet_gambling", "internet_cafe", "none")};
    const restrictedSocialServices = {occupations: ["OCCCL006", "OCCCL019"], options: getEmploymentIndustriesFor("adult_entertainment", "cannabis", "none")};
    const restrictedComputerMath = {occupations: ["OCCCL007"], options: getEmploymentIndustriesFor("internet_gambling", "internet_cafe", "payday_title_lending", "none")};
    const restrictedConstructionHealthCare = {occupations: ["OCCCL001", "OCCCL008", "OCCCL010", "OCCCL012", "OCCCL013", "OCCCL014", "OCCCL016", "OCCCL020", "OCCCL023"], options: getEmploymentIndustriesFor("cannabis", "none")};
    const restrictedFoodPrep = {occupations: ["OCCCL011"], options: getEmploymentIndustriesFor("adult_entertainment", "cannabis", "internet_cafe", "none")};
    const restrictedGovernmentLegalOccupation = {occupations: ["OCCCL015", "OCCCL024"], options: getEmploymentIndustriesFor("foreign_consulate", "none")};

    const restrictedIndustries = [restrictedBusinessManagement, restrictedArtsDesign, restrictedSocialServices, restrictedComputerMath, restrictedConstructionHealthCare, restrictedFoodPrep, restrictedGovernmentLegalOccupation];
    return  restrictedIndustries.filter( industry => industry.occupations.includes(occupationClassification)).flatMap( industry => industry.options);
  }
