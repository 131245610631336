export const MEXICAN_STATE_LIST = [
  { text: "Aguascalientes", id: "AG" },
  {
    text: "Baja California",
    id: "BC",
  },
  { text: "Baja California Sur", id: "BS" },
  { text: "Campeche", id: "CM" },
  {
    text: "Chiapas",
    id: "CS",
  },
  { text: "Chihuahua", id: "CH" },
  { text: "Coahuila", id: "CO" },
  { text: "Colima", id: "CL" },
  {
    text: "Mexico City",
    id: "DF",
  },
  { text: "Durango", id: "DG" },
  { text: "Guanajuato", id: "GT" },
  { text: "Guerrero", id: "GR" },
  {
    text: "Hidalgo",
    id: "HG",
  },
  { text: "Jalisco", id: "JA" },
  { text: "México", id: "EM" },
  { text: "Michoacán", id: "MI" },
  {
    text: "Morelos",
    id: "MO",
  },
  { text: "Nayarit", id: "NA" },
  { text: "Nuevo León", id: "NL" },
  { text: "Oaxaca", id: "OA" },
  {
    text: "Puebla",
    id: "PU",
  },
  { text: "Querétaro", id: "QT" },
  { text: "Quintana Roo", id: "QR" },
  {
    text: "San Luis Potosí",
    id: "SL",
  },
  { text: "Sinaloa", id: "SI" },
  { text: "Sonora", id: "SO" },
  { text: "Tabasco", id: "TB" },
  {
    text: "Tamaulipas",
    id: "TM",
  },
  { text: "Tlaxcala", id: "TL" },
  { text: "Veracruz", id: "VE" },
  { text: "Yucatán", id: "YU" },
  {
    text: "Zacatecas",
    id: "ZA",
  },
];
