/*
  TODO: This can be replaced with react-select
  Check out Prefill's SUFFIX box for example
*/
import React, { FunctionComponent, useCallback, useState } from "react";
import Fuse from "fuse.js";
import "./styles.scss";
import {
  DropDownItemConfig,
  DropDownList,
  TextField,
  themeColors,
} from "@laurelroad/lrds-react";
import { SearchableDropdownProps } from "./types";
import {
  DROP_DOWN_ITEM_NODE,
  LOADING_ITEMS,
  NO_RESULTS_ITEM,
} from "./constants";

const fuseConfig: Fuse.IFuseOptions<DropDownItemConfig> = {
  keys: ["text"],
  shouldSort: false,
  threshold: 0.0,
  ignoreLocation: true,
};
const SearchableDropdown: FunctionComponent<SearchableDropdownProps> = ({
  id,
  items = [],
  value,
  name,
  label,
  error,
  onChange,
  onBlur,
  required,
  maxVisibleItems,
  readOnlyTextFilter,
  onInputChange = () => {},
  isLoadingItems = false,
  leadingIcon,
}) => {
  const [showDropdown, setShowdropdown] = useState(false);
  const [textFilter, setTextFilter] = useState("");

  const textFieldOnClick = useCallback(() => {
    setShowdropdown(true);
    setTextFilter("");
  }, [setTextFilter, setShowdropdown]);

  const dropdownClickOut = useCallback(
    (e: any) => {
      //since dropdown is position:absolute it is not a part of the DOM tree
      // for this compnent. As such the onBlur event will be called before the onSelect event
      // from the dropdown list.
      if (
        e.relatedTarget?.nodeName?.toLowerCase().includes(DROP_DOWN_ITEM_NODE)
      ) {
        e.preventDefault();
        return;
      }
      setShowdropdown(false);
      onBlur(value);
    },
    [setShowdropdown, onBlur, value]
  );
  const noResultsList = [
    {
      ...NO_RESULTS_ITEM,
      onClick: () => {
        setShowdropdown(false);
      },
    },
  ];

  const dropdownList = items.map((item) => {
    return {
      ...item,
      onClick: () => {
        onChange({
          fieldName: name,
          id: item.id,
        });
        setTextFilter(item.text);
        setShowdropdown(false);
      },
    };
  });

  const fuse = new Fuse(dropdownList, fuseConfig);
  const filteredDropdown = fuse.search(textFilter).map((r) => {
    return { ...r.item };
  });
  const selectedValue = items.find((item) => item.id === value);

  const getDropdownList = () => {
    if (filteredDropdown.length === 0) {
      return textFilter ? noResultsList : dropdownList;
    }

    return filteredDropdown;
  };

  return (
    <div className="lr-savings-searchable-dropdown" onBlur={dropdownClickOut}>
      <TextField
        label={label}
        id={id}
        value={selectedValue?.text || textFilter}
        onClick={textFieldOnClick}
        onFocus={textFieldOnClick}
        leadingIcon={leadingIcon}
        trailingIcon={{ color: themeColors.secondary, icon: "chevron-down" }}
        onChange={(e: any) => {
          const foundItem = items.find((item) => item.text === e.target.value);
          setTextFilter(e.target.value);
          onInputChange(e.target.value);
          onChange({
            fieldName: name,
            id: foundItem?.id || NO_RESULTS_ITEM.id,
          });
        }}
        required={required}
        error={error}
        readOnly={readOnlyTextFilter || false}
      />

      <DropDownList
        className="lr-savings-searchable-dropdown__dropdown-list u-l-full-width"
        isVisible={showDropdown}
        maxVisibleItems={maxVisibleItems}
        items={isLoadingItems ? LOADING_ITEMS : getDropdownList()}
        selectedItemId={"-1"}
      />
    </div>
  );
};

export default SearchableDropdown;
