export const OCCUPATION_CLASSIFICATIONS: Array<{
  text: string;
  id: string;
  industryClarification: boolean;
}> = [
  {
    text: "Healthcare practitioners – doctors and dentists",
    id: "OCCCL012",
    industryClarification: true,
  },
  {
    text: "Other healthcare practitioners – i.e. nurses, lab technicians, physical therapy, dental hygienists etc",
    id: "OCCCL013",
    industryClarification: true,
  },
  {
    text: "Architecture and engineering occupations",
    id: "OCCCL001",
    industryClarification: true,
  },
  { text: "Armed Forces", id: "OCCCL002", industryClarification: false },
  {
    text: "Arts, design, entertainment, sports, and media occupations",
    id: "OCCCL003",
    industryClarification: true,
  },
  {
    text: "Building and grounds cleaning and maintenance occupations",
    id: "OCCCL004",
    industryClarification: false,
  },
  {
    text: "Business and financial operations occupations",
    id: "OCCCL005",
    industryClarification: true,
  },
  {
    text: "Community and social service occupation",
    id: "OCCCL006",
    industryClarification: true,
  },
  {
    text: "Computer and mathematical science occupations",
    id: "OCCCL007",
    industryClarification: true,
  },
  {
    text: "Construction and extraction occupations",
    id: "OCCCL008",
    industryClarification: true,
  },
  {
    text: "Education, training, and library occupations",
    id: "OCCCL009",
    industryClarification: false,
  },
  {
    text: "Farming, fishing, and forestry occupations",
    id: "OCCCL010",
    industryClarification: true,
  },
  {
    text: "Food preparation and serving related occupations",
    id: "OCCCL011",
    industryClarification: true,
  },

  {
    text: "Installation, maintenance, and repair occupations",
    id: "OCCCL014",
    industryClarification: true,
  },
  { text: "Legal occupations", id: "OCCCL015", industryClarification: true },
  {
    text: "Life, physical, and social science occupations",
    id: "OCCCL016",
    industryClarification: true,
  },
  {
    text: "Management occupations",
    id: "OCCCL017",
    industryClarification: true,
  },
  {
    text: "Office and administrative support occupations",
    id: "OCCCL018",
    industryClarification: false,
  },
  {
    text: "Personal care and service occupations",
    id: "OCCCL019",
    industryClarification: true,
  },
  {
    text: "Production occupations",
    id: "OCCCL020",
    industryClarification: true,
  },
  {
    text: "Protective service occupations",
    id: "OCCCL021",
    industryClarification: false,
  },
  {
    text: "Sales and related occupations",
    id: "OCCCL022",
    industryClarification: true,
  },
  {
    text: "Transportation and material moving occupations",
    id: "OCCCL023",
    industryClarification: true,
  },
  {
    text: "Government related employee",
    id: "OCCCL024",
    industryClarification: true,
  },
];
