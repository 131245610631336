import Joi from "joi";
import { OCCUPATION_CLASSIFICATIONS } from "../OccupationClassifications";

const CLARIFIATION_IDS = OCCUPATION_CLASSIFICATIONS.filter(
  (option) => option.industryClarification
).map((option) => option.id);

export const EmployerIndustrySchema = Joi.when(
  Joi.ref("occupationClassification"),
  {
    is: CLARIFIATION_IDS,
    then: Joi.string().min(1).required(),
    otherwise: Joi.optional(),
  }
).messages({ "string.empty": "Employer industry is required" });

export const EmployerClarificationSchema = Joi.string().valid(
  ...CLARIFIATION_IDS
);
