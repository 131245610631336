import { putRequest } from "../../../../utils/crud";
import { ObsResponse } from "../../types";
import { MP_OBS_ENDPOINT } from "../../constants";
import {
  SERVICE_UNAVAILABLE,
  FORBIDDEN,
  UNPROCESSABLE_ENTITY,
  UNAUTHORIZED,
} from "../../../../utils/crud/constants";
import { OBSCallException, OBSNotRunError, OBSTimeoutError, OBSApplicationNotCompleteError, OBSRunError } from "./runOBS";

export const runBundleOBS = async () => {
  try {
    return await putRequest<null, ObsResponse[]>(MP_OBS_ENDPOINT);
  } catch (e: any) {
    const error: OBSCallException = e;
    const { status: errorStatusCode } = error;
    if (errorStatusCode === UNAUTHORIZED) {
      throw new OBSNotRunError();
    } else if (errorStatusCode === SERVICE_UNAVAILABLE) {
      throw new OBSTimeoutError();
    } else if (errorStatusCode === FORBIDDEN) {
      throw new OBSApplicationNotCompleteError();
    }
    throw new OBSRunError();
  }
};