import Joi from "joi";
import moment from "moment";
import { isValidDateFormat } from "../../../../../utils/stringUtils";

const today = moment();

const isValidIssueDate = (dateString: string, helpers: any) => {
  if (!dateString) {
    return helpers.error("date.base");
  }

  const givenDate = moment(dateString, "MM/DD/YYYY");

  if (!isValidDateFormat(dateString) || !givenDate.isValid()) {
    return helpers.error("date.format");
  }

  if (!givenDate.isValid()) {
    return helpers.error("date.valid");
  }

  if (givenDate.isValid() && givenDate.isAfter(today)) {
    return helpers.error("date.max");
  }
};

const issueDateSchema = Joi.custom(isValidIssueDate);

export const IssueDateSchema = issueDateSchema.messages({
  "any.required": "Issue Date is required",
  "date.base": "Issue Date is required",
  "date.max": "Issue Date must be before today's date",
  "date.format": "Issue Date must be in format MM/DD/YYYY",
  "date.valid": "Issue Date must be a valid date",
});
