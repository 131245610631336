import {
  EmployeeStatus,
  NON_EMPLOYED_EMPLOYMENT_STATUSES,
} from "./constants/EmployeeStatuses";
import { OCCUPATION_CLASSIFICATIONS } from "./constants/OccupationClassifications";

export const isUnemployed = (employmentStatus: string) =>
  NON_EMPLOYED_EMPLOYMENT_STATUSES.includes(employmentStatus as EmployeeStatus);

export const needsClarification = (occupationId: string) => {
  const occupation = OCCUPATION_CLASSIFICATIONS.find( ({id}) => id === occupationId)
  return occupation?.industryClarification;
}

export const isHealthcareOccupation = (occupationId = '') => {
  const healthcareIds = OCCUPATION_CLASSIFICATIONS.filter( ({ text }) => text.toLowerCase().includes("healthcare")).map( ({id}) => id );
  return healthcareIds.includes(occupationId);
}


