import { useMemo } from "react";
import { usePersonalInfoViewState, usePersonalInfo } from ".";
import { isEligibleForAccount } from "../utils";

export const useIsEligibleForAccount = () => {
  const { personalInfoSubmitted } = usePersonalInfoViewState();
  const personalInfo = usePersonalInfo();

  return useMemo(() => {
    if(personalInfoSubmitted){
        return isEligibleForAccount(personalInfo);

    }
  return false;
  }, [personalInfo, personalInfoSubmitted]);
};
