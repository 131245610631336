import { Acknowledgement, BundleAcknowledgments } from "../../types";

export const groupAcknowledgmentsByProductCode = (
  acknowledgements: Acknowledgement[],
  bundleProductCodes: string[]
) => {
  const bundleAcknowledgements = bundleProductCodes.reduce(
    (acc, current) => {acc[current] = []; return acc;},
    {} as BundleAcknowledgments
  );

  Object.keys(bundleAcknowledgements).forEach( (key,index) => {
    bundleAcknowledgements[key] = acknowledgements.filter( ack =>
       (ack.productCodes && ack.productCodes.includes(key))
     || (index === 0 && ack.productCodes == null))
  });

  return bundleAcknowledgements;
};
