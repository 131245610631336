import { COUNTRIES } from "./Countries";
import { CITIZENSHIPS, BUNDLED_OAO_CITIZENSHIPS } from "./Citizenships";

export const CITIZENSHIP_OPTIONS = Object.keys(CITIZENSHIPS).map(
  (citizenshipName) => ({
    id: citizenshipName,
    label: citizenshipName,
    value: CITIZENSHIPS[citizenshipName],
  })
);

export const BUNDLED_OAO_CITIZENSHIP_OPTIONS = Object.keys(BUNDLED_OAO_CITIZENSHIPS).map(
  (citizenshipName) => ({
    id: citizenshipName,
    label: citizenshipName,
    value: BUNDLED_OAO_CITIZENSHIPS[citizenshipName],
  })
);

export const COUNTRY_OPTIONS = Object.keys(COUNTRIES).map((countryName) => ({
  label: countryName,
  value: COUNTRIES[countryName],
}));
