import { useEffect } from "react"

/*
prevetNav is set in index.html script to notify the user their changes
won't be saved.
There doesn't seem to be a clean way to discrimenate between events issues by 
browser back button or a user selecting  a url to an external site within our app.
as such there is a global flag that we can toggle for instances where a user is allowed
to go to an external site
*/
export const useAllowNavigationToExternalSite = () => {
    useEffect(() => {
        window.preventNav = false;
        return () => {
            window.preventNav = true;
        }
    },[])
}