import React, { FC } from "react";
import { Button } from "@laurelroad/lrds-react";
import { useIsBundled } from "../../../hooks/useIsBundled";
import { useSLOSRedirectUrl } from "../../../hooks/useSLOSRedirectUrl";
import { redirectToLaurelRoad } from "../../CreateCredentials/utils/redirectToLaurelRoad";
import { useAllowNavigationToExternalSite } from "../../Navigation/hooks/AllowNavigationToExternalSite/useAllowNavigationToExternalSite";

const ApplicationNotFinished: FC = () => {
  useAllowNavigationToExternalSite();

  const isBundled = useIsBundled();
  const slOSUrl = useSLOSRedirectUrl();
  const handleOnClick = () => {
    if (isBundled) {
      window.location.href = slOSUrl ?? "";
    } else {
      redirectToLaurelRoad();
    }
  }

  return (
      <div className={"finish-application__modal"}>
        <h4>Your request can't be completed</h4>
        <span className={"hys-text primary-text"}>
          It appears your application is not complete and we cannot book your
          account. If you believe this is in error, please contact our support
          team at <a href={"emailto:help@laurelroad.com"}>help@laurelroad.com</a>
        </span>
        <Button onClick={handleOnClick}>OK</Button>
      </div>
  );
};

export default ApplicationNotFinished;
