import Joi from "joi";
import { CitizenshipStatusSchema } from "./CitizenshipStatus";
import { CitizenshipCountriesSchema } from "./CitizenshipCountries";
import { ResidencyCountriesSchema } from "./ResidencyCountries";

export const CitizenshipSchema = Joi.object({
  citizenshipStatus: CitizenshipStatusSchema,
  citizenshipCountries: CitizenshipCountriesSchema,
  residencyCountries: ResidencyCountriesSchema,
});
