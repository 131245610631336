import { useEffect } from "react";
import { useApplicationInfo } from "../../components/ConfirmationPage/hooks";
import { sendToGTM } from "./sendToGTM";

export const useSendProfileEvent = () => {
  const { productCode, id } = useApplicationInfo();
  useEffect(() => {
    if (productCode) {
      sendToGTM({
        event: "oao_profile",
        applicationId: id,
        productCode,
      });
    }
  }, [productCode, id]);
};
