import { FC, memo } from "react";
import { useFetchDisclosures } from "../hooks";

const DisclosuresWrapper: FC = () => {
  useFetchDisclosures();

  return null;
};

export default memo(DisclosuresWrapper);
