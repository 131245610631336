import { FC } from 'react';
import React from 'react';
import { Paragraph } from '@laurelroad/lrds-react';

const SavingsAccountNotCreated: FC = () => {
  return (
    <>
      {' '}
      <Paragraph>
        <span className="hys-text subtitle2 secondary bold">
          Getting Started with Your New Account
        </span>
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Please bear with us while we finish setting up your account. You’ll receive an email
        confirming your account opening in a few hours. Then, you’ll be able to log into our
        Online Banking system with the User ID and password you created.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Your next step will be to fund your savings account. Bookmark our{' '}
        <a href="https://www.laurelroad.com/faq/how-do-i-deposit-money-into-my-account/">
          FAQ
        </a>
        , which lists methods for getting money into your account.
      </Paragraph>
    </>
  );
};

export default SavingsAccountNotCreated;
