import React, { FC, memo, useCallback, useEffect } from 'react';
import {
  Button,
  Grid,
  GridBreakpoints,
  LrIconName,
  Optional,
  Paragraph,
} from '@laurelroad/lrds-react';
import './profile.scss';
import ProfileModal from './ProfileModal/ProfileModal';
import { useIsMobile } from '../../hooks/useIsMobile';
import ProspectName from './ProspectName/ProspectName';
import ProspectEmail from './ProspectEmail/ProspectEmail';
import DateOfBirth from './DateOfBirth/DateOfBirth';
import Address from './Address/Address';
import SocialSecurityNumber from './SocialSecurityNumber/SocialSecurityNumber';
import MobilePhone from './MobilePhone/MobilePhone';
import ElectronicDisclosure from './ElectronicDisclosure/ElectronicDisclosure';
import {
  useContinueButtonHandler,
  useIsValidProfileInfo,
  useProfileInfo,
  useProfileViewState,
} from './hooks';
import { OAOForm } from '../OAOForm/OAOForm';
import { useSendProfileEvent } from '../../services/google-tag-manager/useSendProfileEvent';
import { useDispatch } from 'react-redux';
import { showProfileFieldErrors, validateProfileFields } from './slice';
import { autoScrollToError } from '../../utils/autoScrollToError';

const ProfileCreationPage: FC = () => {
  useSendProfileEvent();
  const isMobile = useIsMobile();
  const isValidProfile = useIsValidProfileInfo();
  const continueButtonHandler = useContinueButtonHandler();
  const profileInfo = useProfileInfo();
  const { profileFieldErrors, showFieldErrors } = useProfileViewState();
  const dispatch = useDispatch();

  const gridBreakpoints: GridBreakpoints[] = isMobile
    ? [{ breakpoint: 'mobile', autoSize: 'sm' }]
    : [];

  const headerData = {
    icon: 'user' as LrIconName,
    text: "We'll set up your profile with the details below",
  };

  const profileValidationHandler = useCallback(() => {
    dispatch(validateProfileFields(profileInfo));
    dispatch(showProfileFieldErrors());
  }, [dispatch, profileInfo]);

  const notValid = !isValidProfile;

  const { showModal } = useProfileViewState();

  useEffect(() => {
    if (showFieldErrors && profileFieldErrors.length > 0) {
      autoScrollToError(profileFieldErrors);
    }
  }, [showFieldErrors, profileFieldErrors]);

  return (
    <OAOForm className={'profile'} headerData={headerData} data-testid="profile-creation-page">
      <Grid className={''} gap={'md'} breakpoints={gridBreakpoints}>
        <Optional renderIf={showModal}>
          <ProfileModal />
        </Optional>
        <ProspectName />
        <DateOfBirth />
        <ProspectEmail />
        <MobilePhone />
        <SocialSecurityNumber />
        <Address />
        <ElectronicDisclosure />
        <Paragraph>
          <div data-testid="consent-info">
            By providing your email address you authorize Laurel Road to email you about your
            application and other Laurel Road accounts and services, even if the application is
            not completed. You can opt out of future marketing email offers at any time by
            following instructions in the email.
            <br />
            <br />
            To help the government fight the funding of terrorism and money laundering
            activities, Federal law requires all financial institutions to obtain, verify, and
            record information that identifies each person who opens an account. What this
            means for you: When you open an account, we will ask for your name, address, date
            of birth, and other information that will allow us to identify you. We may also ask
            to see your driver's license or other identifying documents. By clicking continue,
            you authorize to collect and validate the above and related information within this
            application through public and third party sources to confirm your identity and
            process this application.
          </div>
        </Paragraph>
        <div className={'profile__footer'}>
          <Button
            onClick={() => {
              if (notValid) {
                profileValidationHandler();
              } else {
                continueButtonHandler();
              }
            }}>
            Continue
          </Button>
        </div>
      </Grid>
    </OAOForm>
  );
};

export default memo(ProfileCreationPage);
