import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchDisclosures } from "../asyncActions";

export const useFetchDisclosures = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDisclosures());
  }, [dispatch]);
};
