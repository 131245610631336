import {
  invokeCredentialCreationIDV,
  invokeCredentialCreationPhone,
} from '../../../utils/amt';

export const invokeAMTCredentialCreation = async (
  originationId: string,
  skipDataReentry: boolean,
  profileSavedSessionId?: string
): Promise<boolean> => {
  console.log(
    'Prospect low in invokeAMTCredentialCreation which is calling invokeCredentialCreationPhone(ACQUIRE_2)'
  );
  const credentialsCreated = skipDataReentry
    ? await invokeCredentialCreationIDV(originationId, profileSavedSessionId)
    : await invokeCredentialCreationPhone(originationId);
  return credentialsCreated;
};
