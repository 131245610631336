import { sendToGTM } from "./sendToGTM";

export const sendOaoReturnEvent = (
  applicationId: number,
  productCode: string
) => {
  sendToGTM({
    event: "oao_return",
    applicationId,
    productCode,
  });
};
