import { useSelector } from "react-redux"
import { AppState } from "../../../store"
import { PrefixState } from "./types";

export const usePrefix = () => 
    useSelector<AppState, PrefixState>(({ application }) => {
        const{
            prefix
        } = application;
        return {
            prefix
        };
    });