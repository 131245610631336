import React, { FC, memo } from 'react';
import '@laurelroad/lrds-styles/css/lrds.css';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AppViewState } from './types';
import { Optional, Progress } from '@laurelroad/lrds-react';
import LeavingModal from '../LeavingModal';
import ProfileCreationPage from '../ProfileCreationPage';
import PersonalInfoPage from '../PersonalInfoPage';
import DisclosuresPage from '../DisclosuresPage';
import IDVHardstopModal from '../IDVHardStopModal';
import { Navigation } from '../Navigation';
import EligibilityModal from '../EligibilityModal';
import ShapeModal from '../ShapeModal/ShapeModal';
import useUiState from '../../hooks/useUiState';
import { useIDVHardStopInfo } from '../IDVHardStopModal/hooks';
import { useApplicationInfo } from '../ConfirmationPage/hooks';
import { ProductCode } from '../../constants/ProductCode';

const App: FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useUiState();
  const { currentView } = useSelector<AppState, AppViewState>(({ appView }) => appView);
  const { show: showIdvHardStop } = useIDVHardStopInfo();
  const { productCode, isLoyaltyEligible } = useApplicationInfo();
  return (
    <>
      {isLoading ? (
        <Progress />
      ) : (
        <div className="App-container">
          <div className="App">
            <Navigation />
            <Optional renderIf={currentView === 'Profile Creation Page'}>
              <Optional renderIf={showIdvHardStop}>
                <IDVHardstopModal />
              </Optional>
              <ProfileCreationPage />
            </Optional>
            <Optional renderIf={currentView === 'Personal Info Page'}>
              <PersonalInfoPage />
            </Optional>
            <Optional renderIf={currentView === 'Disclosures Page'}>
              <DisclosuresPage />
            </Optional>
            <LeavingModal />
            <Optional
              renderIf={
                isLoyaltyEligible === false && productCode === ProductCode.LOYALTY_CHECKING
              }>
              <EligibilityModal />
            </Optional>
            <ShapeModal />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(App);
