import React, { useCallback, useEffect, useState } from "react";
import AccountCreationLoader from "./components/AccountCreationLoader";
import { useDispatch } from "react-redux";
import {
  AccountCreationResponse,
  AccountCreationState,
  AccountStatus,
  ObsResponse,
} from "./types";
import { updateAccountCreationState } from "./slice";
import { convertObsResponseToAccountState } from "./utils";
import { Redirect } from "react-router-dom";
import {
  CONFIRMATION_APPROVED_ROUTE,
  CONFIRMATION_BUNDLE_ROUTE,
  CONFIRMATION_DENIED_ROUTE,
  CONFIRMATION_PENDING_ROUTE,
  CONFIRMATION_REFERRED_ROUTE,
  CONFIRMATION_TIMEOUT_ROUTE,
} from "../ConfirmationPage/constants";

import ApplicationNotComplete from "./components/ApplicationNotComplete";
import CouldNotCompleteAOXCalls from "./components/CouldNotCompleteAOXCalls";
import { runBundleAccountCreation } from "./async/AccountCreation/runBundleAccountCreation";
import {
  AmlFailureError,
  OBSDeclinedError,
  runAccountCreation,
} from "./async/AccountCreation/runAccountCreation";
import { useIsMultiproduct } from "../BundleModal/hook/useIsMultiproduct";
import { useBundleInfo } from "../BundleModal/hook/useBundleInfo";
import {OBSApplicationNotCompleteError, OBSNotRunError, OBSTimeoutError} from "./async/Obs/runOBS";

const triggerAccountCreation = async (
  setComponent: (component: JSX.Element) => void,
  updateAccountState: (state: AccountCreationState) => void
) => {
  try {
    const obsResponse: ObsResponse = await runAccountCreation();

    const updatedAccountState = convertObsResponseToAccountState(obsResponse);
    updateAccountState({ accountCreation: [updatedAccountState] });
    const { accountStatus } = updatedAccountState;
    let component;
    if (accountStatus === AccountStatus.Pending) {
      console.log("Redirecting to confirmation pending route");
      component = <Redirect to={CONFIRMATION_PENDING_ROUTE} />;
    } else if (accountStatus === AccountStatus.Referred) {
      console.log("Redirecting to confirmation referred route");
      component = <Redirect to={CONFIRMATION_REFERRED_ROUTE} />;
    } else {
      console.log("Redirecting to confirmation approved route");
      component = <Redirect to={CONFIRMATION_APPROVED_ROUTE} />;
    }
    setComponent(component);
  } catch (e: any) {
    handleAccountCreationError(e, setComponent);
  }
};

const triggerBundleAccountCreation = async (
  setComponent: (component: JSX.Element) => void,
  updateAccountState: (state: AccountCreationState) => void
) => {
  try {
    const obsResponse: ObsResponse[] | ObsResponse =
      await runBundleAccountCreation();

    const updatedAccountState: AccountCreationResponse[] = obsResponse.map(
      (response) => {
        return convertObsResponseToAccountState(response);
      }
    );
    updateAccountState({ accountCreation: updatedAccountState });
    let component = <Redirect to={CONFIRMATION_BUNDLE_ROUTE} />;
    setComponent(component);
  } catch (e: any) {
    handleBundleAccountCreationError(e, setComponent);
  }
};

const handleAccountCreationError = (
  e: Error,
  setComponent: (component: JSX.Element) => void
) => {
  if (e instanceof AmlFailureError || e instanceof OBSDeclinedError) {
    setComponent(<Redirect to={CONFIRMATION_DENIED_ROUTE} />);
  } else if (e instanceof OBSTimeoutError) {
    setComponent(<Redirect to={CONFIRMATION_TIMEOUT_ROUTE} />);
  } else if (
    e instanceof OBSApplicationNotCompleteError ||
    e instanceof OBSNotRunError
  ) {
    setComponent(<ApplicationNotComplete />);
  } else {
    // Catch all
    setComponent(<CouldNotCompleteAOXCalls />);
  }
};

const handleBundleAccountCreationError = (
  e: Error,
  setComponent: (component: JSX.Element) => void
) => {
  if (e instanceof AmlFailureError || e instanceof OBSDeclinedError) {
    setComponent(<Redirect to={CONFIRMATION_BUNDLE_ROUTE} />);
  } else if (e instanceof OBSTimeoutError) {
    setComponent(<Redirect to={CONFIRMATION_TIMEOUT_ROUTE} />);
  } else if (
    e instanceof OBSApplicationNotCompleteError||
    e instanceof OBSNotRunError
  ) {
    setComponent(<ApplicationNotComplete />);
  } else {
    // Catch all
    console.error("Catch all: ", e);
    setComponent(<CouldNotCompleteAOXCalls />);
  }
};

export const AccountCreationMultiProductCheck = () => {
  const [component, setComponent] = useState<JSX.Element>(
    <AccountCreationLoader />
  );

  const dispatch = useDispatch();
  const isMultiproduct = useIsMultiproduct();
  const { replenishBundleStatus } = useBundleInfo();
  const updateAccountState = useCallback(
    (accountState: AccountCreationState) => {
      dispatch(updateAccountCreationState(accountState.accountCreation));
    },
    [dispatch]
  );
  useEffect(() => {
    if (replenishBundleStatus === "complete") {
      if (isMultiproduct) {
        console.log("Running MP account creation");
        triggerBundleAccountCreation(setComponent, updateAccountState);
      } else {
        console.log("Running single account creation");
        triggerAccountCreation(setComponent, updateAccountState);
      }
    }
  }, [replenishBundleStatus, isMultiproduct, updateAccountState ]);
  return component;
};
