import React, { useEffect, useState } from 'react';
import {
  ApplicationNotFinishedError,
  MCAFailedError,
  MCAPendingError,
  runFinishApplicationChecks,
} from './async/runFinishApplicationChecks';
import FinishApplicationLoader from './components/FinishApplicationLoader';
import ApplicationNotFinished from './components/ApplicationNotFinished';
import CouldNotCompleteMCA from './components/CouldNotCompleteMCA';
import { Redirect } from 'react-router-dom';
import {
  CONFIRMATION_DENIED_ROUTE,
  CONFIRMATION_PENDING_ROUTE,
} from '../ConfirmationPage/constants';
import { ACCOUNT_CREATION_ROUTE } from '../AccountCreation/constants';
import { useIsMultiproduct } from '../BundleModal/hook/useIsMultiproduct';
import store from '../../store';
import { updateMcaDecline, updateMcaPending } from '../ConfirmationPage/BundleView/slice';
import { useBundleInfo } from '../BundleModal/hook/useBundleInfo';

const executeFinishApplicationChecks = async (
  setComponent: (component: JSX.Element) => void
) => {
  try {
    const renderAMTContainer = () =>
      setComponent(<div id="transmitContainer" className="mt-4" />);
    await runFinishApplicationChecks(renderAMTContainer);
    setComponent(<Redirect to={ACCOUNT_CREATION_ROUTE} />);
  } catch (e) {
    console.log(e);
    if (e instanceof ApplicationNotFinishedError) {
      setComponent(<ApplicationNotFinished />);
    } else if (e instanceof MCAFailedError) {
      setComponent(<Redirect to={CONFIRMATION_DENIED_ROUTE} />);
    } else if (e instanceof MCAPendingError) {
      console.log('Redirecting to confirmation pending route');
      setComponent(<Redirect to={CONFIRMATION_PENDING_ROUTE} />);
    } else {
      //catch all for MCARunError and other errors
      setComponent(<CouldNotCompleteMCA />);
    }
  }
};

const executeBundleFinishApplicationChecks = async (
  setComponent: (component: JSX.Element) => void
) => {
  try {
    const renderAMTContainer = () =>
      setComponent(<div id="transmitContainer" className="mt-4" />);
    await runFinishApplicationChecks(renderAMTContainer);
    setComponent(<Redirect to={ACCOUNT_CREATION_ROUTE} />);
  } catch (e) {
    console.log(e);
    if (e instanceof ApplicationNotFinishedError) {
      setComponent(<ApplicationNotFinished />);
    } else if (e instanceof MCAFailedError) {
      console.log('update bundle mcaDecline');
      store.dispatch(updateMcaDecline(true));
      setComponent(<Redirect to={CONFIRMATION_DENIED_ROUTE} />);
    } else if (e instanceof MCAPendingError) {
      store.dispatch(updateMcaPending(true));
      console.log('update bundle mcaPending');
      setComponent(<Redirect to={CONFIRMATION_PENDING_ROUTE} />);
    } else {
      //catch all for MCARunError and other errors
      setComponent(<CouldNotCompleteMCA />);
    }
  }
};

export const useFinishApplication = () => {
  const [component, setComponent] = useState<JSX.Element>(<FinishApplicationLoader />);
  const isItMultiproduct = useIsMultiproduct();
  const { replenishBundleStatus } = useBundleInfo();

  useEffect(() => {
    if (replenishBundleStatus === 'complete') {
      if (isItMultiproduct) {
        executeBundleFinishApplicationChecks(setComponent);
      } else {
        executeFinishApplicationChecks(setComponent);
      }
    }
  }, [isItMultiproduct, replenishBundleStatus]);

  return component;
};
