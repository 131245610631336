import { ApplicationFinishedResponse, MCAData } from "../async/types";
import { toYearMonthDateString } from "../../../utils/dateUtils";

export const convertApplicationFinishedResponseToMCAData = (
  applicationFinishedResponse: ApplicationFinishedResponse
): MCAData => {
  const {
    applicationId,
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth,
    mobilePhone,
    streetAddress,
    zipCode,
    socialSecurityNumber,
    productCode,
  } = applicationFinishedResponse;
  return {
    id: applicationId,
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth: toYearMonthDateString(dateOfBirth),
    mobilePhone,
    streetAddress,
    zipCode,
    socialSecurityNumber,
    productCode,
  };
};
