import { DialogModal, Optional, Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';
import { useApplicationInfo } from '../ConfirmationPage/hooks';
import { useIsBundled } from '../../hooks/useIsBundled';
import './styles.scss';
import { useIsCheckingApplication } from '../../hooks/useIsCheckingApplication';
import { useAllowNavigationToExternalSite } from '../Navigation/hooks/AllowNavigationToExternalSite/useAllowNavigationToExternalSite';
import { useIsBundleFreedomCheckingApplication } from '../../hooks/useIsBundleFreedomChecking';

const IDVHardstopModal: FC = () => {
  useAllowNavigationToExternalSite();
  const { slosRedirectUrl } = useApplicationInfo();
  const isBundled = useIsBundled();
  const isChecking = useIsCheckingApplication();
  const isBundleFreedomChecking = useIsBundleFreedomCheckingApplication();
  const productType = isChecking ? 'checking' : 'savings';
  const link = isBundled ? slosRedirectUrl : 'http://laurelroad.com/';
  const linkText = isBundled ? 'Finish Student Loan Refinance' : 'OK';

  return (
    <DialogModal
      className={'high-yield-savings__hard-stop-modal'}
      isOpen={true}
      title="We've hit a speed bump."
      hasLeftButton
      leftButtonProps={{
        text: linkText,
        href: `${link}`,
      }}
      buttonPosition={'center'}>
      <Optional renderIf={isBundleFreedomChecking}>
        <p>
          Based on a review of the information provided in your application, we are not able to
          open a checking account for you at this time.
        </p>
        <p>Check your email for next steps regarding your membership.</p>
      </Optional>
      <Optional renderIf={isBundled}>
        <>
          <p>
            During the account opening process, our review determined that we are not able to
            open a {productType} account for you at this time. But Don't worry! This doesn't
            affect your refinancing approval. You can return to your student loan refinance
            application to close out your loan.
          </p>
          <p>
            If you have any questions, please call Member Services at{' '}
            <a href="tel:1-833-427-2265">1-833-427-2265</a> (TDD/TTY at{' '}
            <a href="tel:1-800-539-8336">1-800-539-8336</a>).
          </p>
        </>
      </Optional>
      <Optional renderIf={!isBundled && !isBundleFreedomChecking}>
        <p>
          Based on a review of the information provided in your application, we are not able to
          open a {productType} account for you at this time. If you have any questions please
          call our Contact Center at <a href="tel:1-833-427-2265">1-833-427-2265</a> (TDD/TTY
          at <a href="tel:1-800-539-8336">1-800-539-8336</a>). We appreciate your interest in
          Laurel Road, and we hope to be able to serve you in the future.
        </p>
      </Optional>
    </DialogModal>
  );
};

export default memo(IDVHardstopModal);
