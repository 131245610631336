export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const UNPROCESSABLE_ENTITY = 422;
export const SERVICE_UNAVAILABLE = 503;

const JSON_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function jsonHeaders() {
  const csrfToken = document.getElementsByName("_csrf")[0]?.getAttribute("content") || "did-not-find-csrf-token"
  const csrfHeaderName = document.getElementsByName("_csrf_header")[0]?.getAttribute("content") || "X-XSRF-TOKEN"

  return {
    ...JSON_HEADERS,
    [csrfHeaderName]: csrfToken
  }
}