import React, { ChangeEventHandler, FC, useCallback, useState } from "react";
import { Column, NumberField } from "@laurelroad/lrds-react";
import { useSocialSecurityNumberInfo } from "./hooks";
import { useDispatch } from "react-redux";
import { updateTextField } from "../../../utils/applicationDispatchFunctions";
import { disableClipboardEvent } from "../../../utils/inputFieldUtils";
import { useProfileInfoErrorMessages } from "../hooks/useProfileInfoErrorMessages";
import { validateProfileFields } from "../slice";
import { useProfileInfo } from "../hooks";

const SocialSecurityNumber: FC = () => {
  const dispatch = useDispatch();

  const profileInfo = useProfileInfo();
  const { socialSecurityNumber, socialSecurityNumberConfirmation } =
    useSocialSecurityNumberInfo();

  const blurHandler = () => {
    dispatch(validateProfileFields(profileInfo));
  };

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        dispatch(updateTextField(event));
      },
      [dispatch]
    );

  return (
    <>
      <Column size={6}>
        <NumberField
          id={"socialSecurityNumber"}
          name={"socialSecurityNumber"}
          className="high-yield-savings__form-field fs-exclude"
          value={socialSecurityNumber}
          onChange={textFieldChangeHandler}
          onBlur={blurHandler}
          onPaste={disableClipboardEvent}
          onCopy={disableClipboardEvent}
          label={"SSN (Social Security Number)"}
          error={useProfileInfoErrorMessages("socialSecurityNumber")}
          isValueHidden
          required={true}
          showRequiredAsterisk
          data-heap-redact-text="true"
          autoComplete={"off"}
        />
      </Column>
      <Column size={6}>
        <NumberField
          id={"socialSecurityNumberConfirmation"}
          name={"socialSecurityNumberConfirmation"}
          className="high-yield-savings__form-field fs-exclude"
          value={socialSecurityNumberConfirmation}
          onChange={textFieldChangeHandler}
          onBlur={blurHandler}
          onPaste={disableClipboardEvent}
          onCopy={disableClipboardEvent}
          label={"Confirm SSN"}
          error={useProfileInfoErrorMessages(
            "socialSecurityNumberConfirmation"
          )}
          isValueHidden
          required={true}
          showRequiredAsterisk
          data-heap-redact-text="true"
          autoComplete={"off"}
        />
      </Column>
    </>
  );
};

export default SocialSecurityNumber;
