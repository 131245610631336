export const ASSOCIATES_DEGREES = [
  "AS - Cardiovascular Technology",
  "AS - Dental Hygiene",
  "AS - Diagnostic Medical Sonography",
  "ASN - Nursing",
  "AS - Paramedics",
  "AS - Pharmacy Tech",
  "AS - Physical Therapy",
  "AS - Radiation Therapy",
  "AS - Radiology/ MRI Tech",
  "AS - Respiratory Therapy",
  "AS - Surgical Technologist",
  "Not Listed"
];
