import React, { FC } from "react";
import { useIsBundled } from "../../../hooks/useIsBundled";
import TimeoutBundleView from "./TimeoutBundleView";
import TimeoutHighYieldView from "./TimeoutHighYieldView";
import "./timeout-view.scss";
import confirmationPage from "../confirmationPage";

const TimeoutView: FC = () => {
  const isBundled = useIsBundled();
  if (isBundled) {
    return <TimeoutBundleView />;
  }
  return <TimeoutHighYieldView />;
};

export default confirmationPage(TimeoutView);
