import { Column, SelectFieldEvent } from "@laurelroad/lrds-react";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSelectField } from "../../../utils/applicationDispatchFunctions";
import SearchableDropdown from "../../SearchableDropdown/searchableDropdown";
import { ACCOUNT_PURPOSE_OPTIONS } from "./constants/SelectOptions";
import { useAccountPurpose } from "./hooks";
import { usePersonalInfoErrorMessages } from "../hooks/usePersonalInfoErrorMessages";
import { usePersonalInfo } from "../hooks";
import {validatePersonalFields} from "../asyncActions";

const AccountPurpose: FC = () => {
  const accountPurpose = useAccountPurpose();
  const dispatch = useDispatch();
  const personalInfo = usePersonalInfo();

  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const searchableDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
    },
    [dispatch]
  );

  return (
    <>
      <Column className={"col--relative"} size={6}>
        <SearchableDropdown
          className="high-yield-savings__form-field"
          id={"accountPurpose"}
          value={accountPurpose.accountPurpose || ""}
          required
          name={"accountPurpose"}
          label={"What will be the primary purpose of this account?"}
          items={ACCOUNT_PURPOSE_OPTIONS}
          onChange={searchableDropdownChangeHandler}
          error={usePersonalInfoErrorMessages("accountPurpose")}
          onBlur={blurHandler}
        />
      </Column>
    </>
  );
};

export default AccountPurpose;
