import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../utils/crud";
import { MP_MULTIPRODUCT_CODES_ENDPOINT } from "./constants";
import { MultiproductProductCodesResponse } from "./types";

export const fetchMultiproductProductCodesActionPrefix =
  "bundleInfo/multiproductProductCodes";

export const executeFetchMultiproductProductCodes = async (token?: string) =>
  await getRequest<MultiproductProductCodesResponse[]>(
    `${MP_MULTIPRODUCT_CODES_ENDPOINT}${token ? `?token=${token}` : ""}`
  ).catch((error) => {
    console.error(error);
    return [];
  });

export const fetchMultiproductProductCodes = createAsyncThunk(
  fetchMultiproductProductCodesActionPrefix,
  executeFetchMultiproductProductCodes
);
