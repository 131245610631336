import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddressDetailsResponse,
  AddressPredictionsResponse,
  JarvisDataResponse,
  MultiproductPayload,
  MultiproductUpdatedResponse,
  PasPayLoad,
  ProfileInfo,
  ZipcodeLookupPayload,
} from './types';
import { getRequest, postRequest, putRequest } from '../../utils/crud';
import {
  ADDRESS_AC_ENDPOINT,
  ADDRESS_DETAILS_ENDPOINT,
  JARVIS_ENDPOINT,
  MULTIPRODUCT_ENDPOINT,
  PAS_ENDPOINT,
  PROFILE_ENDPOINT,
  ZIPCODE_LOOKUP_ENDPOINT,
} from './constants';
import { ApplicationUpdatedResponse } from '../../types/ApplicationUpdatedResponse';
import { removeIdvSessionCookies } from '../../utils/crud/removeIdvSessionCookies';
import { AppDispatch, AppState } from '../../store';
import { AMTProspectCreationStatus } from '../AMT/types';
import { onProspectCreation } from '../AMT/asyncActions';

export const getZipCodeInfoPrefix = 'zipCode/lookup';
export const putProfileInfoActionPrefix = 'profileUpdate/updateProfileInfo';
export const postProfileInfoActionPrefix = 'profileCreation/submitProfileInfo';
export const getJarvisEligibilityPrefix = 'jarvis/eligibility';
export const postPasInfoPrefix = 'jarvis/pas';
export const postMultiproductPrefix = 'multiproductCreation/submitMultiproductInfo';

export const executePostMultiproduct = async (multiproduct: MultiproductPayload) =>
  postRequest<MultiproductPayload, MultiproductUpdatedResponse>(
    `${MULTIPRODUCT_ENDPOINT}/create`,
    multiproduct
  );

export const executeZipCodeLookup = async (zipCode: string) => {
  removeIdvSessionCookies();
  console.log(document.cookie);
  return getRequest<ZipcodeLookupPayload>(`${ZIPCODE_LOOKUP_ENDPOINT}?zipCode=${zipCode}`);
};
export const executePutProfileInfo = async (profileInfo: ProfileInfo) => {
  removeIdvSessionCookies();
  console.log(document.cookie);
  return putRequest<ProfileInfo, ApplicationUpdatedResponse>(PROFILE_ENDPOINT, profileInfo);
};
export const executePostProfileInfo = async (profileInfo: ProfileInfo) =>
  postRequest<ProfileInfo, ApplicationUpdatedResponse>(PROFILE_ENDPOINT, profileInfo);

export const executeGetJarvisEligibility = async () => {
  return await getRequest<JarvisDataResponse>(JARVIS_ENDPOINT);
};

export const executePostPasInfo = async (pasPayLoad: PasPayLoad | undefined) => {
  postRequest<PasPayLoad, void>(PAS_ENDPOINT, pasPayLoad);
};

export const getZipCodeInfo = createAsyncThunk(getZipCodeInfoPrefix, executeZipCodeLookup);

const doSaveProfileInfo = async (
  executor: () => Promise<ApplicationUpdatedResponse>,
  getState: () => AppState,
  dispatch: AppDispatch
) => {
  const profileCreateResponse = await executor();
  if (
    getState().ui.isAuthenticated &&
    getState().amtInfo.prospectCreationStatus === AMTProspectCreationStatus.Passed
  ) {
    dispatch(onProspectCreation(getState().amtInfo.prospectCreationStatus));
  }
  return profileCreateResponse;
};

export const putProfileInfo = createAsyncThunk<
  ApplicationUpdatedResponse,
  ProfileInfo,
  { state: AppState; dispatch: AppDispatch }
>(putProfileInfoActionPrefix, async (profile, { getState, dispatch }) => {
  return await doSaveProfileInfo(() => executePutProfileInfo(profile), getState, dispatch);
});
export const postProfileInfo = createAsyncThunk<
  ApplicationUpdatedResponse,
  ProfileInfo,
  { state: AppState; dispatch: AppDispatch }
>(postProfileInfoActionPrefix, async (profile, { getState, dispatch }) => {
  return await doSaveProfileInfo(() => executePostProfileInfo(profile), getState, dispatch);
});

export const getJarvisEligibilityInfo = createAsyncThunk(
  getJarvisEligibilityPrefix,
  executeGetJarvisEligibility
);

export const postPasInfo = createAsyncThunk(postPasInfoPrefix, executePostPasInfo);

export const postMultiproductInfo = createAsyncThunk<
  MultiproductUpdatedResponse[],
  void,
  { state: AppState }
>(postMultiproductPrefix, async (f, { getState }) => {
  try {
    return await Promise.all(
      getState().bundle.productCodes.map(pc => executePostMultiproduct({ productCode: pc }))
    );
  } catch {
    return [];
  }
});

export const executeAddressAutoComplete = async (keyword: string) => {
  return postRequest<{ keyword: string }, AddressPredictionsResponse>(ADDRESS_AC_ENDPOINT, {
    keyword,
  });
};

export const executeAddressDetails = async (placeId: string) => {
  return postRequest<{ placeId: string }, AddressDetailsResponse>(ADDRESS_DETAILS_ENDPOINT, {
    placeId,
  });
};
