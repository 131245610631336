import { AccountStatus, ObsResponse } from "./types";
import { DEFAULT_ROUTING_NUMBER } from "./constants";

export const stringToAccountStatus = (status: string): AccountStatus => {
  const lowerCaseStatus = status.toLocaleLowerCase();
  switch (lowerCaseStatus) {
    case "approved":
      return AccountStatus.Approved;
    case "denied":
    case "decline":
      return AccountStatus.Denied;
    case "referred":
      return AccountStatus.Referred;
    default:
      return AccountStatus.Pending;
  }
};

const validRoutingNumber = (routingNumber: string | null) =>
  routingNumber && routingNumber.trim() !== "";

export const convertObsResponseToAccountState = (response: ObsResponse) => {
  const {
    depositDecision,
    accountNumber,
    routingNumber,
    obpPartyId,
    productCode,
    applicationId,
    aoxSubmissionId,
    accountCreated,
  } = response;

  return {
    accountNumber: accountNumber!,
    routingNumber: validRoutingNumber(routingNumber)
      ? routingNumber!
      : DEFAULT_ROUTING_NUMBER,
    obpPartyId,
    accountStatus:
      depositDecision === null
        ? AccountStatus.Pending
        : stringToAccountStatus(depositDecision.toLocaleLowerCase().trim()),
    productCode: productCode,
    applicationId,
    aoxSubmissionId,
    accountCreated,
  };
};
