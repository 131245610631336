import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { postPasInfo } from "../asyncActions";

export const useSendPasInfo = () => {
    const dispatch = useDispatch();
    return useCallback((id: number) => {
        dispatch(postPasInfo({ id: id, landingUrl: window.location.href }));
    }, [dispatch]);

};