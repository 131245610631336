import { toYearMonthDateString, toIsoString } from "../../../utils/dateUtils";
import {
  useProfileInfo,
  useProfileViewState,
} from "../../ProfileCreationPage/hooks";

export const useSanitizeProfileInfo = () => {
  const profileInfo = useProfileInfo();
  const { showModal } = useProfileViewState();

  return showModal
    ? {
        ...profileInfo,
        zipCode: extractFiveDigitZip(profileInfo.zipCode),
        dateOfBirth: toYearMonthDateString(
          toIsoString(profileInfo.dateOfBirth)
        ),
      }
    : { ...profileInfo }
};

const extractFiveDigitZip = (zipCode: string) => {
    const [zip] = zipCode.split("-");
    return zip;
  };