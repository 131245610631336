export const CANADIAN_PROVINCE_LIST = [
  { text: "Alberta", id: "AB" },
  { text: "British Columbia", id: "BC" },
  {
    text: "Manitoba",
    id: "MB",
  },
  { text: "New Brunswick", id: "NB" },
  { text: "Newfoundland and Labrador", id: "NL" },
  {
    text: "Northwest Territories",
    id: "NT",
  },
  { text: "Nova Scotia", id: "NS" },
  { text: "Nunavut", id: "NU" },
  {
    text: "Ontario",
    id: "ON",
  },
  { text: "Prince Edward Island", id: "PE" },
  { text: "Quebec", id: "QC" },
  {
    text: "Saskatchewan",
    id: "SK",
  },
  { text: "Yukon", id: "YT" },
];
