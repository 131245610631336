import { useSelector } from 'react-redux';
import store, { AppState } from '../../../store';
import { AddressInfo, UseAddressAutoCompleteReturn } from './types';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { DropDownItemConfig } from '@laurelroad/lrds-react';
import { useDebounce } from 'react-use';
import { executeAddressAutoComplete, executeAddressDetails } from '../asyncActions';
import { LOADING_ITEM, NO_RESULTS_ITEM } from '../../SearchableDropdown/constants';
import { updateTextField } from '../../../utils/applicationDispatchFunctions';
import useUiState from '../../../hooks/useUiState';

export const useAddressInfo = () =>
  useSelector<AppState, AddressInfo>(({ application, profileCreationViewState }) => {
    const { zipCode, streetAddress, streetAddressTwo } = application;
    const { errors } = profileCreationViewState;
    return {
      streetAddressTwo,
      zipCode,
      streetAddress,
      serviceErrors: errors,
    };
  });

/**
 * This is private hook handling a case when user authenticates and we need to use prefilled street address.
 * This needs to only execute once after prefill call returns and sets preconditions.
 * @param streetAddress
 * @param setAddressAcState
 */
const usePrefilledStreetAddress = (
  streetAddress: string,
  setAddressAcState: Dispatch<
    SetStateAction<{ predictions: DropDownItemConfig[]; isLoading: boolean }>
  >
) => {
  const { isAuthenticated } = useUiState();
  useEffect(() => {
    if (isAuthenticated && streetAddress?.length > 0) {
      setAddressAcState({
        predictions: [
          {
            id: streetAddress,
            value: streetAddress,
            text: streetAddress,
          },
        ],
        isLoading: false,
      });
    }
  }, [isAuthenticated]);
};

export const useAddressAutoComplete = (
  streetAddress: string,
  onSelectValueChange: (value: string) => void
): UseAddressAutoCompleteReturn => {
  const [{ predictions, isLoading }, setAddressAcState] = useState<{
    predictions: DropDownItemConfig[];
    isLoading: boolean;
  }>({ predictions: [], isLoading: false });

  usePrefilledStreetAddress(streetAddress, setAddressAcState);

  const [isLoadingAddrDetails, toggleLoadingAddrDetails] = useState(false);

  const selectionRef = useRef('');

  const [, cancelAddressSearch] = useDebounce(
    () => {
      if (streetAddress.length > 1 && selectionRef.current !== streetAddress) {
        setAddressAcState(prevState => ({ ...prevState, isLoading: true }));
        executeAddressAutoComplete(streetAddress)
          .then(r => {
            setAddressAcState({
              predictions: r.predictions.map(org => ({
                id: org.placeId,
                value: org.address,
                text: org.description,
              })),
              isLoading: false,
            });
          })
          .catch(() => setAddressAcState({ predictions: [], isLoading: false }));
      } else if (streetAddress.length < 1) {
        selectionRef.current = '';
        setAddressAcState({ predictions: [], isLoading: false });
      }
    },
    400,
    [streetAddress]
  );

  useEffect(() => {
    return () => cancelAddressSearch();
  }, [cancelAddressSearch]);

  const onAddressSelect: (v: any) => void = ({ id }: { id: string }) => {
    if (id !== NO_RESULTS_ITEM.id && id !== LOADING_ITEM.id) {
      cancelAddressSearch();
      const selectedItem = predictions.find(a => a.id === id)!;
      selectionRef.current = selectedItem.value;
      onSelectValueChange(selectedItem.value);

      setAddressAcState({
        predictions: [
          {
            id: selectedItem.value,
            value: selectedItem.value,
            text: selectedItem.value,
          },
        ],
        isLoading: false,
      });

      toggleLoadingAddrDetails(true);
      executeAddressDetails(id)
        .then(({ zipCode }) => {
          toggleLoadingAddrDetails(false);
          if (zipCode) {
            store.dispatch(
              updateTextField({
                target: { name: 'zipCode', value: zipCode },
              } as ChangeEvent<HTMLInputElement>)
            );
          }
        })
        .catch(() => toggleLoadingAddrDetails(false));
    }
  };
  return { predictions, isLoading, isLoadingAddrDetails, onAddressSelect };
};
