import { Optional, Paragraph } from '@laurelroad/lrds-react';
import React, { FC, memo } from 'react';
import { useSLOSRedirectUrl } from '../../../../../hooks/useSLOSRedirectUrl';

const SavingsInstructions: FC = () => {
  const slosRedirectUrl = useSLOSRedirectUrl();
  return (
    <>
      <Paragraph className={'hys-text secondary'}>
        You’ll receive an email confirming your account opening shortly, so keep an eye on your
        inbox. You've also been automatically enrolled in our online banking system, which you
        can access with the User ID and password you just set up.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        <b>The next step is to fund your savings account.</b> Here are some options:
      </Paragraph>
      <Optional renderIf={slosRedirectUrl === null}>
        <Paragraph>
          <ul style={{ paddingLeft: '2rem' }}>
            <li>
              Start a transfer from an account at another bank. Be sure to have your Laurel
              Road account and routing numbers ready.
            </li>
            <li>
              Send a domestic wire transfer from another bank. This is the best option for
              making large transfers. Reach out to your other bank to initiate a wire transfer
              to your Laurel Road account.
            </li>
            <li>
              <b>
                {' '}
                <a href="https://laurelroad.key.com/ibxolb/login/index.html#/login?dest=payment">
                  Transfer funds from an existing Laurel Road deposit account
                </a>{' '}
              </b>{' '}
              in Laurel Road Online or Mobile Banking.
            </li>
            <li>
              Go to Laurel Road Online Banking and{' '}
              <b>
                {' '}
                <a href="https://laurelroad.key.com/ibxolb/login/index.html#/login?dest=external-account">
                  {' '}
                  set up an external transfer from a non-Laurel Road bank account
                </a>{' '}
              </b>{' '}
              (subject to Laurel Road transfer limits).
            </li>
            <li>
              Or, visit our{' '}
              <a href="https://www.laurelroad.com/faq-category/high-yield-savings/">
                {' '}
                FAQ page
              </a>{' '}
              for more details and to see other ways to fund your account.
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          You can access your online banking dashboard from the Laurel Road website or from the
          link emailed to you with your account opening confirmation. (FYI, it can take up to
          24 hours for your account to appear in online banking, so check back if you don’t see
          it.)
        </Paragraph>
      </Optional>
      <Optional renderIf={slosRedirectUrl !== null}>
        <Paragraph className={'hys-text secondary'}>
          Right now, we&apos;ll return you to your student loan refinance application, so you
          can close out your loan.
        </Paragraph>
      </Optional>
    </>
  );
};

export default memo(SavingsInstructions);
