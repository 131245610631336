import React, { FC, useEffect, useState } from "react";
import { Modal } from "@laurelroad/lrds-react";
import { AccountCreationMultiProductCheck } from "./AccountCreationMultiproductCheck";
import "./account-creation.scss";
import { useDispatch } from "react-redux";
import { fetchMultiproductProductCodes } from "./asyncActions";
import { useBundleInfo } from "../BundleModal/hook/useBundleInfo";
import { getRequest } from "../../utils/crud/getRequest";
import { RsqPopUp } from "../ConfirmationPage/PendingView/RsqPopUp/RsqPopUp";
import AccountCreationLoader from "./components/AccountCreationLoader";

const AccountCreation: FC = () => {
  const [fetchDarq, setFetchDarq] = useState("idle");
  const [isDarq, setIsDarq] = useState(false);
  const [ rsqSubmission, setRsqSubmission] = useState("idle");
  const dispatch = useDispatch();
  const { replenishBundleStatus } = useBundleInfo();
  //RSQ POPUP... run account creation if passes
  // PASS -> run account creation
  // FAIL -> AML HARDSTOP -> DENIED  -> Dont run account creation
  useEffect(() => {
    if (replenishBundleStatus === "idle") {
      dispatch(fetchMultiproductProductCodes());
    }
  }, [dispatch, replenishBundleStatus]);

  let [component, setComponent]  = useState(<AccountCreationLoader />);
  useEffect(() => {
    if (fetchDarq == "idle") {
      setFetchDarq("pending");
      const fetch = async () => await getRequest<boolean>("/applications/rsq");
      fetch()
        .then(setIsDarq)
        .then(() => setFetchDarq("complete"));
    }
  }, []);

  useEffect( () => {
    if (fetchDarq === "pending") {
      setComponent(<AccountCreationLoader />);
    }
    if (fetchDarq == "complete") {
      if (isDarq) {
        setComponent(<RsqPopUp 
          onRsqSaved={() => setIsDarq(false)}
        />);
      } else {
        setComponent(<AccountCreationMultiProductCheck />);
      }
    }
  }, [fetchDarq, isDarq])
  
  return (
    <Modal className={"account-creation"} isOpen={true}>
      {component}
    </Modal>
  );
};

export default AccountCreation;
