import React, {FC, memo} from "react";
import {useDisclosureModalStatus} from "../hooks";
import LoadingModal from "../../LoadingModal";
import {DisclosureModalStatus} from "../types";
import {Redirect} from "react-router-dom";
import {ACCOUNT_CREATION_ROUTE} from "../../AccountCreation/constants";
import {CONFIRMATION_PENDING_ROUTE} from "../../ConfirmationPage/constants";
import CreateCredentialsRedirectModal from "./CreateCredentialsRedirectModal";
import LoginNotificationModal from "../../CreateCredentials/components/LoginNotificationModal";
import {FINISH_APPLICATION_ROUTE} from "../../FinishApplication/constants";

const DisclosuresModal: FC = () => {
  const disclosureModalStatus = useDisclosureModalStatus();
  switch (disclosureModalStatus) {
    case DisclosureModalStatus.SubmitDisclosures:
      return <LoadingModal showModal={true} />;
    case DisclosureModalStatus.AccountCreation:
      return <Redirect to={ACCOUNT_CREATION_ROUTE} />;
    case DisclosureModalStatus.CreateCredentials:
      return <CreateCredentialsRedirectModal/>
    case DisclosureModalStatus.ConfirmationPending:
      return <Redirect to={CONFIRMATION_PENDING_ROUTE} />;
    case DisclosureModalStatus.RedirectToIbxLogin:
      return <LoginNotificationModal />;
    case DisclosureModalStatus.RedirectToFinishApp:
      return <Redirect to={FINISH_APPLICATION_ROUTE} />;
    default:
      return null;
  }
};

export default memo(DisclosuresModal);
