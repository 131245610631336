import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateSelectField,
} from "../../../utils/applicationDispatchFunctions";
import SearchableDropdown from "../../SearchableDropdown/searchableDropdown";
import { PREFIX_SELECT_OPTIONS } from "./constants/PrefixDropdownOptions";
import { PrefixSchema } from "./constants/PrefixSchema";
import { usePrefix } from "./hooks";

const Prefix: FC = () => {
  const dispatch = useDispatch();
  const { prefix } = usePrefix();
  const [error, updateError] = useState<string | undefined>();

  const blurHandler = useCallback(() => {
    const validation = PrefixSchema.validate(prefix);
    updateError(validation.error?.message);
  }, [prefix]);

  const onChangeHandler = useCallback(
    (event) => {
      dispatch(updateSelectField(event));
    },
    [dispatch]
  );

  return (
    <>
      <SearchableDropdown
        items={PREFIX_SELECT_OPTIONS}
        id={"prefix"}
        name={"prefix"}
        value={prefix}
        label="Prefix"
        error={error}
        onBlur={blurHandler}
        onChange={onChangeHandler}
      />
    </>
  );
};

export default Prefix;
