import { Modal } from "@laurelroad/lrds-react";
import React, { FC, memo } from "react";
import { ElectronicDisclosureModalProps } from "../../types";
import { ESIGN_ELECTION_URL } from "../constants";
import "./ElectronicDisclosureModal.scss";
import { PDFViewer } from "react-view-pdf";
import ElectronicDisclosureButtons from "./ElectronicDisclosureButtons";

const ElectronicDisclosureModal: FC<ElectronicDisclosureModalProps> = ({
  isOpen,
  modalHandler,
}) => {
  return (
    <Modal className={"electronic-disclosure__modal"} isOpen={isOpen}>
      <ElectronicDisclosureButtons modalHandler={modalHandler} />
      <a
        className="electronic-disclosure__download-pdf"
        href={ESIGN_ELECTION_URL}
        download={ESIGN_ELECTION_URL}
      >
        Download
      </a>
      <div className="modalContent">
        <PDFViewer url={ESIGN_ELECTION_URL} />
      </div>
    </Modal>
  );
};

export default memo(ElectronicDisclosureModal);
