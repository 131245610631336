import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDVHardstopModalApplicantPayload,
  IDVHardstopModalDisplayPayload,
  IDVHardstopModalState,
} from "./types";

export const initialState: IDVHardstopModalState = {
  show: false,
};

const IDVHardstopModalSlice = createSlice({
  name: "idvHardstopModal",
  initialState,
  reducers: {
    updateIDVModalVisibility: (
      state: IDVHardstopModalState,
      action: PayloadAction<IDVHardstopModalDisplayPayload>
    ) => {
      const {
        payload: { value },
      } = action;
      return { ...state, show: value };
    },
    updateApplicantType: (
      state: IDVHardstopModalState,
      action: PayloadAction<IDVHardstopModalApplicantPayload>
    ) => {
      const {
        payload: { value },
      } = action;
      return { ...state, applicantType: value };
    },
  },
});

export const { updateIDVModalVisibility } = IDVHardstopModalSlice.actions;
export const { reducer: IDVHardstopReducer } = IDVHardstopModalSlice;
