import React, { FC } from "react";
import OaoNavigationBar from "./OaoNavigationBar/OaoNavigationBar";
import OaoStepper from "./OaoStepper/OaoStepper";

const Navigation: FC = () => {
  return (
    <>
      <OaoNavigationBar />
      <OaoStepper />
    </>
  );
};

export default Navigation;
