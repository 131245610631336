import React, { FC } from 'react';
import { Paragraph } from '@laurelroad/lrds-react';

const BundleFreedomCheckingDeniedInstructions: FC = () => {
  return (
    <>
      {' '}
      <Paragraph className={'hys-text secondary'}>
        We are unable to open an account for you at this time. Unfortunately, this makes you
        ineligible for the Student Loan Freedom annual membership fee discount. Please check
        your email for instructions on proceeding with your membership.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        Your application was declined based on information found in your consumer report,
        provided by ChexSystems, Inc., a consumer reporting agency. ChexSystems did not make
        the decision to decline your account application and is unable to provide you with
        specific reasons why the decision was made.
      </Paragraph>
      <Paragraph className={'hys-text secondary'}>
        You have rights under state and federal law. Included in these rights are:
      </Paragraph>
      <Paragraph
        className="rights-ol hys-text secondary"
        style={{ paddingLeft: '1.2em', lineHeight: '22px' }}>
        <ol>
          <li>
            The right to obtain a free copy of your consumer report if you make such a request
            to ChexSystems within 60 days of your receipt of this notice
          </li>
          <li>
            The right to dispute the completeness or accuracy of any information contained in
            such report by notifying ChexSystems directly of your dispute.
          </li>
          <li>
            You may contact ChexSystems by visiting their website at{' '}
            <a href="www.chexsystems.com">www.chexsystems.com</a>, by telephone at{' '}
            <a href="tel:1-800-428-9623">1-800-428-9623</a>, by mail at ChexSystems, Inc.,
            Attn: Customer Relations, P.O. Box 583399, Minneapolis, MN 55458.
          </li>
        </ol>
      </Paragraph>
    </>
  );
};
export default BundleFreedomCheckingDeniedInstructions;
