import { useSelector } from 'react-redux';
import { ProductCode } from '../constants/ProductCode';
import { AppState } from '../store';

export const useIsNonBundledProduct = () => {
  return useSelector<AppState, boolean>(({ application, confirmationEventData }: AppState) => {
    const productCode = application.productCode || confirmationEventData.productCode;
    return (
      productCode === ProductCode.LOYALTY_CHECKING ||
      productCode === ProductCode.CHECKING ||
      productCode === ProductCode.SAVINGS ||
      productCode === ProductCode.IDR_CHECKING ||
      productCode === ProductCode.PSLF_CHECKING
    );
  });
};
