import Joi from 'joi';

export const StreetAddressOneSchema = Joi.string()
  .regex(/(po)\b/i, { invert: true })
  .regex(/(P\.O)\b/i, { invert: true })
  .regex(/(P\.O\.)\b/i, { invert: true })
  .regex(/(p\.o\.)\b/i, { invert: true })
  .regex(/(p\.o)\b/i, { invert: true })
  .regex(/(po box)\b/i, { invert: true })
  .regex(/(Post Office Box)\b/i, { invert: true })
  .regex(/\b(box \d+)/i, { invert: true }) // BOX 123
  .min(5)
  .max(40)
  .messages({
    'string.empty': 'Street Address is required',
    'string.min': 'Street Address is required',
    'string.max': 'Please input fewer than 40 characters.',
    'string.pattern.invert.base':
      'Street Address cannot be a PO BOX. Please provide a valid physical Street Address.',
  });
