import { createSlice } from "@reduxjs/toolkit";
import { ConfirmationEventDataState } from ".";
import { fetchConfirmationEventData } from "./asyncActions";

export const initialState: ConfirmationEventDataState = {
    applicationId: null ,
    productCode: "",
    obpPartyId:"" ,
    amlStatus: "",
    aoxSubmissionId: ""
}

const confirmationEventDataSlice = createSlice({
    name: "confirmationEventData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchConfirmationEventData.fulfilled,
            (state: ConfirmationEventDataState, action) => {
                const { applicationId, productCode, obpPartyId, amlStatus, aoxSubmissionId } = action.payload;
                return {
                    ...state,
                    applicationId,
                    productCode,
                    obpPartyId,
                    amlStatus,
                    aoxSubmissionId
                }
            }
        );
    }
});

export const { reducer: ConfirmationEventDataReducer } = confirmationEventDataSlice;