import {LINKS, LOGIN_ROUTES} from "../constants";
import useUiState from "../../../../hooks/useUiState";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { AppViewState } from "../../../App/types";
import { useLocation } from "react-router";

export const useNavigationLinks = () => {
  const { isAuthenticated } = useUiState();

  const { currentView } = useSelector<AppState, AppViewState>(
    ({ appView }) => appView,
    ({ currentView: leftView }, { currentView: rightView }) =>
      leftView === rightView
  );
  const { pathname } = useLocation();

  return isAuthenticated || currentView !== "Profile Creation Page" || !LOGIN_ROUTES.includes(pathname)
    ? undefined
    : LINKS;
};
