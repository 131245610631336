import React, { FC, useCallback } from 'react';
import MultiSelect from '../../MultiSelect/MultiSelect';
import { Column } from '@laurelroad/lrds-react';
import { useSourcesOfOngoingAccountFunding } from './hooks';
import { FUNDING_SOURCES_OPTIONS } from '../Income/constants/SelectOptions';
import { updateExpectedSourcesOfOngoingFunding } from '../../../utils/applicationDispatchFunctions';
import { useDispatch } from 'react-redux';
import { usePersonalInfoErrorMessages } from '../hooks/usePersonalInfoErrorMessages';
import { usePersonalInfo } from '../hooks';
import { validatePersonalFields } from '../asyncActions';

const SourcesOfOngoingAccountFunding: FC = () => {
  const dispatch = useDispatch();
  const expectedSourcesOfOngoingAccountFunding = useSourcesOfOngoingAccountFunding();
  const personalInfo = usePersonalInfo();

  const expectedSourcesOfFundingChangeHandler = useCallback(
    selectedSourcesOfOngoingFunding =>
      dispatch(updateExpectedSourcesOfOngoingFunding(selectedSourcesOfOngoingFunding)),
    [dispatch]
  );

  const blurHandler = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLSelectElement>
      | React.FocusEvent<HTMLElement>
  ) => {
    dispatch(validatePersonalFields(personalInfo));
  };

  return (
    <Column className={'col--relative ongoing-funding-container'} size={6}>
      <MultiSelect
        id={'expectedSourcesOfOngoingAccountFunding'}
        selected={expectedSourcesOfOngoingAccountFunding}
        placeholder={'Expected Sources of Ongoing Account Funding'}
        onChange={expectedSourcesOfFundingChangeHandler}
        options={FUNDING_SOURCES_OPTIONS}
        required={true}
        error={usePersonalInfoErrorMessages('expectedSourcesOfOngoingAccountFunding')}
        onBlur={blurHandler}
      />
    </Column>
  );
};

export default SourcesOfOngoingAccountFunding;
