import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Column, Optional, TextField } from '@laurelroad/lrds-react';
import {
  EMPLOYMENT_STATUS_OPTIONS,
  OCCUPATION_CLASSIFICATION_OPTIONS,
} from './constants/SelectOptions';
import SearchableDropdown from '../../SearchableDropdown/searchableDropdown';
import { OccupationErrors } from './types';
import { useDispatch } from 'react-redux';
import { useOccupationInfo } from './hooks';
import { SelectFieldEvent } from '@laurelroad/lrds-react/dist/src/components/SelectField';
import {
  resetFields,
  updateSelectField,
  updateTextField,
} from '../../../utils/applicationDispatchFunctions';
import { isHealthcareOccupation, isUnemployed, needsClarification } from './utils';
import { getEmploymentIndustryOptions } from './constants/EmployerIndustryOptions';
import { usePersonalInfoErrorMessages } from '../hooks/usePersonalInfoErrorMessages';
import { usePersonalInfo } from '../hooks';
import { updateApplication } from '../../../store/state/applicationSlice';
import { validatePersonalFields, validateReferralCodeField } from '../asyncActions';
import useUiState from '../../../hooks/useUiState';
import { AppDispatch } from '../../../store';

const updateReferralCodeAndValidate = (dispatch: AppDispatch, code: string) => {
  dispatch(
    updateApplication({
      key: 'employerReferralCode',
      value: code,
    })
  );
  setTimeout(() => dispatch(validateReferralCodeField(code)));
};

const useClearReferralWhenNotShown = (showReferralCode: boolean, dispatch: AppDispatch) => {
  useEffect(() => {
    if (!showReferralCode) {
      updateReferralCodeAndValidate(dispatch, '');
    }
  }, [dispatch, showReferralCode]);
};

const Occupation: FC = () => {
  const {
    employerName,
    employmentStatus,
    employerIndustry,
    occupationClassification,
    employerReferralCode,
  } = useOccupationInfo();

  const dispatch = useDispatch();
  const [errors, updateErrors] = useState<OccupationErrors>({});
  const [hideEmploymentInfo, setHideEmploymentInfo] = useState(false);
  const personalInfo = usePersonalInfo();
  const { optionalFieldNames } = useUiState();

  //run once on page load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const partner = urlParams.get('partner');
    if (partner && !optionalFieldNames.includes('referringOrganization')) {
      updateReferralCodeAndValidate(dispatch, partner);
    }
  }, [dispatch, optionalFieldNames]);

  useEffect(() => {
    if (isUnemployed(employmentStatus)) {
      setHideEmploymentInfo(true);
      dispatch(resetFields(['employerName', 'occupationClassification']));
    } else {
      setHideEmploymentInfo(false);
    }
  }, [dispatch, setHideEmploymentInfo, employmentStatus]);

  const blurHandler = () => {
    dispatch(validatePersonalFields(personalInfo));
  };

  const searchableDropdownChangeHandler = useCallback(
    (event: SelectFieldEvent<any>) => {
      dispatch(updateSelectField(event));
      updateErrors({ ...errors, [event.fieldName!]: undefined });
    },
    [dispatch, errors, updateErrors]
  );

  const textFieldChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => dispatch(updateTextField(event)),
    [dispatch]
  );

  const referralOrgError = usePersonalInfoErrorMessages('employerReferralCode');

  const showReferralCode =
    !optionalFieldNames.includes('referringOrganization') &&
    (isHealthcareOccupation(occupationClassification) ||
      new URLSearchParams(window.location.search).get('partner') !== null);

  useClearReferralWhenNotShown(showReferralCode, dispatch);

  return (
    <>
      <Column className={'col--relative'} size={3}>
        <SearchableDropdown
          className="high-yield-savings__form-field"
          id={'employmentStatus'}
          label={'Which option best describes your primary employment status?'}
          name={'employmentStatus'}
          items={EMPLOYMENT_STATUS_OPTIONS}
          onChange={searchableDropdownChangeHandler}
          value={employmentStatus}
          onBlur={blurHandler}
          error={usePersonalInfoErrorMessages('employmentStatus')}
          required={true}
        />
      </Column>
      <Optional renderIf={!hideEmploymentInfo}>
        <Column className={'col--relative'} size={3}>
          <TextField
            id={'employerName'}
            className="high-yield-savings__form-field"
            value={employerName}
            onChange={textFieldChangeHandler}
            name={'employerName'}
            label={'Employer Name'}
            onBlur={blurHandler}
            error={usePersonalInfoErrorMessages('employerName')}
            required={true}
          />
        </Column>

        <Column className={'col--relative'} size={6}>
          <SearchableDropdown
            className="high-yield-savings__form-field"
            maxVisibleItems={5}
            id={'occupationClassification'}
            label={'Occupation Classification'}
            name={'occupationClassification'}
            items={OCCUPATION_CLASSIFICATION_OPTIONS}
            onChange={searchableDropdownChangeHandler}
            value={occupationClassification!}
            onBlur={blurHandler}
            error={usePersonalInfoErrorMessages('occupationClassification')}
            required={true}
          />
        </Column>
        <Optional renderIf={needsClarification(occupationClassification!)}>
          <Column className={'col--relative'} size={3}>
            <SearchableDropdown
              className="high-yield-savings__form-field"
              id={'employerIndustry'}
              label={'Is your Employer engaged in any of the following industries?'}
              name={'employerIndustry'}
              items={getEmploymentIndustryOptions(occupationClassification)}
              onChange={searchableDropdownChangeHandler}
              value={employerIndustry}
              onBlur={blurHandler}
              error={usePersonalInfoErrorMessages('employerIndustry')}
              required={true}
            />
          </Column>
        </Optional>
        <Optional renderIf={showReferralCode}>
          <Column className={'col--relative'} size={3}>
            <TextField
              className="high-yield-savings__form-field"
              value={employerReferralCode}
              onChange={textFieldChangeHandler}
              name={'employerReferralCode'}
              label={'Referral Code (Optional)'}
              error={referralOrgError}
              onBlur={() => dispatch(validateReferralCodeField(employerReferralCode))}
            />
          </Column>
        </Optional>
      </Optional>
    </>
  );
};

export default Occupation;
