import React, { FC, useEffect } from "react";
import { useCreateCredentials } from "./useCreateCredentials";
import { DialogModal } from "@laurelroad/lrds-react";
import "./create-credentials.scss";
import { useDisclosureInfo } from "../DisclosuresPage/hooks";
import { useProfileViewState } from "../ProfileCreationPage/hooks";
import { useDispatch } from "react-redux";
import { fetchMultiproductProductCodes } from "../AccountCreation/asyncActions";
import { getAuthTokenFromUrl } from "./async/checkApplicationToken";

const getAuthToken = () => {
  try {
    return getAuthTokenFromUrl();
  } catch (error) {
    return undefined;
  }
};

const CreateCredentials: FC = () => {
  const { isUmg: skipDataReentry } = useDisclosureInfo();
  const { prospectCreateSessionId } = useProfileViewState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMultiproductProductCodes(getAuthToken()));
  }, [dispatch]);
  const component = useCreateCredentials(
    skipDataReentry,
    prospectCreateSessionId
  );
  return (
    <DialogModal isOpen={true} className={"create-credentials"}>
      {component}
    </DialogModal>
  );
};

export default CreateCredentials;
