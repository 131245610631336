import { ProductCode } from '../../constants/ProductCode';
import {
  BUNDLED_SAVINGS_PRODUCT_NAME,
  SAVINGS_PRODUCT_NAME,
  CHECKING_PRODUCT_NAME,
  LINKED_CHECKING_PRODUCT_NAME,
  LOYALTY_CHECKING_NAME,
  FREEDOM_CHECKING_PRODUCT_NAME,
} from '../../constants/constants';

import { AccountCreationResponse } from '../AccountCreation/types';

export const createBundleProductNamesAndProductTypes = (
  accounts: AccountCreationResponse[] = [],
  productCodes: string[] = []
) => {
  let productNameList: string[] = [];
  if (accounts.length > 0) {
    accounts.forEach(account => {
      switch (account.productCode) {
        case ProductCode.CHECKING:
          productNameList.push(CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.BUNDLE_CHECKING:
          productNameList.push(LINKED_CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.LOYALTY_CHECKING:
          productNameList.push(LOYALTY_CHECKING_NAME);
          break;
        case ProductCode.PSLF_CHECKING:
        case ProductCode.IDR_CHECKING:
        case ProductCode.BUNDLE_PSLF_CHECKING:
        case ProductCode.BUNDLE_IDR_CHECKING:
          productNameList.push(FREEDOM_CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.SAVINGS:
          productNameList.push(SAVINGS_PRODUCT_NAME);
          break;
        case ProductCode.BUNDLED_SAVINGS:
          productNameList.push(BUNDLED_SAVINGS_PRODUCT_NAME);
          break;
      }
    });
  } else if (productCodes) {
    productCodes.forEach(productCode => {
      switch (productCode) {
        case ProductCode.CHECKING:
          productNameList.push(CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.BUNDLE_CHECKING:
          productNameList.push(LINKED_CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.LOYALTY_CHECKING:
          productNameList.push(LOYALTY_CHECKING_NAME);
          break;
        case ProductCode.PSLF_CHECKING:
        case ProductCode.IDR_CHECKING:
        case ProductCode.BUNDLE_IDR_CHECKING:
        case ProductCode.BUNDLE_PSLF_CHECKING:
          productNameList.push(FREEDOM_CHECKING_PRODUCT_NAME);
          break;
        case ProductCode.SAVINGS:
          productNameList.push(SAVINGS_PRODUCT_NAME);
          break;
        case ProductCode.BUNDLED_SAVINGS:
          productNameList.push(BUNDLED_SAVINGS_PRODUCT_NAME);
          break;
      }
    });
  }
  let productTypes = productNameList.map(productName => {
    if (
      [LINKED_CHECKING_PRODUCT_NAME, CHECKING_PRODUCT_NAME, LOYALTY_CHECKING_NAME].includes(
        productName
      )
    ) {
      return 'checking';
    }
    if ([SAVINGS_PRODUCT_NAME, BUNDLED_SAVINGS_PRODUCT_NAME].includes(productName)) {
      return 'savings';
    }
  });

  return [productNameList.join(' and '), productTypes.join(' and ')];
};
