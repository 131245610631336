import Joi from "joi";
import {
  GOVERNMENT_ID_TYPE_OPTIONS,
  mapSelectOptionToId,
} from "../SelectOptions";

const VALID_GOVERNMENT_ID_TYPES_ID_OPTIONS =
  GOVERNMENT_ID_TYPE_OPTIONS.map(mapSelectOptionToId);

export const GovernmentIdTypeSchema = Joi.any()
  .valid(...VALID_GOVERNMENT_ID_TYPES_ID_OPTIONS)
  .required()
  .messages({
    "any.only": "Government I.D. type is invalid",
  });
