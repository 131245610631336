import { Column, Grid, Loader, Modal, Optional } from "@laurelroad/lrds-react";
import React, { FC, memo } from "react";
import AMTProspectCreation from "../../AMT/AMTProspectCreation";
import { useProfileViewState, useProspectCreationEffect } from "../hooks";
import { useSanitizeProfileInfo } from "../../PersonalInfoPage/hooks/useSanitizeProfileInfo";
import { useAMTInfoProspectCreated } from "../../AMT/hooks/useAMTInfo";

const ProfileModal: FC = () => {
  const { profileCreated, zipCodeLookupFinished } = useProfileViewState();
  const prospectInfo = useSanitizeProfileInfo();
  useProspectCreationEffect();
  const isProspectCreated = useAMTInfoProspectCreated();
  return (
    <Modal className={"high-yield-savings__modal"} isOpen={true}>
      <Grid>
        <Column size={5} />
        <Column size={2}>
          <Loader />
        </Column>
        <Column size={5} />
        <Column size={12}>
          <span className={"hys-text h5 primary-text"}>One Moment Please</span>
        </Column>
        <Column size={12}>
          <span className={"hys-text caption2 primary-text"}>
            Please do not close this window while we save your profile
            information
          </span>
          <Optional
            renderIf={
              profileCreated && zipCodeLookupFinished && !isProspectCreated
            }
          >
            <AMTProspectCreation prospectInfo={prospectInfo} />
          </Optional>
        </Column>
      </Grid>
    </Modal>
  );
};

export default memo(ProfileModal);
