import React, { FC, memo } from "react";
import { OAOFormProps } from "./types";
import "./oao-form.scss";
import { Icon } from "@laurelroad/lrds-react";

export const OAOForm: FC<OAOFormProps> = ({
  headerData,
  children,
  className,
}) => {
  const { icon, text } = headerData;
  const classNames = `oao__form ${className}`;
  return (
    <div className={classNames}>
      <div className={"oao__form__header"}>
        <Icon
          className="m-auto"
          faIconConfig={{ size: "2x" }}
          color="orange"
          icon={icon}
        />
        <h2 className="hys-text bold">{text}</h2>
      </div>
      <div className={"oao__form__body"}>{children}</div>
    </div>
  );
};

OAOForm.defaultProps = {
  className: "",
};

export default memo(OAOForm);
