import { LoginNeededError } from '../components/CreateCredentials/async/checkApplicationToken';
import { parseOriginationId } from './parseOriginationId';
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const cCodes = ['CKR', 'CKL', 'CLR', 'CLL'];
async function initialize() {
  /**Intiialize common toolkit */
  let commonConfig = {
    channel: 'WEB',
    baseUrl: '',
    logsEnabled: true,
    stubsEnabled: true,
  };
  window.KeyCommonTkt.initialize(commonConfig);

  let amtConfig = {
    channel: 'WEB',
    baseUrl: 'https://laurelroad.key.com/',
    imageUrl: window.location.origin + '/images/',
    authServerConfig: {
      appName: 'idv_web',
      apiEndPoint:
        document.getElementsByName('_idv-api-url')[0]?.getAttribute('content') ||
        "'_idv-api-url' is not defined! is it returned by GET: 'ui-configuration API?'",
      token: '',
      apiKey: '',
    },

    sessionConfig: {
      timeoutInterval: 60100, // 17 mins
      countDownInterval: 30, // 3 mins
      sessionExtensionCount: 2,
    },
    logsEnabled: true,
    stubsEnabled: false,
    nextGenSecurityEnabled: true,
    behavioralAnalyticsEnabled: true,
    behavioralAnalyticsConfig: { clientId: 'w-996321' },
    crossPlatform: true,
  };

  try {
    const result = await window.KeyAmtTkt.initialize(amtConfig, true);
    console.log(result);
    window.KeyAmtTkt.setContentVariables({
      CREDENTIAL_CREATE_HEADER: "You've been verified",
      CREDENTIAL_CREATE_SUB_HEADER:
        'Please choose a User ID and password for your new account.',
      NON_CREDENTIAL_LOGIN_HEADER: 'Just making sure!',
      NON_CREDENTIAL_LOGIN_SUB_HEADER: 'Please verify your identity.',
      PHONE_NO: '1-833-427-2265',
    });

    console.log('amt initialized');

    await sleep(1000);
    return true;
  } catch (e) {
    console.log('amt failed', e);
    return false;
  }
}

function getParams(data) {
  const sessionID = window.KeyAmtTkt.getSessionId();
  const blackbox_info =
    window.ioGetBlackbox && window.ioGetBlackbox() && window.ioGetBlackbox().finished
      ? window.ioGetBlackbox().blackbox
      : '';
  const {
    id,
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth,
    mobilePhone,
    streetAddress,
    streetAddressTwo,
    zipCode,
    socialSecurityNumber,
    city,
    state,
    productCode,
  } = data;
  const origApplicationID = parseOriginationId(id, productCode);
  const dob = dateOfBirth.replace(/-/g, '');
  return {
    origApplicationID: origApplicationID,
    sourceChannel: 'OAO',
    firstName,
    middleName,
    lastName,
    ssnTin: socialSecurityNumber,
    dob,
    ...parseStreetAddress(streetAddress),
    streetAddress2: streetAddressTwo,
    city,
    state,
    postalCode: zipCode,
    country: 'US',
    primaryPhoneNumber: mobilePhone,
    primaryEmail: email,
    driversLicenseNo: '',
    driversLicenseSt: '',
    mcaLobCode: 'LAUREL',
    mcaProductCode: 'DDA',
    mcaSubProductCode: '',
    clientType: 'R',
    origBankNumber: '2912',
    origProductCode: 'DDA',
    citizen: 'Y',
    iovation_blackbox: blackbox_info,
    CHANNEL_SESSIONID: sessionID,
  };
}

function parseStreetAddress(streetAddress) {
  const poBoxRegEx =
    /(?:\bbox\s\d*)|\b(?:p\.?\s*o\.?|post\s+office|postal)(?:\s+)?(?:box)?(?:\s\d*)\b/gim;
  const streetAddress1 = streetAddress
    .split(poBoxRegEx)
    .filter(s => s)
    .join()
    .trim();
  return {
    streetAddress1,
  };
}

function invokeIDV(profileInfo) {
  let policyParams = getParams(profileInfo);

  // unsure if policy Id is on policyParams, need to check with Taylor Saven where this comes from
  const policyId = 'Application';
  return window.KeyAmtTkt.authenticateUsingIdv(
    policyParams.origApplicationID,
    policyId,
    'transmitContainer',
    policyParams
  ).then(
    successfulResponse => {
      console.log('We executed IDV through AMT!');
      console.log(successfulResponse);
      return {
        ...successfulResponse,
        sessionId: successfulResponse?._internalData?.json_data?.sessionId,
      };
    },
    error => {
      console.log('Something went wrong with IDV!');
      console.log(error);
      const result = error?._data?.failure_data?.reason?.data?.bcn === 'LGN';
      throw result;
    }
  );
}

async function invokeMca(application) {
  // unsure if policy Id is on policyParams, need to check with Taylor Saven where this comes from
  const policyId = 'Application_Auth';
  const policyParams = getParams(application);
  try {
    const response = await window.KeyAmtTkt.authenticateUsingIdv(
      policyParams.origApplicationID,
      policyId,
      'transmitContainer',
      policyParams
    );
    console.log('We executed MCA through AMT!');
    console.log(response);
    return true;
  } catch (e) {
    console.log('Something went wrong with executing mca!');
    console.log(e);
    return [...cCodes, 'APP'].includes(e?._data?.failure_data?.reason?.data?.bcn);
  }
}

async function invokeNonCredentialedLogin(applicationId) {
  let origID = applicationId;
  let policyID = 'NonCredentialedLogin';
  let policyParams = {
    CHANNEL_SESSIONID: window.KeyAmtTkt.getSessionId(),
    origApplicationID: origID,
    sourceChannel: 'OAO',
  };
  try {
    const result = await window.KeyAmtTkt.authenticateUsingIdv(
      origID,
      policyID,
      'transmitContainer',
      policyParams
    );
    console.log('Phone DOB authentication success', result);
    return true;
  } catch (e) {
    console.log('Phone DOB authentication failed', e);
    return false;
  }
}

async function invokeCredentialCreation(applicationId, policyId = 'Acquire_2', sessionId) {
  let policyParams = {
    CHANNEL_SESSIONID: window.KeyAmtTkt.getSessionId(),
    origApplicationID: applicationId,
    sourceChannel: 'OAO',
  };

  try {
    const response = await window.KeyAmtTkt.invokePolicy(
      policyId,
      'transmitContainer',
      policyParams
    );
    console.log('Prospect Create policy execution success', response);
    const isLgnBcn = response?._internalData?.json_data?.bcn === 'LGN';
    console.log(
      'The value of BCN in this session is',
      response?._internalData?.json_data?.bcn
    );
    if (isLgnBcn) {
      console.log('BCN is LGN without rejecting session and so re-directing to IBX login');
      throw new LoginNeededError();
    }
    return true;
  } catch (e) {
    if (e instanceof LoginNeededError) {
      console.log(
        'BCN is LGN without rejecting session and so re-directing to IBX login after catching the error'
      );
      throw new LoginNeededError();
    }
    console.log('Prospect Create policy execution failed', e);
    const isLgnBcn = e?._data?.failure_data?.reason?.data?.bcn === 'LGN';
    if (isLgnBcn) {
      console.log('BCN is LGN with rejecting session and so re-directing to IBX login');
      throw new LoginNeededError();
    }
    return false;
  }
}

const invokeCredentialCreationIDV = (applicationId, sessionId) =>
  invokeCredentialCreation(applicationId, 'Acquire_1');
const invokeCredentialCreationPhone = applicationId =>
  invokeCredentialCreation(applicationId, 'Acquire_2');

export {
  initialize,
  invokeIDV,
  invokeCredentialCreationIDV,
  invokeCredentialCreationPhone,
  invokeNonCredentialedLogin,
  invokeMca,
  parseStreetAddress, //only exported for testing
  sleep,
};
