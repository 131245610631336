import Joi from "joi";

const PHONE_NUMBER_LENGTH = 10;

export const MobilePhoneSchema = Joi.string()
  .min(PHONE_NUMBER_LENGTH)
  .max(PHONE_NUMBER_LENGTH)
  .messages({
    "string.empty": "Mobile Phone is required",
    "string.base": "Mobile Phone is required",
    "string.max": "Mobile Phone Number must be a valid 10-digit number.",
    "string.min": "Mobile Phone Number must be a valid 10-digit number.",
    "string.pattern.base":
      "Mobile Phone Number must be a valid 10-digit number.",
  })
  .required();
