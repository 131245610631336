import React, { FC, memo } from "react";
import LoadingModal from "../../LoadingModal";
import AmlHardstopModal from "../AmlHardstopModal/AmlHardstopModal";
import { usePersonalInfoViewState } from "../hooks";
import { ModalStatus } from "../types";
import { Optional } from "@laurelroad/lrds-react";

const PersonalInfoModal: FC = () => {
  const { modalStatus } = usePersonalInfoViewState();

  return (
    <>
      <LoadingModal showModal={modalStatus === ModalStatus.LOADING} />
      <Optional renderIf={modalStatus === ModalStatus.AML_MODAL}>
        <AmlHardstopModal />
      </Optional>
    </>
  );
};

export default memo(PersonalInfoModal);
