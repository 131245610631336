import "./styles.scss";
import React, { FC } from "react";
import { DialogModal } from "@laurelroad/lrds-react";
import useNetworkState from "../../hooks/useNetworkState";
import { FORBIDDEN } from "../../utils/crud/constants";

const ShapeModal: FC = () => {
  const network = useNetworkState();
  const netWorkStatus = network.error.status;
  const fsNumber = network.error.fs_ref_number;
  const showModal = netWorkStatus === FORBIDDEN && fsNumber !== undefined;

  return (
    <DialogModal
      isOpen={showModal}
      className="shape-modal"
      title="For security, sign on from this browser is currently blocked"
    >
      <div className="shape-modal__message">
        <div>Here's what to do:</div>
        <div>
          1) Go to browser settings, 2) Clear your data, 3) Relaunch your
          browser. Or try a different browser.
        </div>
        <div>
          Still can't sign on? Call Online support at{" "}
          <a href="tel:1-833-427-2265">1-833-427-2265</a> (TDD/TTY at{" "}
          <a href="tel:1-800-539-8336">1-800-539-8336</a>). and reference
          Incident Number: {fsNumber}
        </div>
      </div>
    </DialogModal>
  );
};

ShapeModal.defaultProps = {};

export default ShapeModal;
