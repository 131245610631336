import { PRIMARY_INCOME_SOURCES } from "./PrimaryIncomeSources";
import { FUNDING_SOURCES } from "./FundingSources";

export const PRIMARY_INCOME_SOURCE_OPTIONS: {
  text: string;
  id: string;
}[] = Object.keys(PRIMARY_INCOME_SOURCES).map((sourceName) => ({
  text: sourceName,
  id: PRIMARY_INCOME_SOURCES[sourceName],
}));

export const FUNDING_SOURCES_OPTIONS = Object.keys(FUNDING_SOURCES).map(
  (sourceName) => ({
    label: sourceName,
    value: FUNDING_SOURCES[sourceName],
  })
);
