import {
  validateCheckingDisclosures,
  validateLoyaltyDisclosures,
  validateSavingsDisclosures,
} from '../utils';
import { useMemo } from 'react';
import { useDisclosureInfo } from './useDisclosureInfo';
import { useApplicationInfo } from '../../ConfirmationPage/hooks';
import { ProductCode } from '../../../constants/ProductCode';

export const useDisclosureInfoValidated = () => {
  const disclosureInfo = useDisclosureInfo();
  const { productCode } = useApplicationInfo();
  return useMemo(() => {
    switch (productCode) {
      case ProductCode.CHECKING:
      case ProductCode.BUNDLE_CHECKING:
      case ProductCode.IDR_CHECKING:
      case ProductCode.PSLF_CHECKING:
      case ProductCode.BUNDLE_IDR_CHECKING:
      case ProductCode.BUNDLE_PSLF_CHECKING:
        return validateCheckingDisclosures(disclosureInfo);
      case ProductCode.SAVINGS:
      case ProductCode.BUNDLED_SAVINGS:
        return validateSavingsDisclosures(disclosureInfo);
      case ProductCode.LOYALTY_CHECKING:
        return validateLoyaltyDisclosures(disclosureInfo);
      default:
        return false;
    }
  }, [disclosureInfo, productCode]);
};
