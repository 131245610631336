import { Column, Grid, Loader, Modal } from "@laurelroad/lrds-react";
import React, { FunctionComponent } from "react";
import { LoadingModalProps } from "./types";

const LoadingModal: FunctionComponent<LoadingModalProps> = ({ showModal }) => {
  return (
    <Modal className={"high-yield-savings__modal"} isOpen={showModal}>
      <Grid>
        <Column size={5} />
        <Column size={2}>
          <Loader />
        </Column>
        <Column size={5} />
        <Column size={12}>
          <span className={"hys-text h5 primary-text"}>One Moment Please</span>
        </Column>
        <Column size={12}>
          <span className={"hys-text caption2 primary-text"}>
            Please do not close this window while we save your information
          </span>
        </Column>
        <div id="transmitContainer" className="mt-4" />
      </Grid>
    </Modal>
  );
};

export default LoadingModal;
