import { useSelector } from "react-redux";
import { ProductCode } from "../constants/ProductCode";
import { AppState } from "../store";

export const useIsBundled = () => {
  return useSelector<AppState, boolean>(({ application }: AppState) => {
    switch (application.productCode) {
      case ProductCode.BUNDLED_SAVINGS:
      case ProductCode.BUNDLE_CHECKING:
        return true;
      default:
        return false;
    }
  });
};
