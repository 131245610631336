import Joi from "joi";
import { US_STATE_LIST, WASHINGTON_DC } from "../USStates";
import { MEXICAN_STATE_LIST } from "../MexicanStates";
import { CANADIAN_PROVINCE_LIST } from "../CanadianProvinces";
import { COUNTRY_LIST } from "../Countries";
import { mapSelectOptionToId } from "../SelectOptions";

const VALID_GOVERNMENT_ID_LOCATIONS_ID_OPTIONS = US_STATE_LIST.concat(
  WASHINGTON_DC
)
  .concat(MEXICAN_STATE_LIST)
  .concat(CANADIAN_PROVINCE_LIST)
  .concat(COUNTRY_LIST)
  .map(mapSelectOptionToId);

export const GovernmentIdLocationSchema = Joi.any()
  .valid(...VALID_GOVERNMENT_ID_LOCATIONS_ID_OPTIONS)
  .required()
  .messages({
    "any.only": "Government I.D. location is required",
  });
