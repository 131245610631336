import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { selectAcknowledgement } from "../slice";

export const useSelectAcknowledgement = () => {
  const dispatch = useDispatch();
  return useCallback(
    (acknowledgement : any) => {
      dispatch(selectAcknowledgement({ acknowledgement }));
    },
    [dispatch]
  );
};
