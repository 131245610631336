import React from 'react';
import { ProductCode } from '../../../constants/ProductCode';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import CheckingInstructions from '../ApprovedView/ApprovedBody/ApprovedBodyInstructions/CheckingInstructions';
import LoyaltyInstructions from '../ApprovedView/ApprovedBody/ApprovedBodyInstructions/LoyaltyInstructions';
import BundleCheckingInstructions from '../ApprovedView/ApprovedBody/ApprovedBodyInstructions/BundleCheckingInstructions';
import FreedomCheckingInstructions from '../ApprovedView/ApprovedBody/ApprovedBodyInstructions/FreedomCheckingInstructions';
import BundleFreedomCheckingInstructions from '../ApprovedView/ApprovedBody/ApprovedBodyInstructions/BundleFreedomCheckingInstructions';

export const useInstructionsParagraph = () => {
  return useSelector<AppState, JSX.Element>(
    ({ application, confirmationEventData }: AppState) => {
      const productCode = application.productCode || confirmationEventData.productCode;
      switch (productCode) {
        case ProductCode.CHECKING:
          return <CheckingInstructions />;
        case ProductCode.PSLF_CHECKING:
        case ProductCode.IDR_CHECKING:
          return <FreedomCheckingInstructions />;
        case ProductCode.BUNDLE_IDR_CHECKING:
        case ProductCode.BUNDLE_PSLF_CHECKING:
          return <BundleFreedomCheckingInstructions />;
        case ProductCode.LOYALTY_CHECKING:
          return <LoyaltyInstructions />;
        case ProductCode.BUNDLE_CHECKING:
          return <BundleCheckingInstructions />;
        default:
          return <></>;
      }
    }
  );
};
