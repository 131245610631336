import { useEffect } from "react"
import { sendToGTM } from "./sendToGTM"
import { useApplicationInfo } from "../../components/ConfirmationPage/hooks"

export const useSendDisclosuresEvent = () => {
    const { id, productCode } = useApplicationInfo();

    useEffect( () => {
        if ( productCode && id) {
            sendToGTM({
                event:  "oao_disclosures",
                applicationId: id,
                productCode
            })
        }
    }, [id, productCode])
}